// google analytics for the semantic browser

import ReactGA from 'react-ga';


import { APP_NAME } from './constants'

import { version } from './version'

import { server_environment } from './servers'
/*
   GOOGLE ANALYTICS (ask iam for access to data)

   UA-221280679-1

 */

const UNIVERSAL_TRACKING_ID = 'UA-221280679-1'

export function google_analytics_initialize() {
    console.log(`${APP_NAME} ${version} ${server_environment} (Google Analytics: ${UNIVERSAL_TRACKING_ID})`);
    ReactGA.initialize(UNIVERSAL_TRACKING_ID, { gaOptions: { cookieFlags: "SameSite=None;Secure" } })
}

export function google_analytics_pageview(page)  {
    const analytics_page = page ? page : window.location.pathname + window.location.search
    const hit = `/${server_environment}${analytics_page}`
    ReactGA.pageview(hit)
    console.log(`Google Analytics Pageview event: ${hit}`)
}

