// the html blobs and rendering a "List of Tables"

import MINIO_URL from './network/constants'

import { cleanBlob } from './utils'

function conjourId(name, content) {
    const  pat = /title:(.*)/
    const ma = pat.exec(content)
    let retval = null
    if (ma) {
	retval = 'code' + ma[ma.length - 1].replaceAll(' ', '_').replaceAll("'", '')
    }
    //console.log(name, ma, content.indexOf('title:'), retval)
    return retval
}

/*
function load(filename) {
    const url = MINIO_URL + filename
    const request = new XMLHttpRequest()
    // I see no real reason to do this asyncronously ...
    request.open('GET', url, false);
    request.send(null);
    if (request.status === 200) {
	return request.responseText
    } else {
	return null
    }
}
*/

function minioRequestFailed(name, cmap, err) {
    cmap.set(name, null)
    console.log(err)
}

function blob2Promise(blob, cmap){
    const name = blob.filename
    const url = MINIO_URL + name
    const promise = new Promise(function(resolve, reject) {
	let xhttp = new XMLHttpRequest();
	xhttp.onreadystatechange = function() {
	    if(this.readyState === 4) {
		if(this.status === 200) {
		    resolve(this.responseText);
		}
		else
		    reject(`Minio blob request for ${url} failed`);
	    }
	};
	xhttp.open("GET", url);
	xhttp.send();
    })
    return promise.then((content) => cmap.set(name, processContent(blob, content))).catch((err) => minioRequestFailed(name, cmap, err))
}

/*
function fetchContent(blob) {
    let content = load(blob.filename)
    return processContent(blob, content)
}
*/


function processContent(blob, content) {
    const name = blob.filename
    const nt = blob.node_type
    switch(nt) {
	case 'table': {
	    content = deMicrosoftify(name, content)
	    break
	}
	case 'code': {
	    // try and manufacture a meaningful id (used in comparison mode)
	    const id = conjourId(name, content)
	    if (id) {
		blob.id = id
	    }
	    // blobs seem to contain illegal html characters. cleanBlob may need to grow in scope.
	    content = cleanBlob(content)
	    content = `<pre>\n${content}\n</pre>`
	    break
	}
	default: {
	    console.error(`Unexpected blob type: ${nt}`)
	    break
	}
    }
    return content
}

/*
   This is a hack to try and figure out the actual minio URL of the image.
   It used to be a relatively easy task:

   img.src.replace(img.baseURI,MINIO_URL);

   But now that we have routes, the baseURL contains some cruft we have to ignore.
   So we count back from the end to find the

   <...>/<docid>_files/<image>

   and replace the <...> with the MINIO_URL
 */
function resolveURL(base, minio) {
    const last = base.lastIndexOf('/')
    const penultimate = base.substring(0, last).lastIndexOf('/')
    const url = base.substring(0, penultimate + 1)
    //console.log(url)
    const retval = base.replace(url, minio)
    //console.log(retval)
    return retval

}

/*
 * Some frustrating hours spent here. Microsoft's exported html is not legal.
 * Surprise surprise. (tables have no tbody). This means React with throw
 * ugly validateDOMNesting warnings in the console that make it look like the
 * sky is falling. To get around this we let the browser parse the html, use its
 * robustness to Seattle bullshit, and use the generated textual representation
 * of the tables.
 */
function deMicrosoftify(filename, text) {
    const parser = new DOMParser()
    const doc = parser.parseFromString(text, 'text/html');
    if (doc.documentElement.querySelector('parsererror')) {
	console.error(`${filename} suffers from ${doc.documentElement.querySelector('parsererror').innerText}`)
	return null
    }
    const body = doc.documentElement.querySelector('body')
    // may as well fix the images while we are at it...
    const images = body.querySelectorAll('img')
    //console.log('# of images', images.length);
    //images.forEach((img) => { console.log(img.src, img.baseURI, MINIO_URL); } )
    images.forEach((img) => { img.src = resolveURL(img.src, MINIO_URL); } )
    // our tables will now have tbodies!
    return body.innerHTML
}




class Blob {

    constructor(data, parent) {
	this.parent = parent
	this.data = data
    }

    caption() {
	if ( this.data['caption'] ) {
	    return this.data['caption'].map((dc) => dc['content'])
	} else {
	    return 'Blob has no caption'
	}
    }

    nt() {
	// currently either a table or code
	return this.data.node_type
    }

    filename() {
	return this.data.filename
    }

    content_hash() {
	return this.data.content_hash
    }
}

/*
   33501-h10_clean 

   syncronous solution:  "load time of 31 blobs: 372ms"
   asyncronous solution: "load time of 31 blobs: 161ms"

 */


export class Blobs {

    constructor(data) {
	//maps the guid to the blob node
	this.index_by_guid = new Map()
	//maps the guid to the blob node
	this.index_by_id = new Map()
	// maps the filename to the blob
	this.content_map = new Map()
	this.data = data
	this.table_list = []
	this.code_list = []
	this.has_tables = false
	this.has_code = false
	
	// we do async ajax calls, wait for them all to finish
	this.promises = []

	this.initialize(this.data, this.data)
    }

    hasTables() {
	return this.has_tables
    }

    hasCode() {
	return this.has_code
    }

    async wait4Blobs() {
	const count = this.promises.length
	await Promise.all(this.promises)
	return count
    }

    add(blob, parent) {
	// get the content first
	const name = blob.filename
	// blobs get reused a lot (empty tables for example are everywhere)
	if (!this.content_map.has(name)) {
	    //this.content_map.set(name, fetchContent(blob))
	    this.content_map.set(name, true) // till the real thing comes along ...
	    this.promises.push(blob2Promise(blob, this.content_map))
	}


	const b = new Blob(blob, parent)
	this.index_by_guid.set(blob.guid, b)
	if (blob.id) {
	    this.index_by_id.set(blob.id, b)
	}
	const nt = b.nt()
	switch(nt) {
	    case 'table': {
		this.table_list.push(b)
		break
	    }
	    case 'code': {
		this.code_list.push(b)
		break
	    }
	    default: {
		console.error(`Unexpected blob type ${nt}`)
		return
	    }
	}
    }

    getBlobByGuid(guid) {
	return this.index_by_guid.get(guid)
    }

    getBlobById(id) {
	return this.index_by_id.get(id)
    }

    getBlobContent(name) {
	return this.content_map.get(name)
    }

    initialize(x, parent) {
        if (!x) {
            return
        }
        const nt = x['node_type']
        switch(nt) {
            case 'document':
		x['document_parts'].forEach((dp) => this.initialize(dp, x))
		return;
            case 'document_part':
		x['subsections'].forEach((s) => this.initialize(s, x))
		x['direct_children'].forEach((c) => this.initialize(c, x))
		return
            case 'section': {
		x['subsections'].forEach((s) => this.initialize(s, x))
		x['direct_children'].forEach((c) => this.initialize(c, x))
		return
	    }
	    case 'figure': {
		x['caption'].forEach((c) => this.initialize(c, x))
		return
	    }
	    case 'list': {
		x['list_items'].forEach((c) => this.initialize(c, x))
		return
	    }
	    case 'paragraph':
	    case 'list_item':
	    case 'abbreviation_entry':
	    case 'defined_term':
	    case 'indented':
	    case 'glossary_entry':
	    case 'note': {
		x['direct_children'].forEach((c) => this.initialize(c, x))
		return
	    }
	    case 'definition_placeholder':
	    case 'sentence': {
		return
	    }
	    case 'reference': {
		return
	    }
	    case 'raw': // gone with the wind?
	    case 'table':
	    case 'code':{
		this.add(x, parent)
		return
	    }
	    default:
		console.error(`Blobs: Don't forget about poor wee ${nt}`)
		return
        }

    }
}
