import * as Types from '../constants/action-types';


// The cache never changes, so there are no setters for it.
import DocumentCache from '../javascript/Cache'

const initialState = { 	busy: true, builder: null, cache: new DocumentCache()  }

function network(state = initialState, action) {
    switch(action.type) {
            // network
	    case Types.SET_BUSY: return {...state, busy: action.payload }
	    case Types.SET_BUILDER: return {...state, builder: action.payload }
	    default: return state
    }
}

export default network
