import React from 'react';
import { connect } from "react-redux";

import DocumentTabs from './DocumentTabs'
import Controls from './Controls'

import { documentViewDimensions } from '../javascript/Realty'

const mapStateToProps = state => {
    return {
	    dimensions: state.user.dimensions,
	    settings: state.user.settings,
    };
}

class ConnectedDocumentView extends React.Component {

    render() {
	    const debug = this.props.settings ? this.props.settings.value('debug') : false
	    const dimensions = documentViewDimensions(this.props.dimensions, debug)
	    //console.log(`Window: ${dimensions ? dimensions.height : null}`)
	    //console.log(`DocumentView: height = ${dimensions.height}`)
	    const style = { marginTop: 80, minHeight: dimensions.height, maxHeight: dimensions.height }
	    return (
	        <div style={style}>
	        <Controls />
	        <DocumentTabs />
	        </div>
	    );
    }
}

const DocumentView = connect(mapStateToProps)(ConnectedDocumentView);

export default DocumentView
