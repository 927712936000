//<network>
export const SET_BUILDER = "SET_BUILDER";
export const SET_BUSY = "SET_BUSY";
//</network>

//<portfolio>
export const SET_CONTROL = "SET_CONTROL";
export const SET_DOCUMENT = "SET_DOCUMENT";
export const SET_FRAGMENT = "SET_FRAGMENT";
export const SET_OTHER = "SET_OTHER";
export const SET_TOC_STATE = "SET_TOC_STATE";
export const SET_IS_STATE = "SET_IS_STATE";
export const SET_LOI_STATE = "SET_LOI_STATE";
export const SET_CST_STATE = "SET_CST_STATE";
export const SET_EI_STATE = "SET_EI_STATE";
//</portfolio>


//<user>
export const SET_SETTINGS = "SET_SETTINGS";
export const SET_CATALOGUE = "SET_CATALOGUE";
export const SET_DIMENSIONS = "SET_DIMENSIONS";

//<Authentication>
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
//</Authentication>
//</user>
