// GUIDs and Occurrences
//
// Because the uids that come fom over sunflower way are not unique (a sentence may
// occur many times in a document, and each occurence will have the same uid), we
// augment the JSON nodes by a Globally Unique ID (guid), and use that to keep track
// of parentage, and other ancestral properties of ocurrences (like the section that
// contains the occurence). The guid is relatively human readable, and is constant
// from session to session (assuming that the raw json doesn't change)
//
// Backward Pointers
//
// a data structure that may be useful for rendering the context of a node.
// backward pointers to the enclosing:
//
// - section
// - smallest renderable fragment
//

//import { v4 as getUUID } from 'uuid';

//import { unquote } from './utils'

import { makeGUID } from './guid'

export class PNode {

    constructor(index, list, data, section, parent, clan, cd) {
        // the index is either null, or the node sits in a list within the parent, and this is its index
        this.index = index
        // this is the list that the index refers to, or null if index is null
        this.list = list
        this.data = data
        this.section = section
        this.parent = parent
        // only sentences have clans: (note, paragraph, figure, abbreviation_entry, glossary_entry)
        // clans are used in indexes to try and indicate what the occurrence of the sentence is.
        this.clan = data.node_type === 'sentence' ? clan : null
        this.clan_data = cd
    }

    sectionLabel() {
        const section = this.section
        if (section.id === section.name) {
            return section.name;
        } else {
            return section.id + ": " + section.name;
        }
    }

    up() {
        return this.section
    }

    forward() {
        if (this.index === null || this.list === null) {
            return null
        }
        let j = this.index + 1
        if (j === this.list.length) {
            return null
        }
        return this.list[j]
    }

    previous() {
        if (this.index === null || this.index === 0) {
            return null
        }
        return this.list[this.index - 1]
    }

}



export class Parentage {

    constructor(data, nodeId) {
        this.occurrence = 0
        // we want occurrence id's to be both globally unique and constant across runs
        this.guid_prefix = nodeId
        // maps a nodes guid to it's pnode
        this.guidMap = {}

        // occurrence map, maps sentence_ids to a list of guids (i.e. the occurrences of those sentences)
        this.occurrenceMap = new Map()

        this.tableIdMap = new Map()

        //the move to rdf means we lose the getFilteredMap so we reconstruct it here
        this.hMap = new Map()

        this.initialize(null, null, data, data, data, null, null)
    }


    hashMap() {
        return this.hMap
    }

    pnode(guid) {
        const rval = this.guidMap[guid]
        return rval ? rval : null
    }

    // sets the guid field of the node to be a newly generated guid, and returns that new id
    tagNode(parent, node) {
        let id = parent ? parent.id : "null"
        if(id.indexOf(' ')) {
            id = id.replaceAll(' ', '_')
        }
        this.occurrence++;
        const guid = makeGUID(this.guid_prefix, id,  this.occurrence)
        node['guid'] = guid
        return guid
    }

    // generate vaguely accurate id's for tables that don't have one
    table_id(tnode, parent_id) {
        if (!this.tableIdMap.has(parent_id)) {
            this.tableIdMap.set(parent_id, 0)
        }
        const count = this.tableIdMap.get(parent_id)
        this.tableIdMap.set(parent_id, count + 1)
        return `Table unnamed-${parent_id.replaceAll(' ', '_')}-${count}`
    }

    occurrences(uid) {
        const rval = this.occurrenceMap.get(uid)
        return rval ? rval : []
    }

    process_node(i, l, x, s, p, c, cd) {
        const guid = this.tagNode(s, x)
        const pnode = new PNode(i, l, x, s, p, c, cd)
        this.guidMap[guid] = pnode
        let uid = x.uid
	// since a hashed thing may appear more than once, we only set the first occurrence of it
	if (!this.hMap.has(uid)){
            this.hMap.set(uid, x)
	}
        if (x.node_type === 'sentence') {
	    if (this.occurrenceMap.has(uid)) {
                    this.occurrenceMap.get(uid).push(guid)
            } else {
                this.occurrenceMap.set(uid, [guid])
            }
        }
    }

    initialize(index, list, x, s, p, c, cd) {
        if (!x) {
            return;
        }
        this.process_node(index, list, x, s, p, c, cd)
        const nt = x['node_type'];
        switch(nt) {
            case 'document': {
                let l = x['document_parts']
                l.forEach((d, i) => this.initialize(i, l, d, x, d, c, cd))
                return
            }
            case 'document_part':  {
                let l0 = x['subsections']
                l0.forEach((d, i) => this.initialize(i, l0, d, x, d, c, cd))
                let l1 = x['direct_children']
                if (!l1) {
		    console.log("VIOLATION", x)
		}
                l1.forEach((d, i) => this.initialize(i, l1, d, x, d, c, cd))
                return
            }
            case 'section': {
                let l0 = x['subsections']
                l0.forEach((d, i) => this.initialize(i, l0, d, x, d, c, cd))
                let l1 = x['direct_children']
                if (!l1) {
		    console.log("VIOLATION", x)
		}
                l1.forEach((d, i) => this.initialize(i, l1, d, x, d, c, cd))
                return
            }
            case 'table': {
                // if the table doesn't have an id then we give it one, 
		// this is for that TableOfTables thingy. But remember that it 
		// was an anonymous table (so won't have incoming references)
                if (!x.id) {
                    x.id = this.table_id(x, s.id)
		    x.anonymous = true
                } else {
		    x.anonymous = false
		}
                // console.log(`table ${x.id}  in ${s.id}`)
                let l = x['caption']
                l.forEach((d, i) => this.initialize(i, l, d, s, d, nt, x))
                return
            }
            case 'figure': {
                let l = x['caption']
                l.forEach((d, i) => this.initialize(i, l, d, s, d, nt, x))
                return
            }
            case 'list': {
                let l = x['list_items']
                l.forEach((d, i) => this.initialize(i, l, d, s, x, c, cd))
                return
            }
            case 'list_item': {
                let l = x['direct_children']
                l.forEach((d, i) => this.initialize(i, l, d, s, x, c, cd))
                return
            }
            case 'paragraph':
            case 'abbreviation_entry':
            case 'glossary_entry':
            case 'note': {
                let l = x['direct_children']
                l.forEach((d, i) => this.initialize(i, l, d, s, x, x.node_type, x))
                return
            }
                // the rest are leafs.
            default: return;
        }
    }

}
