import { Component, Fragment } from 'react';
import { useNavigate } from 'react-router-dom'

import Card from 'react-bootstrap/Card'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';

import TextField from '@mui/material/TextField';

import { ECOLOR, ERROR_COLOR } from '../../javascript/colors'

import { query } from '../../javascript/network/cached_sparql.js'

import { v4 as getUUID } from 'uuid';

import * as QBUtils from '../qb/QBUtils'

import Busy from '../qb/Busy'

import NavigationBar from '../qb/NavigationBar'

function rqs2Query (str) {
    let query = null
    if (str) {
        query = decodeURIComponent(str)
    }
    return query
}

function query2rqs (str) {
    let rqs = null
    if (str) {
	rqs = encodeURIComponent(str)
    }
    return rqs
}

async function fetchState(qs) {
    let q = rqs2Query(qs)
    const start = performance.now()
    let data = []
    let error = null
    try {
	data = await query(q)
    } catch (err) {
	error = err
	console.log(`Caught an error: ${err.toString()}`)
    }
    const end = performance.now()
    const duration = end - start
    console.log(`Got ${data.length} results after ${duration}  ms`, data)
    return { data, query: q, error}
}

export default class RawQueryBrowser extends Component {

    constructor(props) {
	super(props)
	this.debug = QBUtils.monitor(props.user)
	if (this.debug) { console.log('RawQueryBrowser', props) }
	this.state = { data: null, busy: false, query: rqs2Query(props.rqs) }
	this.textFieldHandler = this.textFieldHandler.bind(this)
	this.setBusy = this.setBusy.bind(this)
	this.clearQuery = this.clearQuery.bind(this)
    }

    async componentDidMount() {
	if (this.debug) { console.log(">RawQueryBrowser.componentDidMount") }
	if (this.props.rqs) {
	    let state = await fetchState(this.props.rqs)
	    this.setState( { busy: false, ...state } )
	}
	if (this.debug) { console.log("<RawQueryBrowser.componentDidMount") }
    }


    async componentDidUpdate(prevProps) {
	if (this.debug) { console.log(">RawQueryBrowser.componentDidUpdate") }
	if (prevProps.rqs !== this.props.rqs) {
	    if (this.props.rqs) {
		let state = await fetchState(this.props.rqs)
		this.setState( { busy: false, ...state } )
	    } else {
		this.setState( { data: null, busy: false,  query: null } )
	    }
	} else {
	    if (this.state.busy) {
		this.setState( { busy: false } )
	    }
	}
	if (this.debug) { console.log("<RawQueryBrowser.componentDidUpdate") }
    }

    goIsDisabled() {
	return this.state.disabled || !this.state.query
    }

    setBusy() {
	this.setState( { busy: true } )
    }

    textFieldHandler(event) {
	this.setState({ query: event.target.value, disabled: false });
    }

    clearQuery(event) {
	this.setState({ query: '', disabled: true, data: null, error: null });
    }

    render() {
	if (this.debug) { console.log('RawQueryBrowser.render', this.state) }

	const style = QBUtils.makeStyle(this.props.user)

	if (this.state.busy) {
	    return ( <Busy/> );
	} else {
	    const text = this.state.query ? this.state.query : ""
	    const rqs = query2rqs(text)
	    return (
		<Fragment>
		<NavigationBar header={'Raw Query Browser'} style={style} />
		<Card border="info" style={{ ...style}}>
		<Card.Body>
		<Grid container spacing={4}>
		<Grid item xs={12}>
		<TextField multiline rows={20} style ={{width: '50%'}} id="outlined-multiline-flexible" label="Jena Query" value={text} onChange={this.textFieldHandler}/>
		</Grid>
		<Grid item xs={2}>
		<ClearButton />
		</Grid>
		<Grid item xs={2}>
		<GoButton disabled={this.goIsDisabled()} setBusy={this.setBusy} rqs={rqs}/>
		</Grid>
		</Grid>
		</Card.Body>
		</Card>
		{displayResults(this.props.user, this.state)}
		</Fragment>
	    );
	}
    }
}

function displayResults(user, state) {
    if (state.error) {
	return displayError(user, state)
    } else {
	return displayResultsTable(user, state)
    }
}

function displayError(user, state) {
    const style = QBUtils.makeStyle(user)

    return (
        <Card border="info" style={{ maxHeight: style.dimensions.height, ...style}}>
        <Card.Header> <span style={{ color: ERROR_COLOR }}>Computer says NO...</span> </Card.Header>
        <Card.Body>
	<span style={{ color: ERROR_COLOR }}>{state.error.toString()}</span>
        </Card.Body>
        </Card>
    )
}

 

function computeColumns(elem) {
    const retval = Object.getOwnPropertyNames(elem)
    return retval
}

function object2Row(obj, index, uid, columns) {
    const cells = columns.map((key, j) => (<TableCell key={uid + 'cell' + index + 'x' + j} component="th" scope="row"> {JSON.stringify(obj[key].value)} </TableCell>))
    return (
	<TableRow key={uid + 'row' + index}
	sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
	    >
	{cells}
	</TableRow>
    )
}


function state2Heading(state) {
    if (state.data.length === 0) {
	return 'Sorry, There were zero query results.'
    } else {
	return `The query returned ${state.data.length} results, listed below:`
    }
}

function displayResultsTable(user, state) {
    let data = state.data
    if (!data) {
	return null;
    }
    const style = QBUtils.makeStyle(user)

    const heading = state2Heading(state)


    if (data.length === 0) {
	return (
        <Card border="info" style={{ maxHeight: style.dimensions.height, ...style}}>
        <Card.Header> <span style={{ color: ECOLOR }}>{heading}</span> </Card.Header>
        <Card.Body>
	No Results
        </Card.Body>
        </Card>
	)
    }

    const uid = getUUID()
    const columns = computeColumns(data[0])
    return (
        <Card border="info" style={{ maxHeight: style.dimensions.height, ...style}}>
        <Card.Header> <span style={{ color: ECOLOR }}>{heading}</span> </Card.Header>
        <Card.Body>    
	<TableContainer key={uid + 'container'} component={Paper}>
	<Table key={uid + 'table'} sx={{ minWidth: 650 }} size="small"  aria-label="simple table">
	<TableHead key={uid + 'head'}>
	<TableRow key={uid + 'header-row'}>
	{columns.map((text, index) => (<TableCell key={uid + 'header-cell' + index}><Typography variant="h5">{text}</Typography></TableCell>))}
	</TableRow>
	</TableHead>
	<TableBody>
	{data.map((obj, index) => object2Row(obj, index, uid, columns))}
        </TableBody>
	</Table>
	</TableContainer>
        </Card.Body>
        </Card>
    );


}


function GoButton(props) {
    let navigate = useNavigate();
    const handleClick = () => {
	props.setBusy()
	let url = `/rqb/${props.rqs}`
	console.log(`Navigating to ${url}`)
	return navigate(url)
    }
    return (
	<Button color="secondary" variant="contained"  disabled={props.disabled} onClick={handleClick}>
	Go
	</Button>
    )
}


function ClearButton(props) {
    let navigate = useNavigate();

    const handleClick = () => {
	let url = `/rqb`
	console.log(`Navigating to ${url}`)
	return navigate(url)
    }

    return (
	<Button color="success" variant="contained"  onClick={handleClick}>
	Clear
	</Button>
    )


}
