// a data structure that may be useful for rendering a "List of Figures"

export class LOFNode {

    constructor(data) {
        this.id = data.id
        this.data = data
    }

    caption() {
	if ( this.data['caption'] ) {
	    return this.data['caption'].map((dc) => dc['content'])
	} else {
	    return 'Image has no caption'
	}
    }
}

export class LOF {

    constructor(data) {
        this.list = [];
	// figure id to LOFNode
        this.index = {};
	// has figures
	this.figures = false
        this.initialize(data);
    }

    hasFigures() {
	return this.figures
    }

    hasFigure(id) {
	return !!this.index[id]
    }

    node4Figure(id) {
	return this.index[id]
    }

    initialize(x) {
        if (!x) {
            return;
        }
        const nt = x['node_type'];
        switch(nt) {
            case 'document': {
                x['document_parts'].map((d) => this.initialize(d)); return null;
            }
            case 'document_part':  {
		x['subsections'].map((d) => this.initialize(d));
		x['direct_children'].map((d) => this.initialize(d));
		return null;
	    }
            case 'section': {
		x['subsections'].map((d) => this.initialize(d));
		x['direct_children'].map((d) => this.initialize(d));
		return null;
	    }
	    case 'figure': {
		let node = null
		// sometimes it's just an inlined image with id === ""
		if (x.id) {
		    this.figures = true
		    node = new LOFNode(x)
		    this.list.push(node);
		    this.index[x.id] = node;
		}
		return node;
	    }
            // anything else can be ignored for now.
            default: return null;
        }
    }

}


