import React  from 'react';

import { useNavigate } from 'react-router-dom'

import QueryStatsIcon from '@mui/icons-material/QueryStats';
import IconButton from '@mui/material/IconButton';

import { ECOLOR } from '../../javascript/colors'
import { EToolip } from '../EToolip'



export default function QueryBrowserButton (props) {

    let navigate = useNavigate();

    const handleClick = (event) => {
	let url = `/qb`
	console.log(`Navigating to ${url}`)
	return navigate(url)
    }

    return (
        <EToolip title="The Entity Query Browser">
	<IconButton
        style={{ color: ECOLOR }}
        aria-label={'The query browser for cross corpus queries'}
        component="span"
        onClick={handleClick}
        size="large">
	<QueryStatsIcon />
	</IconButton>
	</EToolip>
    );



}
