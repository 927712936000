import * as Types from '../constants/action-types';

export function setBuilder(payload) {
    return { type: Types.SET_BUILDER, payload }
}

export function setBusy(payload) {
    return { type: Types.SET_BUSY, payload }
}

export function setControl(payload) {
    return { type: Types.SET_CONTROL, payload }
}

export function setCatalogue(payload) {
    return { type: Types.SET_CATALOGUE, payload }
}

export function setDimensions(payload) {
    return { type: Types.SET_DIMENSIONS, payload }
}

export function setDocument(payload) {
    return { type: Types.SET_DOCUMENT, payload }
}

export function setFragment(payload) {
    return { type: Types.SET_FRAGMENT, payload }
}

export function setOther(payload) {
    return { type: Types.SET_OTHER, payload}
}

//<tree state>
export function setTOCState(payload) {
    return { type: Types.SET_TOC_STATE, payload}
}

export function setISState(payload) {
    return { type: Types.SET_IS_STATE, payload}
}

export function setLOIState(payload) {
    return { type: Types.SET_LOI_STATE, payload}
}

export function setCSTState(payload) {
    return { type: Types.SET_CST_STATE, payload}
}

export function setEIState(payload) {
    return { type: Types.SET_EI_STATE, payload}
}


//</tree state>

export function setSettings(payload) {
    //console.log(payload)
    return { type: Types.SET_SETTINGS, payload}
}

//<Authentication>

// How often to refresh the auth token
// const TOKEN_REFRESH_MIN = 5;

export function loginSuccess(username, token) {
  return { type: Types.LOGIN_SUCCESS, payload: { username, token }}
}

export function loginError(username, error) {
  return { type: Types.LOGIN_ERROR, payload: { username, error }}
}

export function logout() {
  return { type: Types.LOGOUT }
}

export function refreshToken(token) {
  return { type: Types.REFRESH_TOKEN, payload: { token }}
}

//</Authentication>
