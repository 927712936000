import React from 'react';
import { connect } from "react-redux";

import Card from 'react-bootstrap/Card'

import { TEXT_FONT_SIZE  } from '../../javascript/constants'
import { documentDimensions } from '../../javascript/Realty'

import { ECOLOR } from '../../javascript/colors'

import FragmentNavigation from './FragmentNavigation'

import { Root, renderFragment, resolveFragment } from './DocumentUtils';

import DocumentScroller from './DocumentScroller'

// Translating the JSON see:
// https://github.com/SRI-CSL/Effigy/generated-files/source-documents/schemas/source-schema.json
// into HTML via JSX

const mapStateToProps = state => {
    return {
        portfolio: state.portfolio,
        user: state.user,
    };
}

class ConnectedDocument extends React.Component {

    render() {
        const document = this.props.portfolio.document
        const other = this.props.portfolio.other
        const role = this.props.role;  // if role = 0 we are the star (i.e. this document); role > 0 we are a supporting actor (i.e. that document)
        const primary = this.props.role ? other : document;
        const secondary = this.props.role ?  document : other;

        if (!primary) {
            return null;
        }

        let fragment = this.props.portfolio.fragment;

        let subtext = ""

        if (secondary !== null) {
            const us = primary.root()
            const them = secondary.root()
            subtext = <span className="text-danger">Comparing {us} with {them} </span>
        }

        const debug = this.props.user.settings ? this.props.user.settings.value('debug') : false
        const dimensions = documentDimensions(this.props.user.dimensions, this.props.portfolio.control !== null, other !== null, debug)

	if (false) {
            console.log(`Window: [${this.props.user.dimensions.width}, ${this.props.user.dimensions.height}]`, dimensions)
            console.log(`Document: control: ${this.props.portfolio.control}`)
            console.log(`Document: maxHeight: ${dimensions.height}`)
	}

        const delta = 40
        const width = dimensions.width - delta
        const style = { margin: '1rem', fontSize: TEXT_FONT_SIZE, overflow: 'auto', width: width}


        if (!fragment) {
	    if (true) {
//<Experimental>
		const data = primary.streamedData() 
		return (
		    <Card border="info" style={{ maxHeight: dimensions.height, ...style}}>
			<Card.Header> <span style={{ color: ECOLOR }}>{primary.jsonData()['title']}</span> &nbsp;&nbsp; {subtext}
			</Card.Header>
			<Card.Body>
			    <DocumentScroller data={data} document={primary} other={secondary} height={dimensions.height} debug={debug}/>
			</Card.Body>
		    </Card>
		)
//</Experimental>
	   } else {
		return (
                    <Card border="info" style={{ maxHeight: dimensions.height, ...style}}>
			<Card.Header> <span style={{ color: ECOLOR }}>{primary.jsonData()['title']}</span> &nbsp;&nbsp; {subtext}
			</Card.Header>
			<Card.Body>
			    <Root document={primary} other={secondary} debug={debug}/>
			</Card.Body>
                    </Card>
		);
	    }
        }
	
        // if role is not 0, then we have to figure out our corresponding fragment if there is one.
        if (role) {
            fragment = resolveFragment(fragment, primary)
        }

        return (
            <Card border="info" style={{ minWidth: width, maxHeight: dimensions.height - 30, ...style}}>
            <Card.Header> <span style={{ color: ECOLOR }}>{primary.jsonData()['title']}</span>
                                                         &nbsp;&nbsp; {subtext} &nbsp;&nbsp;
            <br></br>
            <FragmentNavigation></FragmentNavigation>
            </Card.Header>
            <Card.Body>
            {renderFragment(fragment, primary, secondary, debug)}
            </Card.Body>
            </Card>
        );
    }
}


const Document = connect(mapStateToProps, null)(ConnectedDocument);

export default  Document;


