// lightweight document cache 
// NOTE: This duplicates the network cache, but is used slightly differently
// Coloring the Catalogue entries, and making the redownload button visible.
// Could just be a hashset, rather than a map.


export default class Cache {

    constructor() {
	this.clear()
    }

    size() {
	return this._size
    }

    clear() {
	this._cache = {}
	this._size = 0
    }

    contains(id) {
	return this.fetch(id) !== null 
    }

    fetch(id) {
	const doc = this._cache[id]
	if (doc) {
	    return doc
	}
	return null
    }

    update(doc) {
	if (doc) {
	    const id = doc.nodeId
	    if (id) {
		const val = this._cache[id]
		this._cache[id] = doc
		if (!val) {
		    this._size += 1
		} else {
		    return true
		}
	    }
	}
	return false
    }

}
