
import { useNavigate } from 'react-router-dom'

import Link from '@mui/material/Link';

import { entityColor } from '../../javascript/colors'


import { encodeEntity } from './WikiUtils'

export default function WikiLink (props) {

    const entity = props.entity
    const role = props.role
    const text = props.text ? props.text : entity

    let navigate = useNavigate();

     if (entity && role)  {
	const handleClick = () => {
	    let url = `/wiki/${encodeEntity(entity)}/${role}`
	    console.log(`Navigating to ${url}`)
	    return navigate(url)
	}

	return (
	    <Link component="button" color={entityColor(role)} variant="body2"  onClick={handleClick}> {text ? text : entity} </Link>
	)
    } else {
	return null
    }
}
