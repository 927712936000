import React  from 'react';
import { connect } from "react-redux";

import { styled } from '@mui/material/styles';

import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';

import { setControl } from "../../actions/index";

import { MD_CONTROL } from '../../constants/controls'

import { ECOLOR } from '../../javascript/colors'
import { EToolip } from '../EToolip'


const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));


function mapDispatchToProps(dispatch) {
    return {
	    setControl: control => dispatch(setControl(control)),
    };
}

const mapStateToProps = state => {
    return {
	    control: state.portfolio.control,
	    document: state.portfolio.document,
    };
}

class ConnectedDocumentNameplate extends React.Component {

    constructor (props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick = (event) => {
	    const  off = this.props.control === MD_CONTROL
	    this.props.setControl(off ? null :  MD_CONTROL );
	    //console.log(off ? 'MD Clicked OFF' : 'MD Clicked ON')
    }


    render() {
	    const document = this.props.document
	    if (document == null) {
	        return null
	    }
	    const  off = this.props.control === MD_CONTROL
	    return (
	        <Paper
	        sx={{
		        display: 'flex',
		        justifyContent: 'center',
		        flexWrap: 'wrap',
		        listStyle: 'none',
		        p: 0,
		        m: 0,
	        }}
	        component="ul"
	        >
	        <ListItem>
            <EToolip title={document.data.title}>
	        <Chip sx={{color: ECOLOR}} aria-label={`${off ? "close" : "open"} the document's meta-data`} label={document.nodeId} variant="outlined" size="small" onClick={this.handleClick}/>
	        </EToolip>
	        </ListItem>
	        </Paper>
	    )
    }
}

const DocumentNameplate = connect(mapStateToProps, mapDispatchToProps)(ConnectedDocumentNameplate);


export default DocumentNameplate;
