
// Our home grown occurrence ids

export function makeGUID(prefix, id, nth) {
    return `${prefix}_${id}_${nth}`
}

const GUID = new RegExp(/([A-Za-z0-9-]*)_([\w._]*)_(\d*)/)

const GUID_clean = new RegExp(/([A-Za-z0-9-]*_clean)_([\w._]*)_(\d*)/)


export function isGUID(guid) {
    return GUID_clean.test(guid) || GUID.test(guid)
}

export function parseGUID(guid) {
    if (GUID_clean.test(guid)) {
	const match = GUID_clean.exec(guid)
	return [match[1], match[2], match[3]] 
    } else if (GUID.test(guid)) {
	const match = GUID.exec(guid)
	return [match[1], match[2], match[3]] 
    }
    return null
}
