import { Component, Fragment } from 'react';
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Card from 'react-bootstrap/Card'

import Typography from '@mui/material/Typography';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import IconButton from '@mui/material/IconButton';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';

import { useParams } from "react-router-dom";

import { google_analytics_pageview } from '../../javascript/ga'

import * as QBUtils from '../qb/QBUtils'
import NavigationBar from '../qb/NavigationBar'

import { props2query, fetchState, distillId }  from './MentionsUtils'

import Busy from '../qb/Busy'

import { innerHtml, rdfId2jsonId } from '../../javascript/utils'
import { ECOLOR } from '../../javascript/colors'

export function MentionsLoaderHook(props) {

    google_analytics_pageview()

    let params = useParams()

    let doc = params.Document  || null
    let id = params.Identifier || null
    let node = params.Node     || null

    const user = useSelector(state => state.user)
    
    const debug = QBUtils.monitor(user)

    if (debug) { console.log("MentionsLoaderHook", params) }

    return (
	<Mentions user={user} doc={doc} id={id} node={node}/>
    )

}


export default class Mentions extends Component {

    constructor(props) {
	super(props)
	this.debug = false //QBUtils.monitor(props.user)
	if (this.debug) { console.log('Mentions', props) }
	this.state = { data: null, busy: true, query: props2query(props) }
    }


    async componentDidMount() {
	if (this.debug) { console.log(">Mentions.componentDidMount") }
	if (this.state.query) {
	    let state = await fetchState(this.state.query)
	    this.setState( { busy: false, ...state } )
	}
	if (this.debug) { console.log("<Mentions.componentDidMount") }
    }

    render() {
	if (this.debug) { console.log('Mentions.render', this.state) }
	if (this.state.busy) {
	    return ( <Busy/> )
	} else {
	    return (
		<MentionsContent user={this.props.user} doc={this.props.doc} id={this.props.id} node={this.props.node} data={this.state.data} />
	    )
	}
    }
}


function MentionsContent (props) {

    const user = props.user

    const header = `References to ${props.node} ${distillId(props.id, props.node)} in ${props.doc}.`

    const style = QBUtils.makeStyle(user)

    const data = props.data
    
    let content = null
    if (!data || data.length === 0) {
	content = <Typography> Sorry we didn't find any such references in the corpus.</Typography> 
    } else {
	content = <MentionsReferences data={data} doc={props.doc} id={props.id} node={props.node}> </MentionsReferences> 
    }

    return (
	<Fragment>
	<NavigationBar header={header} style={style} />
        <Card border="info" style={{ maxHeight: style.dimensions.height, ...style}}>
        <Card.Body>
	{content}
        </Card.Body>
        </Card>
	</Fragment>
    )


}

function MentionsReferences (props) {
    const data = props.data
    const header = data.length > 1 ?
                 `There are ${data.length} references to ${props.node} ${distillId(props.id, props.node)} in ${props.doc} in the corpus` :
                 `There is ${data.length} reference to ${props.node} ${distillId(props.id, props.node)} in ${props.doc} in the corpus`


    return (
	<Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="mentions-references"
        id="mentions-references"
        >
        <Typography style={{ color: ECOLOR }}>{header}</Typography>
        </AccordionSummary>
	<AccordionDetails>
	<List key={'mentions-references-list'} dense={true}>
	{data.map((d, i) => <MentionsReferenceItem key={'mentions-reference' + i} reference={d} index={i} />)}
	</List>
	</AccordionDetails>
	</Accordion>
    )
}

function MentionsReferenceItem (props) {
    const reference = props.reference
    const index = props.index

    //console.log(`reference ${index}: `, reference)

    const doc_id = rdfId2jsonId(reference.src_doc.value)
    const sent_id = rdfId2jsonId(reference.sent_id.value)

    const title = reference.src_title.value
    const version = reference.src_version.value

    const text = reference.sent_content.value
    
    const doc = `${title} version ${version}`

    let navigate = useNavigate();

    const handleClick = () => {
	let url = `/document/${doc_id}/sentence/${sent_id}`
	console.log(`Navigating to ${url}`)
	return navigate(url)
    }

     return (
    	<ListItem key={'mentions-reference-item' + index}
        secondaryAction={
            <IconButton style={{ color: ECOLOR }} edge="end" aria-label="navigate to the document" onClick={handleClick}>
            <AssistantDirectionIcon />
            </IconButton>
	}
	>
	<ListItemText key={'mentions-reference-item-text' + index} primary={innerHtml(text)} secondary={doc}/>
    </ListItem>
)

}
