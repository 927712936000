import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import Typography from '@mui/material/Typography';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import Checkbox from '@mui/material/Checkbox';

import { ECOLOR } from '../javascript/colors'

import { version } from '../javascript/version';

import CloseMe from './CloseMe'

function PaperComponent(props) {
    return (
	<Draggable
	handle="#draggable-dialog-title"
	cancel={'[class*="MuiDialogContent-root"]'}
	>
      <Paper {...props} />
    </Draggable>
  );
}

export default function SettingsDialog(props) {

    const handleChange = props.handleChange

    const settings = props.settings

    const styling = {
	color: ECOLOR,
	'&.Mui-checked': {
	    color: ECOLOR,
	}
    }


    return (


	<Dialog
	open={props.open}
	onClose={props.handleClose}
        maxWidth='md'
        fullWidth={false}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
	BackdropProps={{ style: { backgroundColor: "transparent" }}}
	>
        <DialogTitle style={{ cursor: 'move'}} id="draggable-dialog-title">
	
	<Typography sx={{ color: ECOLOR }} align="center"><DragHandleIcon fontSize="small" sx={{ color: ECOLOR }} />Semantic Browser v {version}<DragHandleIcon fontSize="small" sx={{ color: ECOLOR }} /></Typography>
	</DialogTitle>
	<DialogContent>
	{
	}
	<ul>
	<li> <Checkbox sx={styling} onChange={handleChange} checked={settings.value('issues')} value='issues' inputProps={{ 'aria-label': 'Toggle issues' }}/> Structural Issues </li>
	<li> <Checkbox sx={styling} onChange={handleChange} checked={settings.value('cst')} value='cst' inputProps={{ 'aria-label': 'Toggle concrete syntax trees' }}/>  CSTs </li>
	<li> <Checkbox sx={styling} onChange={handleChange} checked={settings.value('entities')} value='entities' inputProps={{ 'aria-label': 'Toggle entities' }}/>  Entities </li>
	<li> <Checkbox sx={styling} onChange={handleChange} checked={settings.value('debug')} value='debug' inputProps={{ 'aria-label': 'Toggle debug' }}/>  Debug </li>
	</ul>
	</DialogContent>
	<DialogActions>
	<CloseMe handleClose={props.handleClose} />
        </DialogActions>
      </Dialog>
  );
}

