import * as NS from '../../javascript/namespaces'

import { roleList } from '../../javascript/roles'

import { query } from '../../javascript/network/cached_sparql.js'

import { TEXT_FONT_SIZE  } from '../../javascript/constants'
import { documentDimensions } from '../../javascript/Realty'

export function monitor(user) {
    return user && user.settings ? user.settings.value('debug') : false
}

export function makeStyle(user) {
    const debug = monitor(user)
    const dimensions = documentDimensions(user.dimensions, false, false, debug)
    const delta = 110
    const width = dimensions.width - delta
    const style = { margin: '1rem', fontSize: TEXT_FONT_SIZE, overflow: 'auto', width: width, dimensions: dimensions }
    return style
}

export async function fetchData(qs) {
    const start = performance.now()
    let results = await query(qs)
    const end = performance.now()
    const duration = end - start
    console.log(`Got ${results.length} results after ${duration}  ms`, results)
    return results
}


export const roles = roleList();
export const emptyFstate = roles.map((r) => { return false; })

export function qbState2String (state) {
    return `${JSON.stringify(state.fstate)}.${encodeURIComponent(state.prefix)}`
}

export async function computeState(qbs) {
    const state = string2QBState(qbs)
    let fstate = state.fstate
    let prefix = state.prefix
    let qs = state2Query(state)
    let data = await fetchData(qs)
    return { fstate, prefix, data }
}

export function string2QBState (str) {
    let fstate = emptyFstate
    let prefix = null
    let data = null
    if (str) {
        let components = str.split('.')
        fstate = JSON.parse(components[0])
        prefix = decodeURIComponent(components[1])
    }
    return { prefix, fstate, data }
}

/*
export function fstate2Disjunction(fstate) {
    let retval = null
    for (let i = 0; i < roles.length; i++) {
        if (fstate[i]) {
            if (retval) {
                retval += " || "
            } else {
                retval = ""
            }
            retval += `?type = "${roles[i]}"`
        }
    }
    return "(" + retval + ")"
}
*/

export function fstate2RolesRegex(fstate) {
    let retval = null
    for (let i = 0; i < roles.length; i++) {
        if (fstate[i]) {
            if (retval) {
                retval += "|"
            } else {
                retval = ""
            }
            retval += `${roles[i]}`
        }
    }
    return `regex(?type, "^(${retval})$")`
}

export function fstate2English(fstate) {
    let retval = null
    let solitary = true
    for (let i = 0; i < roles.length; i++) {
        if (fstate[i]) {
            if (retval) {
                solitary = false
                retval += " or a "
            } else {
                retval = ""
            }
            retval += `"${roles[i]}"`
        }
    }
    if (!solitary) {
        retval = ' either a ' + retval
    }
    return retval    
}

export function state2Heading (count, state) {
    const yadayada = fstate2English(state.fstate)
    return `${count} results for entities that begin with the prefix "${state.prefix}" and have the role of ${yadayada}` 
}



export function state2Query (state) {
    const prefix = state.prefix
    if (!prefix) {
        return null
    }
    //const disjunction = fstate2Disjunction(state.fstate)
    const regex = fstate2RolesRegex(state.fstate)
    return `
PREFIX rdf: <${NS.RDF}>
PREFIX src: <${NS.SRC}>
PREFIX ent: <${NS.ENTITY}>
PREFIX ef: <${NS.EFFIGY}> 
SELECT DISTINCT ?text ?type
WHERE {
    [] a ef:ImportedDocument;
    ef:sourceGraph ?sg;
    ef:entityGraph ?eg .
                     GRAPH ?sg {
    ?doc a src:Document
                     } .
                     GRAPH ?eg {
    ?er a ent:EntityProcessorSentenceResult ;
                         ent:extracts/rdf:rest*/rdf:first ?ext .
    ?ext ent:text ?text ;
                         ent:entity_type ?type
                         FILTER (strStarts( ?text, "${prefix}" ) && ${regex})
                     } 
}
ORDER BY ?text
    `
}

