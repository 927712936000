/*   EDIT IN TEXT MODE!  */


import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';

// catalogue
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
// settings
import SettingsIcon from '@mui/icons-material/Settings';
// user guide
import InfoIcon from '@mui/icons-material/Info';
// logout
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
// entity query browser
import QueryStatsIcon from '@mui/icons-material/QueryStats';
// raw query browser
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';


// table of contents
import TocIcon from '@mui/icons-material/Toc';
// list of figures
import ImageIcon from '@mui/icons-material/Image';
// list of tables
import GridViewIcon from '@mui/icons-material/GridView';
// index of entities
import ShareIcon from '@mui/icons-material/Share';
// index of CSTs
import ParkIcon from '@mui/icons-material/Park';
// debug search
import SearchIcon from '@mui/icons-material/Search';
// debug
import BugReportIcon from '@mui/icons-material/BugReport';
// unknown error
import NotListedLocationSharpIcon from '@mui/icons-material/NotListedLocationSharp';
// unknown uncurated
import NotListedLocationOutlinedIcon from '@mui/icons-material/NotListedLocationOutlined';

// local
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
// external
import LocationOnTwoToneIcon from '@mui/icons-material/LocationOnTwoTone';
// incoming references
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
// structural issues
import ReportIcon from '@mui/icons-material/Report';

// entity wiki
import FeedIcon from '@mui/icons-material/Feed';

import { ACTOR_COLOR, DATA_COLOR, MESSAGE_COLOR, PROCEDURE_COLOR, NUMBER_COLOR, LOCATION_COLOR , ECOLOR, ERROR_COLOR, LINK_COLOR, DISABLED } from '../javascript/colors'

import sunflower_logo from '../images/sunflower.png'
import sri_logo from '../images/sri.jpg'
import nameplate from '../images/nameplate.png'


import { google_analytics_pageview } from  '../javascript/ga'

const icon_size = 25
const icon_style = { color: ECOLOR, fontSize: icon_size }


export default function Guide(props) {

	google_analytics_pageview('/guide')

    return (
<React.Fragment>
<CssBaseline />
<Container maxWidth="lg">


<h1>
<img src={sunflower_logo} width="30" height="30" className="d-inline-block align-center" alt="Sunflower logo" />
&nbsp;
Effigy Semantic Browser User Guide
&nbsp;
	<img src={sri_logo} alt="SRI logo"  className="d-inline-block align-center" width="30" height="30"/>
	</h1>


	<h2> Loading a document </h2>

	<p>
	Once the browser is up and running and has contacted the Sunflower
	database (the spinning wheels vanish).  Your browsing experience can commence.
					    </p>

	<ul>
	<li> <LocalLibraryIcon style={icon_style} /> You can choose a document to
	browse from the "Document Catalog", which can be opened by clicking on this icon.
									  </li>
	<li> <SettingsIcon style={icon_style} />  You can adjust your setting by clicking on the settings icon.
												</li>
	<li> <InfoIcon style={icon_style} /> You can peruse the user guide by clicking on the information icon.
												</li>
	<li> <QueryStatsIcon style={icon_style} /> You can query the entities in the corpus by clicking on the entity query browser icon.
														   </li>
	<li> <ExitToAppIcon style={icon_style} /> You can logout by clicking on the exit icon.
									       </li>
	</ul>


	<p>
	Please be aware that certain features of the document are only downloaded if the user's settings
indicate that they are desired. This will be covered in more detail when discussing the settings.
</p>

<h2>Browsing a document</h2>


<p>
Upon choosing a
document, the browser will contact the sunflower database to obtain
the chosen document. Again when the spinners stop the network task has
completed, and the document selected will be displayed.
The icons that appear in the catalog will change color according to
the current state. Dark purple indicates the document is available but has yet to be downloaded.
Lighter purple means that the document has been downloaded (and so is available via the cache).
Green indicates that this is the document currently displayed in the semantic browser.
</p>

<p>
Once the document has loaded, you should see some more
icons on the toolbar, and actual content in the panel below the
toolbar.
The new icons are buttons, and they have tooltips explaining
what they are, so just mouse over them.
They are, from left to right:
</p>
<ul>
  <li> <img src={nameplate} className="d-inline-block align-center" alt="nameplate button" /> Document Nameplate and Meta Data button</li>
  <li><TocIcon style={icon_style} /> Table of Contents </li>
  <li><ImageIcon style={icon_style} />  List of Figures </li>
  <li><GridViewIcon style={icon_style} />   List of Tables </li>
</ul>
<p>
Other buttons may also show up according to your settings (and the document
you have loaded). These are, from left to right:
</p>
<ul>
  <li><ShareIcon style={icon_style} /> Index of Entities </li>
  <li><ParkIcon style={icon_style} />  Index of Concrete Syntax Trees (CSTs for short)</li>
  <li><SearchIcon style={icon_style} /> Debug Search</li>
</ul>
<p>
These icons are also buttons, and they have tooltips identifying their function.
</p>

<p>
Clicking on one of these icons will open a small scrollable area above
the document pane that contains the chosen control. For example the
table of contents is a navigable tree widget containing active links
to the various parts of the document. To close the region, just click
on the chosen icon again.
</p>

<p>
If there are multiple versions of the chosen document, another
dropdown menu "Compare" will appear next to the "Document"
dropdown. Selecting a document to compare will again initiate network
communication with the sunflower database, and will be indicated by
the spinners.  Documents are cached by the browser, so repeating this
process will not always result in spinners appearing.
</p>

<h2>Comparing one version of a document with another</h2>

<p>
One you have selected a comparison document, the pane beneath the
toolbar should contain two tabs, the original (selected) tab, and the
tab containing the "other" document.
</p>

<p>
Things that are changed with be indicated in red. A good place to
start is to open the table of contents (one of the icons on the
toolbar) and look for things in red. If you click on one in the table
of contents tree it will "zoom" both tabs to the selected section or
annex.  Switching between tabs can be helpful in comparing the
differences.
</p>

<p>
One thing to note, is that switching tabs has no effect on the
semantics of the toolbar icons like the "Table of Contents". It still
refers to the main original document.
</p>

<h2>User Settings and the various modes.</h2>

<p>
The cog icon  <SettingsIcon style={icon_style} /> that appears on the right of the toolbar, next to the SRI
logo, is the button that will open the settings popup dialog. You will also see the
Semantic Browser's version number there.
				</p>

	<p>
	These mode settings or preferences are persistent across
	sessions. Currently there are four additional modes, (and thus sixteen
	    combinations):
	</p>

	<ul>
	<li> <b>Structural Issues</b>:  Indicate places in the text where extraction
	issues were encounted. When enabled, places in the text where extraction issues occurred are annotated by
	the <ReportIcon style={icon_style} /> button, and mousing over this icon will reveal the issues. </li>

	<li> <b>CSTs</b>: AKA concrete syntax trees, indicate in the text where a
	sentence has been analyzed and allow the user to examine the
	concrete syntax tree. When this mode is enabled, sentences that have a CST will be indicated by 
	the addition of <ParkIcon style={icon_style} /> button, which when pressed will open a dialog 
        with the CST.</li> 

	<li> <b>Entities</b>: indicate in the text via color highlighting and
	tooltips the phrases that have been identified as entities. The
	entities are only displayed when viewing a fragment of the
	document, and <b>more
	importantly</b>, when the document was downloaded, your settings mode
	for "Entities" was checked (if not you can always reload the document
	    from the catalog). We discuss entities in more detail in the next section.
			     </li>

	<li> <b>Debug</b>: in this mode certain features contain more information for our developers.
											For more detail see the <b>Debug</b> section below.
														      </li>
	</ul>


	<h2> Entities </h2>

	<p>
	There are currently six types of entities, color coded:
	</p>

	<ul>
	<li> <span style={{ color: ACTOR_COLOR }}> Actor </span>
	</li>
	<li> <span style={{ color: DATA_COLOR }}> Data </span>
	</li>
	<li> <span style={{ color: MESSAGE_COLOR }}> Message </span>
	</li>
	<li> <span style={{ color: PROCEDURE_COLOR }}> Procedure </span>
	</li>
	<li> <span style={{ color: NUMBER_COLOR }}> Number </span>
	</li>
	<li> <span style={{ color: LOCATION_COLOR }}> Location </span>
	</li>
	</ul>

	Entities in the text have active tooltips describing their type and the 
	<FeedIcon style={icon_style} /> button, which, when clicked, will navigate the browser to the Wiki page for that entity.

													      
        <h4><span style={{ color: LOCATION_COLOR }}>  Location Entities </span></h4>

	<p>
	Locations can either be <b><em>internal</em></b> or <b><em>external</em></b>, depending on whether they refer to a location
	in the currently loaded document or not. They can be <b><em>local</em></b> or <b><em>global</em></b>, depending on whether
	they refer to a specific part or the entirety of a document. These two dichotomies are orthogonal.
							    </p>

	Location entities are immediately followed by a Location Link icon button.
								   <ul>
	<li> A link that can be followed
	has a circle in its interior, it is two tone if it is external, and outlined if it is internal.
											<ul>
	<li><LocationOnOutlinedIcon style={icon_style} /> an <b>internal</b>  link that may be followed (i.e.. by clicking on it).
														   </li>
	<li><LocationOnTwoToneIcon style={icon_style} /> an <b>external corpus</b> link that may be followed (i.e.. by clicking on it).
														 </li>
	<li><LocationOnTwoToneIcon style={{ color: LINK_COLOR, fontSize: icon_size }} /> an <b>external non-corpus</b> link that may be followed (i.e.. by clicking on it). </li>
	</ul>
	</li>
	<li> A link that has a question mark in its interior cannot be followed.
								 </li>
	<ul>
	<li><NotListedLocationOutlinedIcon style={{ color: DISABLED, fontSize: icon_size }} /> an <b>external non-corpus</b> link that cannot be followed because we have not curated
             a URL for  it. </li>
	<li> <NotListedLocationSharpIcon style={{ color: ERROR_COLOR, fontSize: icon_size }} />  the color <span style={{ color: ERROR_COLOR }}> red</span>, is used to 
         indicate that the semantic browser was not provided with enough entity information to resolve a target.</li>
	</ul>

	</ul>
	<p>
	To summarize: A <span style={{ color: ERROR_COLOR }}>red</span> or <span  style={{ color: DISABLED }}> grey</span> link that 
        has a question mark in its interior cannot be followed.
        A link that refers to a document in our corpus is colored  <span style={{ color: ECOLOR }}> purple, </span>
	while an external link that is not in our corpus is colored <span  style={{ color: LINK_COLOR }}> green </span> if we have a URL for it, 
        (otherwise it will be  <span  style={{ color: DISABLED }}> grey</span>).
	Every link is equipped with a tooltip, which currently shows a printable form of the location entity data.
	To see the actual entity data, the user can use the debug button on the entity itself.
																			       </p>

	<h2>Incoming References </h2>

	Figures, tables, annexes, and sections all may be refered to by location entities within the corpus. Thus we annotate figures, tables, annexes, and sections by the <ArrowCircleDownIcon style={icon_style} /> buuton, which, when pressed, with navigate to a page containing a list of all 
	sentences in the corpus that refer to the annotated object.

							 <h2>Widgets</h2>

	<p>
	When a document is loaded, there are an assortment of tools or widgets
	at the user's disposal (depending, of course, upon the settings). Hopefully
these are all rather simple to use, and do not require lengthy instructions.
</p>

<ul>
   <li> <b>Index of Entities</b>: Because a document usually contains
   a large number of entities, and some of those entities are of
   marginal interest, we only index those entities whose role type has
   been selected by the user. This is done by clicking on those roles
   that are of interest. </li>
   <li> <b>List of Entities</b>: List of sections and the number of
   entities that occur in them </li>
   <li> <b>Concrete Syntax Trees</b>: Section by section index of
   CSTs </li>
   <li> <b>Issue Summary</b>: Two choices: Structural Issue Summary
   (various types of issue categories), and Per Node Type Issue
   Summary </li>
   <li> <b>List of Issues</b>: List of sections and their issues </li>
</ul>


<p>
When one of the first three modes is chosen, there will be an
additional drop down menu entitled "Actions", which will allow various
types of navigation, according to the mode.
</p>

<h2> Entity Query Browser</h2>

Clicking on the <QueryStatsIcon style={icon_style} /> icon will activate the Entity Query Browser.
This feature is currently under development, and so may change rapidly.
Currently it enables the user to search for entities within the entire corpus,
assuming the user specifies a prefix, and a selection of acceptable roles.
Note that location and number entities are both numerous and uninteresting
from this point of view, so we advise excluding them from searches.

Once the user specifies a prefix and a selection of roles, the search button becomes
enabled. Once pressed the database is queried, and a lists of results are returned.
Each entity satisfying the request is listed on a seperate line, that also indicates
its role. The entity name is linked to the Wiki page for that entity.
To reveal more information about the entity, one can click on the 
<FeedIcon style={icon_style} /> button, which will navigate the browser to the Wiki page for that entity.
The browser back button can be used to return
to the list of entities.

<h2> Raw Jena Query Browser</h2>

Clicking on the <QueryBuilderIcon style={icon_style} /> icon will activate the Raw Jena Query Browser,
a primitive way of evaluating RDF queries.


<h2>Debug Mode</h2>

In the debug mode the following things happen:
<ul>
<li> Things like tables, and figures are annotated with a debug button.
<ul>
<li>  <BugReportIcon style={icon_style} />  clicking on this button causes information to be printed
to the browser's javascript console (developer tools), and copied to the user's clipboard.
</li>
</ul>
</li>
<li> In the entity toolip we include both the document name and a debug button.
Clicking on that button will cause the document name, sentence uid, and <em>occurrence id</em>, as well as the
original sentence content to be printed to the browser console, where you can copy it out to a text file.
The occurrence id is of the form
<pre>
'doc id'_'section id'_'occurrence number'
</pre>
and can be used to search for the occurrence.
</li>
<li>
There is a debug search button <SearchIcon style={{ color: ECOLOR }} />
that allows you to search using the
above occurrence id. This way you can return to a previously reported
issue to see if it has been resolved. Or as a developer, go to the location on an ongoing issue.
</li>
</ul>



<h2>Reporting bugs</h2>

<p>
If you have access to SRI's GitHub private repositories, then please file an issue
	<a href="https://github.com/SRI-CSL/Effigy/issues"> here </a>
	making it clear that the issue pertains to the semantic browser.
							      </p>

	<p>
	If you don't then just send me an <a href="mailto:iam@csl.sri.com">email.</a>
	</p>


	</Container>
	</React.Fragment>
    );


}
