import React,  { Component }  from 'react';
import { connect } from "react-redux";
import Button from 'react-bootstrap/Button'
import MINIO_URL from '../javascript/network/constants'

import { openInNewTab } from '../javascript/utils'

const mapStateToProps = state => {
    return { document: state.portfolio.document };
}

class ConnectedDocumentMetaData extends Component {
    render() {
	const document = this.props.document
	if (!document) {
            return null;
	}
	const data = document.jsonData()
	const source = data['source_file'];
	return (
            <ul>
            <li> Version:  {data['version']} </li>
            <li> Kind:  {data['kind']} </li>
            <li> Date:  {data['date']} </li>
            <li> Original Word Document exported to HTML:
		 <Button variant="link" onClick={(e) => {
		     e.preventDefault();
		     openInNewTab(MINIO_URL + source);
		 }}>{source}
	    </Button>
	    </li>
            </ul>
	);
    }
}

const DocumentMetaData = connect(mapStateToProps)(ConnectedDocumentMetaData);

export default DocumentMetaData;
