import React  from 'react';

import { useNavigate } from 'react-router-dom'


import FeedIcon from '@mui/icons-material/Feed';
import IconButton from '@mui/material/IconButton';

import { ECOLOR } from '../../javascript/colors'

import { EToolip } from '../EToolip'

import { encodeEntity } from './WikiUtils'

export default function UserGuide(props) {
    const entity = props.entity
    const role = props.role
    let navigate = useNavigate();


    if (!entity || !role)  {
	return null
    } else {
	const handleChange = (event) => {
	    let url = `/wiki/${encodeEntity(entity)}/${role}`
	    console.log(`Navigating to ${url}`)
	    if (props.closing) { props.closing() }
	    return navigate(url)
	}

	return (
            <EToolip title="Entity Wiki">
	    <IconButton
            style={{ color: ECOLOR }}
            aria-label="Navigate to the entity's wiki page"
            component="span"
            onClick={handleChange}
            size="small">
	    <FeedIcon/>
	    </IconButton>
	    </EToolip>
	)
    }
}
