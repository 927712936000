import React from 'react';
import { connect } from "react-redux";

import { useNavigate } from 'react-router-dom'

import Link from '@mui/material/Link';
import { v4 as getUUID } from 'uuid';

import { EToolip } from '../EToolip'

import { setBusy, setDocument } from "../../actions/index";

import { ECOLOR, LINK_COLOR } from '../../javascript/colors' 

import { openInNewTab } from '../../javascript/utils'


export function isReference(document, str) {
    return str && (str.indexOf('[') >= 0) && (str.indexOf(']') > 0)
}

export function makeReference(document, str, guid) {
    const start = str.indexOf('[')
    const end = str.indexOf(']')
    if (start < end) {
	const prefix = str.substring(0, start)
	const suffix = str.substring(end + 1)
	const ref = str.substring(start + 1, end)
	// just a sanity check here
	const numeral = parseInt(ref)
	if (isNaN(numeral)){
	    console.error(`Unexpected reference ${ref} seen in Loc entity ${str} (sentence ${guid})`)
	    return str
	}
	const references = document.references()
	if (!references) {
	    console.error(`Unexpected lack of references`, document)
	    return str
	}
	const refNode = references.lookup(ref)
	if (!refNode) {
	    console.log(`Reference ${ref} within "${str}" not found (sentence ${guid})`)
	    return str
	}
	return 	[prefix, <ReferenceLink key={getUUID()} node={refNode}></ReferenceLink>, suffix]
    }
    return str
}


const mapStateToProps = state => {
    return {
	builder: state.network.builder,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setBusy: bool => dispatch(setBusy(bool)),
        setDocument: document => dispatch(setDocument(document)),
    };
}

function DisconnectedReference(props) {

    let navigate = useNavigate();

    const node = props.node

    let explanation = null

    if (node.is3GPP) {
	const latest = node.latest
	if (latest) {
	    const clickHandler = () => {
		return (e) => {
		    let url = `/document/${latest}`
		    console.log(`Navigating to ${url}`)
		    return navigate(url)
		}
	    }
	    explanation = "Navigate to corpus document"
	    return (
		<EToolip key={getUUID()} title={explanation}>
		<Link aria-label={explanation} key={getUUID()} component="button" size="small" color="primary" underline="hover"
		onClick={clickHandler()}>
		<span key={getUUID()} style={{ color: ECOLOR }}><b>[{props.node.reference_number}]</b></span>
		</Link>
		</EToolip>
	    )
	}
    }
    if (node.url) {
	const clickHandler = (e) => {
	    console.log(`Launching tab @ ${node.url}`)
	    openInNewTab(node.url)
	}
	explanation = "Launch new tab to non-corpus document"
	return (
	    <EToolip key={getUUID()} title={explanation}>
	    <Link  aria-label={explanation} key={getUUID()} component="button" size="small" color="primary" underline="hover"
	    onClick={clickHandler}>
	    <span key={getUUID()} style={{ color: LINK_COLOR }}><b>[{props.node.reference_number}]</b> </span>
	    </Link>
	    </EToolip>
	)
    }
    // default dead link
    return (
	<span key={getUUID()}> <b>[{props.node.reference_number}]</b> </span>
    )
    
}


const ReferenceLink = connect(mapStateToProps, mapDispatchToProps)(DisconnectedReference)

export default ReferenceLink;
