import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { grey } from '@mui/material/colors';

import IconButton from '@mui/material/IconButton';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EjectIcon from '@mui/icons-material/Eject';

import { ECOLOR } from '../../javascript/colors'
import { EToolip } from '../EToolip'

function Up(props) {
    const color = props.disabled ? grey[300] : ECOLOR
    return (
        <EToolip title="Up">
        <span>
        <IconButton
        style={{ color: color }}
        aria-label="up one level"
        onClick={props.handler}
        disabled={props.disabled}
        size="small">
        <ArrowUpwardIcon fontSize="small" />
        </IconButton>
        </span>
        </EToolip>
    );
}

function Top(props) {
    return (
        <EToolip title="Top">
        <IconButton
        style={{ color: ECOLOR }}
        aria-label="back to top"
        onClick={props.handler}
        disabled={props.disabled}
        size="small">
        <EjectIcon fontSize="small" />
        </IconButton>
        </EToolip>
    );
}

function Previous(props) {
    const color = props.disabled ? grey[300] : ECOLOR
    return (
        <EToolip title="Previous">
        <span>
        <IconButton
        style={{ color: color }}
        aria-label="previous node"
        onClick={props.handler}
        disabled={props.disabled}
        size="small">
        <ArrowBackIcon fontSize="small" />
        </IconButton>
        </span>
        </EToolip>
    );
}

function Forward(props) {
    const color = props.disabled ? grey[300] : ECOLOR
    return (
        <EToolip title="Next">
        <span>
        <IconButton
        style={{ color: color }}
        aria-label="next node"
        onClick={props.handler}
        disabled={props.disabled}
        size="small">
        <ArrowForwardIcon fontSize="small" />
        </IconButton>
        </span>
        </EToolip>
    );
}


export default function FragmentNavigation(props) {
    // no longer zoomOrScrolling
    const navigate = useNavigate();
    const document = useSelector(state => state.portfolio.document)
    const other = useSelector(state => state.portfolio.other)
    const fragment = useSelector(state => state.portfolio.fragment)
    if (document === null) {
	    return null
    }
    const parentage = document.parentage()
    const pnode = parentage.pnode(fragment.guid)

    if (pnode === null) {
	    //Annex mess (33512 empty Annex) (now fixed).
	    return null
    }

    const navigateTo = (node) => {
	    const url = document.node2LocalURL(node, other)
	    console.log(`Navigating to ${url}; other=${other ? other.nodeId : null}`)
	    return navigate(url)
    }

    const up = pnode.up()
    const previous = pnode.previous()
    const forward = pnode.forward()

    const goTop = () => { navigateTo(null); }
    const goPrevious = ( !previous ? null : () => {  navigateTo(previous); } )
    const goForward = ( !forward ? null : () => { navigateTo(forward); } )
    const goUp = () => { navigateTo(up.node_type === 'document_part' ? null : up); }

    return (
	    <>
	    <Previous handler={goPrevious} disabled={previous === null}></Previous>
	    <Up handler={goUp} disabled={up === null}></Up>
	    <Forward handler={goForward} disabled={forward === null}></Forward>
	    <Top handler={goTop} disabled={false}></Top>
	    </>
    );
}
