import DataFactory from '@rdfjs/data-model'
import { query } from './cached_sparql.js'
import { rdfStringToJsonString } from './utils.js'
import { EFFIGY_NS, RDF_NS, XSD_NS } from './constants.js'

export default class OneShotTreeBuilder {

    constructor(){
        this.defs = {}  // map from node id to Object
    }

    async load(graphIri, contentIri){
        return await this._runQuery(graphIri, contentIri)
    }

    async constructTree(docIri){
        let tree = this._reconstructTree(DataFactory.namedNode(docIri))
        return tree
    }

    async getTopNodeIRIs(graphIri){
        let queryStr = `
      SELECT ?top
      FROM <${graphIri}>
      WHERE {
        ?top a <${this.topClass}> .
      }
        `
        //console.log('query',queryStr)
        let results = await query(queryStr)
        return results.map(r => Object.values(r).map(v=>v.value)[0])
    }

    // --- Internals below ---

    // Returns an object of property: value pairs from a query result
    _parseSolution(bindings){
        //console.log('parsing solution',bindings)
        let subj = bindings.subj
        let prop = bindings.pred
        let val = bindings.obj
        let obj = this.defs[subj.value]
        if (!obj){
            obj = {}
            this.defs[subj.value] = obj
        }
        obj[prop.value] = val
    }

    _parsePropVals(queryResult){
        for (const bindings of queryResult){
            this._parseSolution(bindings)
        }
    }

    async _runQuery(graphIri, contentIri) {
        let result = []
        if (contentIri){
            result = await this._getContentForUidIri(contentIri, graphIri)
        }
        else {
            let queryString = `
        PREFIX ef: <${EFFIGY_NS}>
        SELECT ?subj ?pred ?obj
        FROM <${graphIri}>
        WHERE {
          ?pred a ef:YAMLProperty .
          ?subj ?pred ?obj .
        }
            `
            //console.log('Load query:\n',queryString)
            result = await query(queryString)
        }
        //console.log(`Got ${result.length} triples`)
        this._parsePropVals(result)
        //console.log('defs:\n',this.defs)
        return result
    }

    async _getContentForUidIri(uid, graph){
        let queryString = `
      PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
      PREFIX ef: <http://www.sri.com/effigy#>
      SELECT ?subj ?pred ?obj
      WHERE {
        GRAPH <${graph}> {
          <${uid}> (ef:children | rdf:rest | rdf:first)*/ef:value? ?obj .
          ?pred a ef:YAMLProperty .
          ?subj ?pred ?obj .
          <${uid}> (ef:children | rdf:rest | rdf:first)* ?subj .
        }
      }
        `
        //console.log(queryString)
        return await query(queryString)
    }

    _reconstructTree(val){
        //console.log('reconstructing ', val)
        if (!val){
            console.error('reconstructing empty value!')
            return undefined
        }
        else if (val.termType === 'BlankNode'){
            // We assume these are lists
            let obj = this.defs[val.value]
            if (obj){
                let first = obj[`${RDF_NS}first`]
                let firstVal = this._reconstructTree(first)
                let rest = obj[`${RDF_NS}rest`]
                let restVal = this._reconstructTree(rest)
                let list = [firstVal, ...restVal]
                return list
            }
            else {
                return val.value
            }
        }
        else if (val.termType === 'NamedNode'){
            if (val.value === `${RDF_NS}nil`){
                // Empty list
                return []
            }
            else {
                let def = this.defs[val.value]
                if (def){
                    let subTree = this._reconstructTree(def)
                    return {uid: rdfStringToJsonString(val.value), ...subTree}
                }
                else {
                    return rdfStringToJsonString(val.value)
                }
            }
        }
        else if (val.termType === 'Literal'){
            const valueStr = val.value
            const dataType = val.datatype.value
            if (this._isIntType(dataType)){
                return parseInt(valueStr)
            }
            else if (this._isFloatType(dataType)){
                return parseFloat(valueStr)
            }
            else if (this._isBooleanType(dataType)){
                return valueStr.toLowerCase() === 'true' || valueStr === '1'
            }
            else {
                return valueStr  // default to string
            }
        }
        else if (typeof(val) === 'object'){
            delete val[`${RDF_NS}type`]
            return Object.fromEntries(Object.entries(val).map(([k,v]) => {
                const newKey = rdfStringToJsonString(k)
                const newVal = this._reconstructTree(v)
                return [newKey, newVal]
            }))
        }
        else { // This should not happen
            return val.value
        }
    }

    intTypes = [
        XSD_NS + 'integer',
        XSD_NS + 'nonPositiveInteger',
        XSD_NS + 'negativeInteger',
        XSD_NS + 'long',
        XSD_NS + 'int',
        XSD_NS + 'short',
        XSD_NS + 'byte',
        XSD_NS + 'nonNegativeInteger',
        XSD_NS + 'unsignedLong',
        XSD_NS + 'unsignedInt',
        XSD_NS + 'unsignedShort',
        XSD_NS + 'unsignedByte',
        XSD_NS + 'positiveInteger'
    ]

    floatTypes = [
        XSD_NS + 'float',
        XSD_NS + 'double',
        XSD_NS + 'decimal'
    ]

    booleanTypes = [
        XSD_NS + 'boolean'
    ]

    _isIntType(datatype){
        return this.intTypes.includes(datatype)
    }

    _isFloatType(datatype){
        return this.floatTypes.includes(datatype)
    }

    _isBooleanType(datatype){
        return this.booleanTypes.includes(datatype)
    }

}
