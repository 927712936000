import { useSelector } from 'react-redux'

import { useParams } from "react-router-dom";

import { google_analytics_pageview } from '../../javascript/ga'

import  QueryBrowser from './QueryBrowser'

import * as QBUtils from './QBUtils'

export default function QBLoaderHook(props) {

    google_analytics_pageview()

    let params = useParams()

    let qbs = params.qbStateString || null

    const user = useSelector(state => state.user)
    
    const debug = QBUtils.monitor(user)

    if (debug) { console.log("QBLoaderHook", params, qbs) }

    return (
	<QueryBrowser qbs={qbs} user={user} />
    )

}
