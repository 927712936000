import React, { useState } from 'react';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom'

import withStyles from '@mui/styles/withStyles';
import TreeView from '@mui/lab/TreeView';

import CustomTreeItem from './CustomTreeItems'
import { Close, Open } from '../Icons'

import { v4 as getUUID } from 'uuid';

import { si_nt_name, nt_name} from '../Issue'

import { setISState } from "../../actions/index";

import { TILabel } from './TreeItems'

import { ECOLOR } from '../../javascript/colors'

const mapStateToProps = state => {
    return {
	    document: state.portfolio.document,
	    other: state.portfolio.other,
	    settings: state.user.settings,
	    is_state: state.portfolio.is_state,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setISState: nodeList => dispatch(setISState(nodeList)),
    };
}


const useStyles = withStyles({
    root: {
        height: 240,
        flexGrow: 1,
        maxWidth: 400,
    },
    label: {
	    backgroundColor: "green",
	    color: "red"
    },
});


function structuralIssueSummary(parentage, si_mapping, hndlr) {
    return Object.keys(si_mapping).map((k) => siTree(parentage, si_mapping, k, hndlr))
}

function siTree(parentage, si_mapping, k, hndlr) {
    const prefix = 'si_tree'
    const label = `${si_nt_name(k)}: ${si_mapping[k].length}`
    const uuid = `${prefix}_${si_nt_name(k)}`
    const inode_list = si_mapping[k]
    const children = inode_list.map((inode) => mkINodeTree(prefix, parentage, inode, hndlr))
    return  <CustomTreeItem key={uuid} nodeId={uuid} label={label}>{children}</CustomTreeItem>
}

function mkINodeTree(prefix, parentage, inode, hdlr) {
    const uuid = `${prefix}_${inode.uid}`
    const guid = inode.owner.guid
    const pnode = parentage.pnode(guid)
    const label = pnode.sectionLabel()
    const handler = hdlr(pnode.section)
    return  <CustomTreeItem key={uuid} nodeId={uuid} label={TILabel(label, handler)}></CustomTreeItem>
}

function perNodeIssueSummary(parentage, nt_mapping, hndlr) {
    return Object.keys(nt_mapping).map((k) => ntTree(parentage, nt_mapping, k, hndlr))
}

function ntTree(parentage, nt_mapping, k, hndlr) {
    const prefix = 'nt_tree'
    const label = `${nt_name(k)}: ${nt_mapping[k].length}`
    const uuid = `ni_key_${nt_name(k)}`
    const inode_list = nt_mapping[k]
    const children = inode_list.map((inode) => mkINodeTree(prefix, parentage, inode, hndlr))
    return  <CustomTreeItem key={uuid} nodeId={uuid} label={label}>{children}</CustomTreeItem>
}


function ConnectedIssueSummary(props) {

    // We have made some attempt to neutralize the selection weirdness.
    // Not really sure if we have succeeded, but ...
    const [selected, setSelected]  = useState(null)

    // no longer zoomOrScrolling
    let navigate = useNavigate();

    const other = props.other

    const makeHandler = (fragment) => {
	    return () => {
	        const url = props.document.node2LocalURL(fragment, other)
	        console.log(`Navigating to ${url}; other=${other ? other.nodeId : null}`)
	        return navigate(url)
	    }
    }

    const handleSelect = (event, nodeIds) => {
	    setSelected(null)
    }

    const handleToggle = (event, nodeIds) => {
	    props.setISState([...nodeIds])
    }

    const issues = props.settings.value('issues')
    const document = props.document
    if (!issues || !document ) { return null; }

    const parentage = document.parentage()

    const loi = document.listOfIssues()

    const si_count = loi.get_si_count()

    const classes = useStyles;

    const si_uuid = loi.si_uuid
    const si_label = `Structural Issue Summary:  ${si_count}`
    const si_mapping = loi.si_mapping()

    const si_children = structuralIssueSummary(parentage, si_mapping, makeHandler)

    const nt_uuid = loi.nt_uuid
    const nt_label = `Per Node Type Issue Summary: ${si_count}`
    const nt_mapping = loi.nt_mapping()

    const nt_children = perNodeIssueSummary(parentage, nt_mapping, makeHandler)

    //console.log('si_mapping', si_mapping)
    //console.log('nt_mapping', nt_mapping)

    return (
	    <TreeView key={getUUID()}
	    className={classes.root}
	    defaultCollapseIcon={<Close  sx={{color: ECOLOR}} />}
	    defaultExpandIcon={<Open  sx={{color: ECOLOR}} />}
	    expanded={props.is_state}
	    selected={selected}
	    onNodeToggle={handleToggle}
	    onNodeSelect={handleSelect}
	    >
	    <CustomTreeItem key={si_uuid} nodeId={si_uuid} label={si_label}>
	    {si_children}
	    </CustomTreeItem>
	    <CustomTreeItem key={nt_uuid} nodeId={nt_uuid} label={nt_label}>
	    {nt_children}
	    </CustomTreeItem>
	    </TreeView>
    );
}

const IssueSummary = connect(mapStateToProps, mapDispatchToProps)(ConnectedIssueSummary)

export default IssueSummary
