import React from 'react';
import { connect } from "react-redux";


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import makeStyles from '@mui/styles/makeStyles';

import Collapse from '@mui/material/Collapse';

import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';

import { EToolip, toolTipDelay } from '../EToolip'

import { setBusy, setDocument } from "../../actions/index";

import { ECOLOR } from '../../javascript/colors'

import CatalogueEntry from './CatalogueEntry'

function mapDispatchToProps(dispatch) {
    return {
        setBusy: bool => dispatch(setBusy(bool)),
        setDocument: document => dispatch(setDocument(document)),
    };
}

const mapStateToProps = state => {
    return {
	cache: state.network.cache,
	builder: state.network.builder,
	document: state.portfolio.document,
	other: state.portfolio.other,
	settings: state.user.settings,
    };
}


const useStyles = makeStyles((theme) => ({
    root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
      paddingLeft: theme.spacing(4),
  },
}));

function DisconnectedCatalogueCollection(props) {
    const classes = useStyles();

    // handles the opening up of the multiple releases sublist
    const [open, setOpen] = React.useState(false)

    const handleClick = () => {
	setOpen(!open);
    }

    const data = props.data
    const uid = data.uid
    const tooltip = data.title
    const releases = data.releases
    const label = `${data.shortname} (${releases.length} versions)`

    const children = releases.map((release, index) => (<CatalogueEntry key={uid + 'CE' + index} nested={classes.nested} data={data} release={release} closing={props.closing}/>))

    return (
	<>
	<ListItem button key={uid} onClick={handleClick}>
	<EToolip title={'Multiple versions'} enterDelay={1000} leaveDelay={0}>
        <ListItemIcon style={{ color: ECOLOR }}>
        <CollectionsBookmarkIcon />
        </ListItemIcon>
	</EToolip>
	<EToolip title={tooltip} enterDelay={toolTipDelay} leaveDelay={0}><ListItemText primary={label}/></EToolip>
        {open ? <ExpandLess /> : <ExpandMore />}
	</ListItem>
	<Collapse in={open} timeout="auto" unmountOnExit>
        <List dense={true} component="div" disablePadding>
	{ children }
        </List>
	</Collapse>
	</>
    );
}

export const CatalogueCollection = connect(mapStateToProps, mapDispatchToProps)(DisconnectedCatalogueCollection);

export default CatalogueCollection;
