import React  from 'react';

import { useNavigate } from 'react-router-dom'

import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import IconButton from '@mui/material/IconButton';

import { ECOLOR } from '../../javascript/colors'
import { EToolip } from '../EToolip'


export default function MentionsButton (props) {


    const doc = props.doc
    const id = props.id
    const node = props.node

    let navigate = useNavigate();

    const handleClick = (event) => {
	let url = `/mentions/${doc}/${node}/${id}`
	console.log(`Navigating to ${url}`)
	return navigate(url)
    }

    return (
        <EToolip title="References to this section">
	<IconButton
        style={{ color: ECOLOR }}
        aria-label={'See incoming references to this section'}
        component="span"
        onClick={handleClick}
        size="small">
	<ArrowCircleDownIcon />
	</IconButton>
	</EToolip>
    );



}
