import React, { useState }  from 'react';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom'

import withStyles from '@mui/styles/withStyles';
import TreeView from '@mui/lab/TreeView';

import CustomTreeItem from './CustomTreeItems'
import { Close, Open } from '../Icons'

import { v4 as getUUID } from 'uuid';

import { setCSTState } from "../../actions/index";

import { TILabel } from './TreeItems'

import { ECOLOR } from '../../javascript/colors'

const mapStateToProps = state => {
    return {
	    document: state.portfolio.document,
	    other: state.portfolio.other,
	    cst_state: state.portfolio.cst_state,
	    settings: state.user.settings,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setCSTState: nodeList => dispatch(setCSTState(nodeList)),
    }
}

const useStyles = withStyles({
    root: {
        height: 240,
        flexGrow: 1,
        maxWidth: 400,
    },
    label: {
	    backgroundColor: "green",
	    color: "red"
    },
})

function treeifyOccurrence(pnode, s, index, handler) {
    const uuid = `section_${s}_cst_child_${index}`
    return (
	    <CustomTreeItem key={uuid} nodeId={uuid} label={TILabel(`Occurrence ${index} in enclosing ${pnode.clan}`, handler(pnode.clan_data))}>
	    </CustomTreeItem>
    );
}

function treeifyCSTsOfSection(document, s, indexOfCSTs, parentage, handler) {
    const occurrences = Array.from(indexOfCSTs.get(s))
    const pnodes = occurrences.map((guid) => parentage.pnode(guid))
    const cuuid = `section_${s}_csts`
    const pnode = pnodes[0]
    if (pnodes.length > 1) {
	    const children = pnodes.map((pnode, index) => treeifyOccurrence(pnode, s, index, handler))
	    return (
	        <CustomTreeItem key={cuuid} nodeId={cuuid} label={TILabel(`Section ${s} with ${pnodes.length} occurrences`, handler(pnode.section))}>
	        {children}
	        </CustomTreeItem>
	    );
    } else {
	    const uuid = `section_${s}_cst_only_child`
	    return (
	        <CustomTreeItem key={cuuid} nodeId={cuuid} label={TILabel(`Section ${pnode.section.id} with 1 occurrence`, handler(pnode.section))}>
	        <CustomTreeItem key={uuid} nodeId={uuid} label={TILabel(`Enclosing ${pnode.parent.node_type}`, handler(pnode.parent))}></CustomTreeItem>
	        </CustomTreeItem>
	    );
    }
}

function ConnectedConcreteSyntaxTrees(props) {

    // We have made some attempt to neutralize the selection weirdness.
    // Not really sure if we have succeeded, but ...
    const [selected, setSelected]  = useState(null)

    // no longer zoomOrScrolling
    let navigate = useNavigate();

    const other = props.other

    const makeClickHandler = (fragment) => {
	    return () => {
	        //console.log('fragment: ', fragment)
	        const url = props.document.node2LocalURL(fragment, other)
	        console.log(`Navigating to ${url}; other=${other ? other.nodeId : null}`)
	        return navigate(url)
	    }
    }

    const handleSelect = (event, nodeIds) => {
	    setSelected(null)
    }

    const handleToggle = (event, nodeIds) => {
	    props.setCSTState([...nodeIds])
    }


    const cst = props.settings.value('cst')
    const document = props.document
    if (!cst || !document ) {
	    return null
    }
    const hasCsts = document.hasConcreteSyntaxTrees()
    if (!hasCsts) {
	    return (
	        <em>Sorry there are no CSTs here yet. </em>
	    );
    }
    const indexOfCSTs = document.indexOfCSTs()
    const sections = Array.from(indexOfCSTs.keys())
    sections.sort()
    const parentage = document.parentage()
    return (
	    <TreeView key={getUUID()}
	    className={useStyles.root}
	    defaultCollapseIcon={<Close sx={{color: ECOLOR}} />}
	    defaultExpandIcon={<Open sx={{color: ECOLOR}} />}
	    expanded={props.cst_state}
	    selected={selected}
	    onNodeToggle={handleToggle}
	    onNodeSelect={handleSelect}
	    >
	    {sections.map((s) => treeifyCSTsOfSection(document, s, indexOfCSTs, parentage, makeClickHandler))}
	    </TreeView>
    );
}

const ConcreteSyntaxTrees = connect(mapStateToProps, mapDispatchToProps)(ConnectedConcreteSyntaxTrees)

export default ConcreteSyntaxTrees
