import { v4 as getUUID } from 'uuid';



function makeAnnexRoot() {
    // in compare mode we pay close attention to the special case of the Annexes
    const uid = getUUID()
    return { uid: uid, 
	     node_type: "section", 
	     id: "Annexes", 
	     name: "Annexes", 
	     subsections: [], 
	     direct_children: [] }
}


// we hack the json coming down the pipe so that it looks 
// more like what the SB want to display.
// 1. we create a document_part to contain all the annexes (non-main document_parts)
// 2. the non-root document parts get demoted to sections
export function hackJSON(json) {
    if (!json || !json.node_type || json.node_type !== 'document') {
	console.error("Bad input to hackJSON: ", json)
	return json
    }
    if (!json.document_parts) {
	console.error("Bad json.document_parts to hackJSON: ", json)
	return json
    }
    const nparts = json.document_parts.length
    if (nparts <= 1) {
	return json
    }
    const retval = {...json}
    // make the new document_part to house the annexes
    const annexesRoot = makeAnnexRoot()
    // preserve the main
    const main = json.document_parts[0]
    retval.document_parts = [ main ]
    // move the annexes into the new document_part
    const annexes = json.document_parts.slice(1)
    annexesRoot.subsections = annexes
    // demote them to sections
    annexes.forEach((a) => { a.node_type = "section" })
    // add the new document_part
    main.subsections.push(annexesRoot)
    // return the result
    return retval
}


