import React from 'react';
import { connect } from "react-redux";

import { CHANGED_COLOR } from '../../javascript/colors'

import { nodeIdentifier, unchanged, innerHtml } from '../../javascript/utils'

import { processEntities } from './Entity'

import { makeCST } from './CST'


const mapSettingsToProps = state => {
    return {
        fragment: state.portfolio.fragment,
        settings: state.user.settings,
    };
}

// represents a json node of node_type "sentence"
// props.data will be the json for that node
class ConnectedSentence extends React.Component {
    render() {
        const document = this.props.document;
        const data = this.props.data;
        const other = this.props.other;
        let content = null
        const identifier = nodeIdentifier(data)

        let cst = null

        const debug = this.props.settings && this.props.settings.value('debug')

        // performance mitigation: only ever render things like entities or CSTs in fragments.
        if (this.props.fragment) {

            // checks via document.hasConcreteSyntaxTrees()
            cst = this.props.settings.value('cst') ? makeCST(document, data) : null


            if (this.props.settings.value('entities') && document.hasEntities()) {
                const entityMap = document.getEntityMap()
                const eInfo = entityMap.get(data.uid)
		//console.log('eInfo', eInfo, 'data.uid', data.uid);
                if (eInfo) {
                    const annotations = eInfo.annotations()
                    //console.log('eInfo.raw', eInfo.raw);
                    if (annotations && annotations.length > 0) {
                        content = processEntities(document, data, eInfo, annotations, debug)
                    }
                }
            }


        }
        if (!content) {
            content = innerHtml(data.content)
        }
        if (unchanged(data, other)) {
            return ( <span id={identifier}> {cst} {content} </span> );
        } else {
            return ( <span style={{ backgroundColor: CHANGED_COLOR }} id={identifier}> {cst} {content} </span> );
        }
    }
}

const Sentence = connect(mapSettingsToProps)(ConnectedSentence);

export default Sentence
