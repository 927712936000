import React from 'react';
import { connect } from "react-redux";

import { useNavigate } from 'react-router-dom'

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import BookIcon from '@mui/icons-material/Book';

import { purple, green, lime } from '@mui/material/colors';

import { EToolip, toolTipDelay } from '../EToolip'

import { setBusy, setDocument } from "../../actions/index";

import { nodeVersion } from '../../javascript/utils'

import { ECOLOR } from '../../javascript/colors'

import IconButton from '@mui/material/IconButton';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';


function mapDispatchToProps(dispatch) {
    return {
        setBusy: bool => dispatch(setBusy(bool)),
        setDocument: document => dispatch(setDocument(document)),
    };
}

const mapStateToProps = state => {
    return {
	cache: state.network.cache,
	document: state.portfolio.document,
	other: state.portfolio.other,
	settings: state.user.settings,
    };
}

function DisconnectedCatalogueEntry(props) {

    let navigate = useNavigate();

    const mkHandler = (dname) => {
	return (e) => {
	    let url = `/document/${dname}`
	    console.log(`Navigating to ${url}`)
	    if (props.closing) { props.closing() }
	    return navigate(url)
	}
    }

    const mkReloader = (dname) => {
	return (e) => {
	    let url = `/document/${dname}?reload=true`
	    console.log(`Navigating to ${url}`)
	    if (props.closing) { props.closing() }
	    return navigate(url)
	}
    }


    const iconColor = (docId) => {
	    if (props.document && props.document.nodeId === docId) {
	        return green.A700
	    } else if (props.other && props.other.nodeId === docId) {
	        return lime.A700
	    }
	    return props.cache.contains(docId) ? purple.A100 : ECOLOR
    }

    const iconTooltip = (docId) => {
	    if (props.document && props.document.nodeId === docId) {
	        return 'Current Document'
	    } else if (props.other && props.other.nodeId === docId) {
	        return 'Comparison Document'
	    }
	    return props.cache.contains(docId) ? 'Cached locally' : 'Availiable serverside'
    }

    const data = props.data
    const uid = data.uid
    const tooltip = data.title
    const label = data.shortname

    const release = props.release

    const version = nodeVersion(release)

    const isCached = props.cache.contains(release)
    const color = iconColor(release)
    const itip = iconTooltip(release)

    const nclass = props.nested ? props.nested : null

    if (isCached) {
	const reloader = <EToolip title={'Reload'}>
                            <IconButton aria-label="Redownload the document" size="small" onClick={mkReloader(release)}>
                             <CloudDownloadIcon fontSize="inherit" sx={{ color: color }}/>
	                    </IconButton>
                         </EToolip>
	return (
	    <ListItem className={nclass} key={uid}
	    secondaryAction={reloader}
	    disablePadding
	    >
	    <ListItemButton onClick={mkHandler(release)}>
	    <EToolip title={itip} enterDelay={toolTipDelay} leaveDelay={0}>
	    <ListItemIcon style={{ color: color }}> <BookIcon />  </ListItemIcon>
	    </EToolip>
	    <EToolip title={tooltip} enterDelay={toolTipDelay} leaveDelay={0}><ListItemText primary={label} secondary={version}/></EToolip>
	    </ListItemButton>
	    </ListItem>
	);
    } else {
	return (
	    <ListItem button className={nclass} key={uid} onClick={mkHandler(release)}>
	    <EToolip title={itip} enterDelay={toolTipDelay} leaveDelay={0}>
	    <ListItemIcon style={{ color: color }}> <BookIcon />  </ListItemIcon>
	    </EToolip>
	    <EToolip title={tooltip} enterDelay={toolTipDelay} leaveDelay={0}><ListItemText primary={label} secondary={version}/></EToolip>
	    </ListItem>
	);
    }
}

export const CatalogueEntry = connect(mapStateToProps, mapDispatchToProps)(DisconnectedCatalogueEntry);

export default CatalogueEntry;
