import * as NS from '../../javascript/namespaces'

export const RDF_NS = NS.RDF
export const EFFIGY_NS = NS.EFFIGY
export const EFFIGY_ONT_GRAPH = NS.EFFIGY
export const SRC5G_NS = NS.SRC5G
export const XSD_NS = NS.XSD

export const AUTH_URL='http://localhost:9070'

// How often to refresh the auth token
export const TOKEN_REFRESH_MIN = 5

const minioBucket = 'html'
let minIO_host = ''

if (process.env.NODE_ENV === 'production') {
    // In production mode, we use nginx's reverse proxy.
    // The real server URL is configured in docker/default.conf
    minIO_host = '/minio'
} else {
    // In development mode, we use an environment var to
    // configure the server URL (in the .env file).
    // This can be overridden with a .env.local file.
    minIO_host = process.env.REACT_APP_MINIO_URL
}


export const MINIO_URL = minIO_host + '/' + minioBucket + '/'

export default MINIO_URL
