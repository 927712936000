// the references

const ThreeGTwoPDocument = new RegExp(/\s*3GPP\s*T[R,S]\s*(\d\d\.\d\d\d)\s*/)

export function is3GPP(str) {
    return ThreeGTwoPDocument.test(str)
}

// returns the non-dotted form, i.e. 22261
export function get3GPPId(str) {
    const series = get3GPPSeries(str)
    if (series) {
	return series[1].replace('.', '')
    }
    return null
}

// returns an array of the series and the dotted form. i.e. ['22', '22.261']
export function get3GPPSeries(str) {
    if (ThreeGTwoPDocument.test(str)) {
	const dotty = ThreeGTwoPDocument.exec(str)[1]
	const dot = dotty.indexOf('.')
	return [dotty.substr(0, dot), dotty]
    }
    return null
}

function getETSIRange(dotty) {
    const dotless = dotty.replaceAll('.', '')
    let num = parseInt(dotless)
    num = 100000 + Math.floor(num/100) * 100
    return `${num}_${num + 99}`
}

function getETSINum(dotty) {
    const dotless = dotty.replaceAll('.', '')
    let num = parseInt(dotless)
    num = 100000 + num
    return num.toString()
}


function getETSIURL(dotty) {
    return `https://www.etsi.org/deliver/etsi_ts/${getETSIRange(dotty)}/${getETSINum(dotty)}/`
}

export function get3GPP_URL(series, full) {
    return `https://www.3gpp.org/ftp/Specs/archive/${series}_series/${full}/`
}

const IETF_RFC = new RegExp(/RFC\s+(\d+)\s*/)


export function isRFC(str) {
    return IETF_RFC.test(str)
}

export function getRFC(str) {
    if (IETF_RFC.test(str)) {
	return IETF_RFC.exec(str)[1]
    }
    return null
}

export function getRFC_URL(digits) {
    return `https://datatracker.ietf.org/doc/search?name=${digits}&sort=&rfcs=on&activedrafts=on&by=group&group=`
}

const BBF_TR = new RegExp(/BBF\s+TR-(\d+)\s*/)

export function isBBF(str) {
    return BBF_TR.test(str)
}

export function getBBF(str) {
    if (BBF_TR.test(str)) {
	return BBF_TR.exec(str)[1]
    }
    return null
}

export function getBBF_URL(digits) {
    return `https://www.broadband-forum.org/resources?=undefined&search_keyword=TR-${digits}&order_by=number&order_sort=ASC`
}

class RefNode {

    constructor(data, section, references) {
	this.data = data 
	this.section = section
	this.uid = data['uid']
	this.reference_number = parseInt(data['reference_number'])
	this.referenced_doc_id = data['referenced_doc_id']

	// not very elegant but extensible

	this.is3GPP = is3GPP(this.referenced_doc_id)
	this.id3GPP = this.is3GPP ? get3GPPId(this.referenced_doc_id) : null
	this.series3GPP = this.is3GPP ? get3GPPSeries(this.referenced_doc_id) : null
	this.etsiRange = this.is3GPP ? getETSIRange(this.series3GPP[1]) : null

	this.isRFC = isRFC(this.referenced_doc_id)
	this.idRFC = this.isRFC ? getRFC(this.referenced_doc_id) : null

	this.isBBF = isBBF(this.referenced_doc_id) 
	this.idBBF = this.isBBF ? getBBF(this.referenced_doc_id) : null

	this.url = this.getURL()

	const title = data['referenced_doc_title']
	this.referenced_doc_title = title ? title : ""


	this.latest = this.id3GPP ? references.latestInCorpus(this.id3GPP) : null

    }


    isInCorpus() {
	return this.latest !== null
    }

    getURL() {
	if (this.isRFC) {
	    return getRFC_URL(this.idRFC)
	    // `https://datatracker.ietf.org/doc/search?name=${this.idRFC}&sort=&rfcs=on&activedrafts=on&by=group&group=`
	} else if (this.is3GPP) {
	    const series = this.series3GPP
	    //return  get3GPP_URL(series[0], series[1])
	    // clt prefers:
	    return getETSIURL(series[1]) 
	    //`https://www.3gpp.org/ftp/Specs/archive/${series[0]}_series/${series[1]}/`
	} else if (this.isBBF) {
	    return getBBF_URL(this.idBBF)
	    //`https://www.broadband-forum.org/resources?=undefined&search_keyword=TR-${this.idBBF}&order_by=number&order_sort=ASC`
	}
	return null
    }

    toString() {
	if (this.referenced_doc_title) {
	    return `[${this.reference_number}] : "${this.referenced_doc_title}" a.k.a ${this.referenced_doc_id}` 
	} else {
	    return `[${this.reference_number}] : ${this.referenced_doc_id}` 
	}
    }

    shortname() {
	return this.id3GPP
    }

}

export class References {

    constructor(doc) {
	this.doc = doc
	this.library  = this.doc.builder.getLibrary()
	this.referenceMap = {}
	const data = doc.data
	this.initialize(data, data)
    }

    add(x, s) {
        this.referenceMap[x.reference_number] = new RefNode(x, s, this)
    }

    lookup(str) {
	const val = this.referenceMap[str]
	if(val) {
	    return val
	}
	return null
    }

    latestInCorpus(shortname) {
	const entries =  shortname ? this.library.entries(shortname) : null
	const releases = entries ? entries.releases : null
	const latest = releases ? entries.releases[releases.length - 1] : null
	return latest
    }

    initialize(x, s) {
        if (!x) {
            return;
        }
        const nt = x['node_type'];
        switch(nt) {
            case 'document': x['document_parts'].forEach((d) => this.initialize(d, d)); return;
            case 'document_part':  {
		x['subsections'].forEach((d) => this.initialize(d, d)); 
		x['direct_children'].forEach((d) => this.initialize(d, x));
		return;
	    }
            case 'section': {
		x['subsections'].forEach((d) => this.initialize(d, d));
		x['direct_children'].forEach((d) => this.initialize(d, x));
		return
	    }
	    case 'figure': {
		x['caption'].forEach((d) => this.initialize(d, s));
		return
	    }
	    case 'list': {
		x['list_items'].forEach((d) => this.initialize(d, s));
		return
	    }
	    case 'paragraph':
	    case 'list_item':
	    case 'abbreviation_entry':
	    case 'defined_term':
	    case 'indented':
	    case 'glossary_entry':
	    case 'note': {
		x['direct_children'].forEach((d) => this.initialize(d, s));
		return
	    }
	    case 'definition_placeholder':
	    case 'sentence': {
		return
	    }
	    case 'reference': this.add(x, s)
		return
	    case 'table':
	    case 'code':
		return
	    default:
		console.error(`References: Don't forget about poor wee ${nt}`)
		return;
        }
    }



}
