import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom'


import Card from 'react-bootstrap/Card'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import IconButton from '@mui/material/IconButton';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';

import { v4 as getUUID } from 'uuid';

import { innerHtml, rdfId2jsonId } from '../../javascript/utils'

import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import WikiLink from './WikiLink'
import NavigationBar from '../qb/NavigationBar'
import * as QBUtils from '../qb/QBUtils'
import { ECOLOR } from '../../javascript/colors'
import Occurrences from './Occurrences'


export default function  WikiEntry (props) {

    const user = props.user

    const debug = QBUtils.monitor(user)	

    if (debug) { console.log('WikiEntry.render', props) }

    const entity = props.entity
    
    const role = props.role

    const data = props.data

    const style = QBUtils.makeStyle(user)

    const height =  style.dimensions.height - 20

    const header = `The Wiki Page for the entity "${entity}" with role ${role}.`

    //<Card.Header> <span style={{ color: ECOLOR }}><h4>{header}</h4></span> </Card.Header>

    return (
	<Fragment>
	<NavigationBar header={header} style={style} />
        <Card border="info" style={{ maxHeight: height, ...style}}>
        <Card.Body>
	<Preamble entity={entity} role={role} data={data}/>
	<Abbreviations entity={entity} role={role} data={data}/>
	<Occurrences entity={entity} role={role} data={data} height={height}/>
        </Card.Body>
        </Card>
	</Fragment>
    )


}


function WikiEntryAbbreviationItem (props) {

    let navigate = useNavigate();


    const abbr = props.abbr
    //console.log('abbr', abbr)
    const index = props.index
    const doc_title = abbr.doc_title.value
    const doc_version = abbr.doc_version.value
    const doc_id = rdfId2jsonId(abbr.doc_id.value)
    const doc = `${doc_title}  version ${doc_version}  a.k.a ${doc_id}`
    const text = innerHtml(abbr.def.value)
    const uid = abbr.def_id.value

    const handleClick = () => {
	let url = `/document/${doc_id}/hash/${uid}`
	console.log(`Navigating to ${url}`)
	return navigate(url)
    }

    //const json = JSON.stringify(abbr)
    //console.log(json, abbr)


     return (
    	<ListItem key={'ali' + index}
        secondaryAction={
            <IconButton style={{ color: ECOLOR }} edge="end" aria-label="navigate to the document" onClick={handleClick}>
            <AssistantDirectionIcon />
            </IconButton>
	}
	>
	<ListItemText key={'alit' + index} primary={text} secondary={doc}/>
	</ListItem>
    )

}



function Abbreviations (props) {
    const entity = props.entity
    const abbrs = props.data.queryThree
    if (!abbrs || abbrs.length === 0) {
	return null
    }
    return (
	<Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        aria-controls="abbreviations-content"
        id="abbreviations-header"
        >
        <Typography style={{ color: ECOLOR }}>{innerHtml(`Abbreviation Entries (${abbrs.length}) for the entity ${entity} in the corpus`)}</Typography>
        </AccordionSummary>
	<AccordionDetails>
	<List key={'al'} dense={true}>
	{abbrs.map((a, i) => <WikiEntryAbbreviationItem key={'weai' + i} abbr={a} index={i} />)}
	</List>
	</AccordionDetails>
	</Accordion>
    )
}


function abbrs2String(entity, abbrs) {
    const count = abbrs.length
    let text = ` The entity "${entity}" `
    if (count > 0) {
	const abbr = abbrs[0].def.value
	text += ` abbreviates "${abbr}", and the abbreviation is defined in ${count} documents (see below for more details).`
	//text += JSON.stringify(abbr)

    } else {
	text += ' is not an abbreviation, or at least doesn\'t not appear as such in the corpus.'
    }
    return (
	text
    )
}


function glossarys2String(entity, glossarys) {
    const count = glossarys.length
    let text = ` The entity "${entity}" `
    if (count > 0) {
	const gentry = glossarys[0].def.value
	text += ` has the following glossary entry: ${gentry}`
	//text += JSON.stringify(gentry)
    } else {
	text += ' does not have a glossary entry in the corpus.'
    }
    return (
	text
    )
}


function reverseAbbrs2String(entity, reverseAbbrs) {
    const count = reverseAbbrs.length 
    let text = null
    if (count > 0) {
	const revAbbr = reverseAbbrs[0].abbr.value
	text = ` The entity "${entity}" is abrreviated by the entity: ${revAbbr}, so more information can be found `
    }
    return (
	text
    )
}

function sentences2String(entity, sentences) {
    const count = sentences.length
    let text = ` The entity "${entity}" occurs in ${count} sentences in the corpus." `
    return (
	text
    )
}

function reverseAbbrs2Link (entity, role, reverseAbbrs) {
    const count = reverseAbbrs.length
    if (count > 0) {
	const revAbbr = reverseAbbrs[0].abbr.value
	return (
	    <WikiLink entity={revAbbr} role={role} text={`here`} />
	)
    } else {
	return null
    }
}

function Preamble (props) {
    const entity = props.entity
    const role = props.role
    const sentences = props.data.queryOne
    const glossarys = props.data.queryTwo
    const abbrs = props.data.queryThree
    const reverseAbbrs = props.data.queryFour

    const atext = abbrs2String(entity, abbrs)

    const gtext = glossarys2String(entity, glossarys)

    const rtext = reverseAbbrs2String(entity, reverseAbbrs)

    const stext = sentences2String(entity, sentences)
    
    const uuid = getUUID()

    return (
	<List key={uuid + 'preamble'} dense={true}>
	{atext ? <ListItem key={uuid + 'atext'}>{innerHtml(atext)}</ListItem> : null}
	{gtext ? <ListItem key={uuid + 'gtext'}>{innerHtml(gtext)}</ListItem> : null}
	{rtext ? <ListItem key={uuid + 'rtext'}><div> {innerHtml(rtext)} {reverseAbbrs2Link(entity, role, reverseAbbrs)} </div></ListItem> : null}
	<ListItem key={uuid + 'stext'}>{innerHtml(stext)}</ListItem>
	</List>
    )




}
