import React, { Fragment, useState }  from 'react';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom'

import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';


import { ECOLOR } from '../../javascript/colors'

import { EToolip } from '../EToolip'

import { isGUID, parseGUID } from '../../javascript/guid'

const mapStateToProps = state => {
    return {
        document: state.portfolio.document,
	settings: state.user.settings,
    };
}

const styling = {
    color: ECOLOR,
}

/*
   33102-g00_6.3.5_849


   29509-h10_4.1_186
   29509-h10 d8f3d71d207f26f50124460a 29509-h10_4.1_186: In this release, the SEAF function is collocated with the AMF.
 */
function ConnectedSearchButton(props) {

    const [open, setOpen]  = useState(false)
    const [guid, setGuid]  = useState(null)
    const [error, setError]  = useState(null)

    // no longer zoomOrScrolling
    let navigate = useNavigate();

    const handleClickOpen = () => {
	setOpen(true)
    }
    
    const handleClose = () => {
	setOpen(false)
    }
    
    const handleChange = ( event ) => {
	let candidate = event.target.value
	console.log(candidate)
	if (isGUID(candidate)) {
	    setGuid(event.target.value)
	    setError(false)
	} else {
	    setError(true)
	}
    }
    
    const handleSearch = () => {
	console.log('Searching for: ', guid)
	if (isGUID(guid)) {
	    let segments = parseGUID(guid)
	    let url = `/document/${segments[0]}/fragment/${guid}`
	    console.log(`Navigating to ${url}`)
            setOpen(false)
	    return navigate(url)
	} else {
	    setError(true)
	}
    }

    if (!props.settings ) {
	return null
    }

    const debug = props.settings.value('debug')
    if (!debug) {
	return null
    }

    const textfield = !error ?
     <TextField
        margin="dense"
        id="name"
        label="Sentence Id"
        type="search"
        fullWidth
        variant="standard"
        helperText="<doc id>_<section>_<occurence>"
        onChange={handleChange}
        />
        :
        <TextField
        error
        margin="dense"
        id="name"
        label="Sentence Id"
        type="search"
        fullWidth
        variant="standard"
        helperText="Can't find that sentence, sorry."
        onChange={handleChange}
        />

    return (
	    <Fragment>
	    <EToolip title="Debug Search">
	    <IconButton
            style={{ color: ECOLOR }}
            aria-label="search"
            component="span"
            size="large"
	    onClick={handleClickOpen}
	    >
	    <SearchIcon />
	    </IconButton>
	    </EToolip>
	    <Dialog sx={styling} open={open} onClose={handleClose}>
            <DialogContent>
            {textfield}
            </DialogContent>
            <DialogActions>
            <Button onClick={handleSearch}>Search</Button>
            </DialogActions>
	    </Dialog>
	    </Fragment>
	);
}

const SearchButton = connect(mapStateToProps, null)(ConnectedSearchButton);

export default SearchButton
