import React from 'react';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom'

import { v4 as getUUID } from 'uuid';


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import { red } from '@mui/material/colors';

import { unchanged } from '../../javascript/utils'
import { ECOLOR } from '../../javascript/colors'

const mapStateToProps = state => {
    return {
	    document: state.portfolio.document,
	    other: state.portfolio.other,
	    settings: state.user.settings,
    };
}

function enode2ListItem(enode, toc, handler, other){
    const tocnode = toc.mapping[enode.id]
    if (!tocnode) {
	    // FIXME: this is a problem with Annexes that have content but no subsection. a logical mistake by me.]
	    console.log('enode with no tocnode', enode)
	    return null
    }

    const label = `${enode.id}  with ${enode.count} annotated sentence${enode.count > 1 ? 's' : ''}`
    const fragment = tocnode.data
    const uid = getUUID();
    const fixed = unchanged(fragment, other)
    const redc = red[600]
    const textColor = { color: fixed ? "black" : redc }
    const icolor = fixed ? ECOLOR : redc
    return (
	    <ListItem button key={uid} onClick={handler(fragment)}>
	    <ListItemIcon style={{ color: icolor }}> <BubbleChartIcon />  </ListItemIcon>
	    <ListItemText primaryTypographyProps={{ style: textColor }}  primary={label}/>
	    </ListItem>
    );
}


function ConnectedListOfEntityAnnotations(props){

    // no longer zoomOrScrolling
    let navigate = useNavigate()


    const other = props.other

    const makeClickHandler = (fragment) => {
	    return () => {
	        const url = props.document.node2LocalURL(fragment, other)
	        console.log(`Navigating to ${url}; other=${other ? other.nodeId : null}`)
	        return navigate(url)
	    }
    }

    const document = props.document
    if (!document) {
        return null
    }
    const toc = document.tableOfContents()
    const loe = document.listOfEntityAnnotations()

    if (!loe.hasEntities()) {
	    return (
	        <em>Sorry no entities here yet. </em>
	    );
    }

    return (
        <List dense={true} key={getUUID()}>
	    {loe.list.map((enode) => enode2ListItem(enode, toc, makeClickHandler, other))}
	    </List>
    );
}

const ListOfEntityAnnotations = connect(mapStateToProps, null)(ConnectedListOfEntityAnnotations);

export default ListOfEntityAnnotations;
