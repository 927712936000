import React  from 'react';
import { connect } from "react-redux";

import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbum';
import IconButton from '@mui/material/IconButton';


import { ECOLOR } from '../../javascript/colors'
import { EToolip } from '../EToolip'
import { setControl } from "../../actions/index";
import { LOF_CONTROL } from '../../constants/controls'

function mapDispatchToProps(dispatch) {
    return {
	setControl: control => dispatch(setControl(control)),
    };
}

const mapStateToProps = state => {
    return {
	control: state.portfolio.control,
	document: state.portfolio.document,
    }
}


class ConnectedLOFButton extends React.Component {

    constructor (props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick = (event) => {
	const  off = this.props.control === LOF_CONTROL
	this.props.setControl(off ? null :  LOF_CONTROL );
    }

    render() {
        const document = this.props.document;
        if (!document || !document.hasFigures()) {
            return null;
        }
	const  off = this.props.control === LOF_CONTROL
	return (
            <EToolip title="List of Figures">
	    <IconButton
            style={{ color: ECOLOR }}
            aria-label={`${off ? "close" : "open"} the document's list of figures`}
            component="span"
            onClick={this.handleClick}
            size="large">
	    <PhotoAlbumIcon />
	    </IconButton>
	    </EToolip>
    );
    }
    
}

const LOFButton = connect(mapStateToProps, mapDispatchToProps)(ConnectedLOFButton);

export default LOFButton;


