import React,  { Component } from 'react';
import { connect } from "react-redux";

import CSTDialog from './CSTDialog'

import { makePresentable } from '../../javascript/utils'

const mapStateToProps = state => {
    return { 
	settings: state.user.settings,
    };
}



//33501-h10_clean_A.2_8206
class ConnectedCST extends Component {

    render() {
	const cst = this.props.settings.value('cst')
	const trees = this.props.trees
	const content = this.props.content
	if (!cst || !trees) {
	    return null;
	}
	return (
	    <CSTDialog trees={trees.map((t) => makePresentable(t))} content={content}/>
	);
    }
}

export function makeCST(document, data) {
    const hasCsts = document.hasConcreteSyntaxTrees()
    if (hasCsts) {
	const trees = document.getConcreteSyntaxTrees(data.uid)
	if (trees && trees.length > 0) {
	    return <CST trees={trees} content={data.content} />
	}
    }
    return null
}


const CST = connect(mapStateToProps)(ConnectedCST);

export default CST
