// https://stackoverflow.com/questions/19266197/reactjs-convert-html-string-to-jsx
// could also try interweave is I get curious, or fail to fix the other rogue stuff in the json.
import ReactHtmlParser from 'react-html-parser';


export function TILabel(label, handler) {
    return (
	<span onClick={handler}>{ReactHtmlParser(label)}</span>
    )
}
