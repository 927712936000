// the star of the show (currently) this is what we refer to as "the document"
import  { TOC } from './TOC'
import  { LOF } from './LOF'
import  { LOI } from './LOI'
import  { LOE } from './LOE'
import  { References } from './Refs'
import  { Parentage} from './Parentage'
import  { Blobs } from './Blobs'
import EntityInfo from './entity'
import { hackJSON } from './Hack'
import stream from './Stream'

import EntityTreeBuilder from './network/entity_tree_builder.js'

import CSTTreeBuilder from './network/cst_tree_builder'

// keep track of the load times, gotta keep these down!
// though the below seems to indicate that the multiple passes
// are not really an issue. so for now I will go with simplicity.
/*
   times are in milliseconds

   loadTime of '21905-h00': 74
   loadTime of '29503-g60': 79
   loadTime of '29503-g70': 73
   loadTime of '29503-h00': 74
   loadTime of '29503-h10': 70
   loadTime of '29503-h20': 69
   loadTime of '33102-g00': 43
   loadTime of '33220-h00': 59
   loadTime of '33402-g00': 29
   loadTime of '33501-g40': 122
   loadTime of '33501-g50': 122
   loadTime of '33501-g60_clean': 123
   loadTime of '33501-h00': 134
   loadTime of '33501-h10_clean': 139

 */
const loadTimes = {}

export function dumpLoadTimes() {
    const keys = Object.keys(loadTimes).sort()
    keys.forEach((k) => console.log(`loadTime of ${k}: ${loadTimes[k]}`))
}

const DEFAULT_DEBUG = false

export class Doc {

    constructor(builder, nodeId, settings=null) {
	// debugging
	this.debug = settings === null ? DEFAULT_DEBUG : settings.value('debug')
	console.log('Doc debug: ', this.debug)

	// root uuid of the document.
	this.nodeId = nodeId
	console.log('nodeId: ', nodeId)
	// server side info of document (need this for entities and csts).
	this.serverSideId = builder.getCatalogueEntry(nodeId)

	if (this.debug) {
	    console.log('Doc nodeId: ', this.nodeId)
	    console.log('Doc serverSideInfo: ', this.serverSideId)
	}

	// the builder
	this.builder = builder
	// indicates that we should try and load entities for this document
	this.entities = settings ? settings.value('entities') : false
	// indicates if we got any
	this._hasEntities = false
	// indicates that we should  try and load csts for this document
	this.csts = settings ? settings.value('cst') : false
	// indicates if we got any
	this._hasCSTs = false
	// the raw json destined to be rendered as a dom object
	this.data = null
	// a somewhat flattened version of it for infinite scolling
	this.stream = null;

	// table of contents data structure
	this.toc = null
	// list of figures  data structure
	this.lof = null
	// list of issues  data structure
	this.loi = null
	// geneology
	this.parents = null;
	// maps uids (i.e. hashes) to the object so hashed.
	this.hMap = null
	// maps sentence uids to the array of csts for that sentence
	this.cstMap = new Map()
	// maps section ids to the set of guids of sentences with csts in that section
	this.indxOfCSTs = new Map()
	// maps the ids of sentences to the list of entity annotations (qua EntityInfo object) for that sentence.
	this.entityMap = new Map()
	this.loe = null
	this.blobs = null
    }

    async load() {
	if (this.builder) {
	    console.log(`loading document: ${this.nodeId} (entities: ${this.entities}, csts: ${this.csts})`)
	    let rawJSON = await this.builder.constructJSON(this.nodeId, this.debug)
	    if (this.debug) {
	        console.log('Got raw json: ', rawJSON)
	    }
	    const start = performance.now()

	    // the json dom object; modified to look easier to digest visually
	    this.data =  hackJSON(rawJSON)
	    // the flattened version
	    this.stream = stream(this.data)

	    // the parentage of every node (backward pointers)
	    // this adds a guid to each node (i.e. an occurrence)
	    this.parents = new Parentage(this.data, this.nodeId)

	    // the table of contents
	    this.toc = new TOC(this.data)

	    //the list of figures
	    this.lof = new LOF(this.data)

	    //the list of issues
	    this.loi = new LOI(this.data)

	    this._references = new References(this)

	    // a map from uids (i.e. hashes) to the object so hashed.
	    this.hMap = this.parents.hashMap() //this.builder.getFilteredMap(this.nodeId)

	    // under construction
	    this._hasCSTs = await this.loadCSTs()

	    const wstart = performance.now()
	    this.blobs = new Blobs(this.data)
	    const blob_count = await this.blobs.wait4Blobs()
	    const wend = performance.now()
	    const wloadTime = wend - wstart
	    console.log(`load time of ${blob_count} blobs: ${wloadTime}ms`)

	    // maps sentences to the entity annotations
	    this._hasEntities = await this.loadEntities(this.debug)

	    // maps section ids to the number of (occurrences of) sentences with entity annotations
	    // plus is contains an index of enitites
	    this.loe = new LOE(this.data, this.entityMap)

	    const end = performance.now()
	    const loadTime = end - start
	    loadTimes[this.nodeId] = loadTime
	    console.log(`load time of document ${this.nodeId}: ${loadTime}ms`)
	}
    }

    async loadEntities(debug) {
	const start = performance.now()
	let success = false
	let count = 0
	if (this.entities) {
	    let docInfo = this.serverSideId
            let entityGraph = docInfo.entGraph
            if (entityGraph) {
	        let entityTreeBuilder = new EntityTreeBuilder()
		// load all the entities for this document
		try {
		    await entityTreeBuilder.load(entityGraph)
		} catch(err){
		    console.error('Error loading entities: ', err.message)
		}
		//process the  entity nodes for this document
		try {
		    console.log(`Processing entity nodes for ${this.nodeId}`)
		    const topEntityIds = await entityTreeBuilder.getTopNodeIRIs(entityGraph)
		    console.log(`Number of Entities: ${topEntityIds.length}`)
		    console.log(`Extracting annotations from the entities for ${this.nodeId}`)
		    for (const entityId of topEntityIds){
			//console.log(`\nProcessing entity ${entityId}`)
                        let entityData = await entityTreeBuilder.constructTree(entityId)
			//console.log('entityData', entityData)
                        let sent_id = entityData.sentence_id
			if (sent_id) {
			    const eInfo = new EntityInfo(this, sent_id, entityData, debug)
			    this.entityMap.set(sent_id, eInfo)
			    // got at least one!
			    if (!success) { success = true }
			    count++
			}
		    }
		} catch(err){
		    console.error('Error processing entities: ', err.message)
		}
            } else {
                console.log("No entity graph found")
            }
	    const end = performance.now()
	    const loadTime = end - start
	    console.log(`load time of Entities: ${loadTime}ms (success = ${success}, entity count = ${count})`)
	}
	return success
    }

    add2CstMap(sent_id, tree) {
	if (this.cstMap.has(sent_id)) {
	    const trees = this.cstMap.get(sent_id)
	    trees.push(tree)
	} else {
	    this.cstMap.set(sent_id, [tree])
	}
    }

    add2IndexOfCSTs(section, guid) {
	let guids = null
	if (this.indxOfCSTs.has(section)) {
	    guids = this.indxOfCSTs.get(section)
	} else {
	    guids = new Set()
	    this.indxOfCSTs.set(section, guids)
	}
	guids.add(guid)
    }

    async loadCSTs() {
	let count = 0
	if (this.csts) {
	    const start = performance.now()
	    let error_count = 0
	    let docInfo = this.serverSideId
            let cstGraph = docInfo.cstGraph
            if (!cstGraph) {
                console.log("No CST graph found")
                return
            }
	    let cstTreeBuilder = new CSTTreeBuilder()
	    // load all the entities for this document
	    try {
		await cstTreeBuilder.load(cstGraph)
		const syntaxTrees = await cstTreeBuilder.getTopNodeIRIs(cstGraph)
                console.log(`Number of CSTs: ${syntaxTrees.length}`)
		for (const cstId of syntaxTrees) {
		    const tree = await cstTreeBuilder.constructTree(cstId)
		    const sent_id = tree.sentence_id
		    if (!sent_id) {
			console.log("No sentence id",  tree)
			continue
		    }
		    count++;
		    this.add2CstMap(sent_id, tree)
		    if (tree.error) {
			error_count++
		    }
		    // indxOfCSTs
		    const occurrences = this.parents.occurrences(sent_id)
		    if (occurrences.length) {
			occurrences.forEach((guid) => {
			    const pnode = this.parents.pnode(guid)
			    const section = pnode.section.id
			    this.add2IndexOfCSTs(section, guid)
			})
		    }
		}
	    } catch(err){
		console.error('Error loading CSTs: ', err.message)
	    }
	    const end = performance.now()
	    const loadTime = end - start
	    console.log(`Number of CSTs: ${count}  (with errors: ${error_count})`)

	    console.log(`load time of CSTs: ${loadTime}ms`)
	}
	return count > 0
    }

    root() {
	return this.nodeId
    }

    jsonData() {
	return this.data
    }

    streamedData() {
	return this.stream
    }

    tableOfContents() {
	return this.toc
    }

    listOfFigures() {
	return this.lof
    }

    hasFigures() {
	return this.lof.hasFigures()
    }

    // Indicates it was loaded with the 'entity' settings true, and we actually got some
    hasEntities() {
	return this._hasEntities
    }

    listOfIssues() {
	return this.loi
    }

    listOfEntityAnnotations() {
	return this.loe
    }

    hasTables() {
	return this.blobs.hasTables
    }

    listOfTables() {
	return this.blobs
    }

    parentage() {
	return this.parents
    }

    references() {
	return this._references
    }

    issuesOfNode(node) {
	const inode_list = this.loi.index[node.uid]
	if (!inode_list) {
	    return null
	} else {
	    return inode_list
	}
    }

    indexOfCSTs() {
	return this.indxOfCSTs
    }

    getConcreteSyntaxTrees(sent_id) {
	if (this.cstMap.has(sent_id)) {
	    return this.cstMap.get(sent_id)
	}
	return null
    }

    hasConcreteSyntaxTrees() {
	return this._hasCSTs
    }

    getEntityMap() {
	return this.entityMap
    }

    indexOfEntities() {
	return this.loe.index
    }

    hashMap() {
	return this.hMap
    }

    // used in comparison mode (see unchanged in ./utils)
    unchanged(node) {
	return this.hMap.has(node.uid)
    }
    
    sameContentHash(data) {
	const nt = data.node_type
	if (['figure', 'table', 'code'].includes(nt)) {
	    switch (nt) {
		case 'figure': {
		    const lofNode = this.lof.index[data.id]
		    if (!lofNode) {
			return false
		    } else {
			return data.content_hash === lofNode.data.content_hash
		    }
		}
		case 'table':
		case 'code': {
		    if (!data.id) {
			return true // code doesn't have ids so it is hard to compare them across versions
		    }
		    const blob = this.blobs.getBlobById(data.id)
		    if (!blob) {
			//console.log(`'${data.id}'`, 'no corresponding blob')
			return false
		    } else {
			//console.log(`'${data.id}'`, data.content_hash, blob.content_hash())
			return data.content_hash === blob.content_hash()
		    }
		}
		default: return true
	    }
	}
	return true
    }

    getBlobContent(name) {
	return this.blobs.getBlobContent(name)
    }

    getBlobByGuid(guid) {
	return this.blobs.getBlobByGuid(guid)
    }

    getBlobById(id) {
	return this.blobs.getBlobById(id)
    }

    // entity ids and json ids don't match for tables and figures, so we
    // have to futz around with them. Here we try to do it uniformaly
    // rather than ad hoc through out the code base.
    resolveIdFromFragment(fragment) {
	if (fragment.node_type === 'figure') {
	    let id = fragment.id
	    const regex = /Figure /i
	    const retval = id.replace(regex, '')
	    //console.log('resolveIdFromFragment', id, retval)
	    return retval
	} else if (fragment.node_type === 'table') {
	    let id = fragment.id
	    const regex = /Table /i
	    const retval = id.replace(regex, '')
	    //console.log('resolveIdFromFragment', id, retval)
	    return retval
	} else {
	    return fragment.id
	}
    }

    /**
     *  id is the thing that comes from entity analysis, and is not always the same as
     *  the thing stored in the JSON.
     *  kind is the either a node_type, currently either 'annex', 'section', 'table', or 'figure'
     *  or else indicates what the id is: currently either 'hash' or 'fragment' indicating
     *  that it is a rdf/json hash or else our style guid
     *  returns the fragment, or null if it can't find one
     *  used in Location.js and Loader.js
     */
    resolveFragmentFromId(id, kind) {
	switch (kind) {
	    case 'annex': {
		if (id && id.indexOf('.') < 0) {
		    id = `Annex ${id}`
		}
		const toc = this.tableOfContents();
		const tocNode = toc.node4Section(id)
		return tocNode ? tocNode.data : null
	    }
	    case 'section': {
		const toc = this.tableOfContents();
		const tocNode = toc.node4Section(id)
		return tocNode ? tocNode.data : null
	    }
	    case 'table': {
		const lot = this.listOfTables()
		const table_id = 'Table ' + id
		const blob = lot.getBlobById(table_id)
		return blob ? blob.data : null
	    }
	    case 'figure': {
		const lof = this.listOfFigures()
		const figure_id = 'Figure ' + id
		const lofNode = lof.node4Figure(figure_id)
		return lofNode ? lofNode.data : null
	    }
	    case 'fragment': {
		const pnode = this.parents.pnode(id)
		//console.log(`guid = ${id}: `, pnode.data)
		return pnode.data
	    }
	    case 'sentence': {
		// in this case the id is the json uid and may occur more than once.
		// we just go to the first occurrence
		const sent_id = id
		const occurrences = this.parents.occurrences(sent_id)
		if (occurrences.length > 0) {
		    const guid = occurrences[0]
		    const pnode = this.parents.pnode(guid)
		    return pnode.data
		} else {
		    console.error(`resolveFragmentFromId: ${id} does not occur`)  
		    return null
		}
	    }
	    case 'hash': {
		return this.parents.hMap.get(id)
	    }
	    default:
		console.error(`resolveFragmentFromId: ${id} with unknown kind of fragment ${kind}`)
		return null
	}
    }

    /**
     * For navigating via the router and loader.
     * Used pervasively.
     */
    node2LocalURL(node, other) {
        const docId = this.root()
        const otherId = other ? other.nodeId : null
	if (!node) {
	    return otherId ? `/compare/${docId}/${otherId}` : `/document/${docId}`
	}
	const ntype = node.node_type
	switch (ntype) {
	        // things that have ids
	case 'annex':
	    return otherId ?
                `/compare/${docId}/${otherId}/annex/${node.id}` :
                `/document/${docId}/annex/${node.id}`
	case 'figure':
	    const fid = this.resolveIdFromFragment(node)
	    return otherId ?
                `/compare/${docId}/${otherId}/figure/${fid}` :
                `/document/${docId}/figure/${fid}`
	case 'table':
	    const tid = this.resolveIdFromFragment(node)
	    return otherId ?
                `/compare/${docId}/${otherId}/table/${tid}` :
                `/document/${docId}/table/${tid}`
	case 'section':
	    return otherId ?
                `/compare/${docId}/${otherId}/section/${node.id}` :
                `/document/${docId}/section/${node.id}`
	    // things that only have guids
	case 'list':
	case 'paragraph':
	case 'note':
	case 'abbreviation_entry':
	case 'reference':
	case 'glossary_entry':
	    return otherId ?
                `/compare/${docId}/${otherId}/fragment/${node.guid}` :
                `/document/${docId}/fragment/${node.guid}`
	default:
	    if (node.id) {
		return otherId ?
                    `/compare/${docId}/${otherId}/section/${node.id}` :
                    `/document/${docId}/section/${node.id}`
	    } else {
		console.error("node2LocalURL couldn't figure out the url for: ", node)
		return `/document/${docId}`
	    }
	}
    }

}


export default Doc
