

// stream a JSON node into an Array that can be incrementally rendered in by an "infinite scrollwer"
export default function stream(node) {

    if (!node) {
	return null
    }

    const nt = node['node_type'];
    switch(nt) {
    case 'document': {
	const main = node['document_parts'][0]
	return main['direct_children'].concat(main['subsections'])
    }
    default:
        console.error(`BUG! stream: We only stream top level documents: ${nt}`)
        return null
    }
}

 
