import { query } from '../../javascript/network/cached_sparql'


function makeDocumentPartsQuery (doc) {
    return `
PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
PREFIX src: <http://www.sri.com/effigy/source#>
PREFIX ef: <http://www.sri.com/effigy#>
SELECT DISTINCT ?id ?uid ?name ?hasChild WHERE {
  GRAPH <http://www.sri.com/effigy/3gpp/${doc}/graph> {
    ?node a src:Document_part ;
            src:id ?id ;
            src:uid ?uid ;
            src:name ?name .
  OPTIONAL {
      FILTER EXISTS {
        ?node ef:children/rdf:rest*/rdf:first ?child .
        ?child a src:Section .
                  }
      BIND(true AS ?hasChild)
   }
  }
} ORDER BY ASC(?id)
`
}



export async function fetchDocumentParts (doc) {
    const q = makeDocumentPartsQuery(doc)
    const start = performance.now()
    let data = []
    try {
	data = await query(q)
    } catch (err) {
	console.log(`Caught an error: ${err.toString()}`)
    }
    const end = performance.now()
    const duration = end - start
    console.log(`Got ${data.length} results after ${duration}  ms`, data)
    return data
}


function makeChildrenQuery(docId, nid) {
    return `
PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
PREFIX src: <http://www.sri.com/effigy/source#>
PREFIX ef: <http://www.sri.com/effigy#>
SELECT DISTINCT ?id ?uid ?name ?hasChild WHERE {
  GRAPH <http://www.sri.com/effigy/3gpp/${docId}/graph> {
    ?jid src:id "${nid}" .
    ?jid ef:children/rdf:rest*/rdf:first ?node .
    ?node a src:Section ;
            src:id ?id ;
            src:uid ?uid ;
            src:name ?name ;
  OPTIONAL {
      FILTER EXISTS {
        ?node ef:children/rdf:rest*/rdf:first ?child .
        ?child a src:Section .
                  }
      BIND(true AS ?hasChild)
   }
  }
}
    `
}


async function fetchChildren(docId, nid) {
    const q = makeChildrenQuery(docId, nid)
    const start = performance.now()
    let data = []
    try {
	data = await query(q)
    } catch (err) {
	console.log(`Caught an error: ${err.toString()}`)
    }
    const end = performance.now()
    const duration = end - start
    console.log(`Got ${data.length} results after ${duration}  ms`, data)
    return data
}


export async function expandMap(docId, nodeIds, map) {
    const nMap = new Map(map)
    for (let i = 0; i < nodeIds.length; i++) {
	const nid = nodeIds[i]
	const current = map.get(nid)
	if (current && current.children === null) {
	    const data = await fetchChildren(docId, nid)
	    const children = data.map((elem) => elem.id.value)
	    nMap.set(nid, { id: nid, uid: current.uid, name: current.name, children: children })
	    data.forEach((elem) => nMap.set(elem.id.value, {id: elem.id.value, uid: elem.uid.value, name: elem.name.value, children: elem.hasChild ? null : []}))
	}
    }
    return nMap
}


export function nodesToFetchChildren(proxima, ahora, map) {
    const p = new Set(proxima) 
    const o = new Set(ahora)
    const n = new Set([...p].filter(x => !o.has(x) && map.get(x).children === null))
    return [...n]
}

export function makeInitialMap(documentParts) {
    const iMap = new Map()
    documentParts.forEach((elem) => iMap.set(elem.id.value, {id: elem.id.value, uid: elem.uid.value, name: elem.name.value, children: elem.hasChild ? null : []}))
    const annexes = documentParts.map((node) => node.id.value)
    const main = annexes.pop()
    if (main !== 'main') {
	console.error(`main not the last document part: ${main}`)
    }
    //console.log('annexes', annexes)
    iMap.set('annexes', {id: 'Annexes', uid: '0', name: 'Annexes', children: annexes})
    return iMap
}


