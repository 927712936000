import React from 'react';

import { v4 as getUUID } from 'uuid';

import { makePresentable } from './utils'


import Location from './Location'

// https://github.com/SRI-CSL/Effigy/blob/6e8ae10af2c969205209c286a565f73050bb2e18/source-documents/schemas/entities-schema.json

// a light weight wrapper around the sunflower entity data that comes down the pipe.
// used to be just annotations, but now is a mess of stuff
export default class EntityInfo {


    constructor(doc, sentence_id, data, debug) {
	this.doc = doc
	this.sentence_id = sentence_id
	this.raw = data
	this.debug = debug
	this._annotations = null
    }


    annotations() {
	if (!this._annotations) {
	    this._annotations = this.compute_annotations()
	}
	return this._annotations
    }


    compute_annotations() {
	if (this.raw) {
	    let extracts = this.raw.extracts
	    if (extracts) {
		return extracts.map((ee) => {
		    let indices = ee.span_idxes
		    let role = this.role(ee.entity_type)
		    let legible = makePresentable(ee)

		    if (role === 'Loc') {
			const location = new Location(this.doc, this.sentence_id, legible, this.debug)
			/* for iam
			   if (this.debug) {
			   if (location.isInCorpus() && location.isSpecialized()) {
			   console.log('CORPUS', location.toString(), location)
			   }
			   }
			 */
			return [role, indices[0], indices[1], location]  //as of 02/23/2022 we tack on the location at position [3]
		    } else
			return [role, indices[0], indices[1], legible]   //as of 02/23/2022 we tack on the actual raw entity at position [3]
		})
	    }
	}
	return null
    }


    role(rawrole) {
	let path = rawrole.split('/')
	return path[path.length - 1]
    }

}


// some routines for helping with the entity mess.
// splits a string into and array of substrings, with the tags being isolated elements
// of the array
// E.g. parseTags('<i><b>hey</b></i>') = ['<i>', '<b>', 'hey', '</b>', '</i>']
export function parseTags(str) {
    if (!str) {
	return []
    }
    const ts = str.indexOf('<')
    const es = str.indexOf('&')

    if (ts === -1 && es === -1) {
	return [str]
    }
    if (ts === -1) {
	return [ str.substring(0, es) ].concat(parseString_html_entity(str.substring(es)))
    }
    if (es === -1) {
	return [ str.substring(0, ts) ].concat(parseString_html_tag(str.substring(ts)))

    }
    if (es < ts) {
	return [ str.substring(0, es) ].concat(parseString_html_entity(str.substring(es)))
    } else {
	return [ str.substring(0, ts) ].concat(parseString_html_tag(str.substring(ts)))
    }
}

// expects the string to begin with a '<' (i.e. an html tag)
function parseString_html_tag(str) {
    const te = str.indexOf('>')
    if (te === -1) {
	return [ str ]
    }
    const tag = str.substring(0, te + 1)
    return [ tag ].concat(parseTags(str.substring(te + 1)))
}

// expects the string to begin with a '&' (i.e. an html entity)
function parseString_html_entity(str) {
    const ee = str.indexOf(';')
    if (ee === -1) {
	return [ str ]
    }
    const entity = str.substring(0, ee + 1)
    return [ htmlEntityToString(entity) ].concat(parseTags(str.substring(ee + 1)))
}

function htmlEntityToString(str) {
    switch(str) {
	case '&gt;': return '>'
	case '&lt;': return '<'
	case '&amp;': return '&'
	case '&quot;': return '"'
	default: return str
    }
}

const htmlTag = new RegExp(/<[/]?\w+>/)

function startTag(arr) {
    if (!arr) {
	return -1
    }
    for (let i = 0; i < arr.length; i++){
	if (htmlTag.test(arr[i])) {
	    return i
	}
    }
    return -1
}

/*
   BUG: In  33501-h00 we have the sentence:
   {
   "node_type": "sentence",
   "content": "The access type distinguisher shall be set to the value for non-3GPP (0x02) when deriving K<sub>N3IWF, </sub>K<sub>WAGF, </sub>K<sub>TWIF</sub> or K<sub>TNGF.</sub>.  ",
   "uid": "ffbabe8b3527858e108eea21"
   }
   and this does not parse out properly

   entity non-3GPP
   entity 0x02
   entity K
   entity N3IWF
   entity K
   entity WAGF
   entity </sub>K<sub>TWIF</sub>
   entity K
   entity TNGF

 */

// have to deal with nesting for tags like with <sup> and <sub>
function endTag(tag, arr, fromIndex = 0) {
    if (!arr  || !tag) {
	return -1
    }
    const stag = `<${tag}>`
    const etag = `</${tag}>`
    let nesting = 0
    for (let i = fromIndex; i < arr.length; i++){
	if (stag === arr[i]) {
	    // console.log('Incrementing', tag, 'in', arr, 'after', fromIndex)
	    nesting++
	}
	if (etag === arr[i]) {
	    if (nesting === 0) {
		return i
	    } else {
		nesting--
	    }
	}
    }
    return -1
}



// we assume that the array has been detagified.
// this is our own homegrown version of ReactHtmlParser for
// processing random html tags that are in a sentence that has
// entity annotations
export function domify(arr) {
    if (!arr || arr.length === 0) {
	return []
    }
    const sindex = startTag(arr)
    // nothing to do
    if (sindex === -1) {
	return arr
    }
    const stag = arr[sindex]
    const tag = stag.substring(1, stag.length - 1) // ditch the '<' and '>'
    const prefix = arr.slice(0, sindex)
    const eindex = endTag(tag, arr, sindex + 1)
    // some kind of funky mess
    if (eindex === -1) {
	return arr
    }
    const children = arr.slice(sindex + 1, eindex)
    const current = React.createElement(tag, {key: getUUID()}, domify(children))
    const suffix = arr.slice(eindex + 1)
    return prefix.concat(current).concat(domify(suffix))
}
