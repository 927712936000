import IconButton from '@mui/material/IconButton';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';

import { ECOLOR } from '../javascript/colors'


export default function CloseMe(props) {

    return (
        <IconButton
	autoFocus
        aria-label="close this pop up"
        onClick={props.handleClose}
        size="small">
        <CancelPresentationIcon fontSize="small" sx={{ color: ECOLOR }} />
        </IconButton>
    )



}
