import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';

import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { ECOLOR } from '../javascript/colors'
import Card from 'react-bootstrap/Card'

import DocumentView from './DocumentView'

import { EToolip } from './EToolip'

import Navigation from './Navigation'

/* this is a work in progress; it should not be the star of the show like this */

//import { setCatalogue } from "../actions/index";
//import { useDispatch } from 'react-redux'
//import { useSelector } from 'react-redux'

import Catalogue from './catalog/Catalogue'

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
  },
    content: {
	flexGrow: 1,
	marginLeft: -drawerWidth,
    },
    contentShift: {
    marginLeft: -drawerWidth,
  },
}));


/*
   iam: 9/14/21  there are two ways to handle the state of the drawer, locally or in the central
   redux store. When the document is large (33501) the response time in opening or closing the
   button is very noticable when using the central store. The infrastructure is in place to do either,
   but for the time being we stick with the local version until I figure out how to get around this.
*/


export default function Sidebar(props) {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    //const dispatch = useDispatch()

    const handleDrawerOpen = () => {
	//dispatch(setCatalogue(true))
	setOpen(true)
    }

    const handleDrawerClose = () => {
	//dispatch(setCatalogue(false))
	setOpen(false)
    }

    //const open = useSelector(state => state.user.catalogue)


    const catalogue = (
	<EToolip title="Document Catalog">
        <IconButton
          style={{ color: ECOLOR }}
          aria-label={`${open ? 'close' : 'open'} the document catalogue`}
          onClick={open ? handleDrawerClose : handleDrawerOpen}
          size="large">
        <LocalLibraryIcon />
        </IconButton>
	</EToolip>
    )

    const closer = (
        <IconButton onClick={handleDrawerClose} size="large">
        <ChevronLeftIcon />
        </IconButton>
    )

    const footer = (
	<Card className="text-center">
          <Card.Footer width={300}  style={{ color: ECOLOR }}>&copy; SRI International 2022
          </Card.Footer>
        </Card>
    )

    return (
	<div className={classes.root}>
	<CssBaseline />
	<AppBar  position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: open })}>
	<Navigation busy={props.busy} controls={props.controls} catalogue={catalogue} />
	</AppBar>
	<div>

	<DocumentView />

	{props.footer ? footer  : null	}
	</div>


	<Drawer className={classes.drawer} variant="persistent" anchor="left" open={open} classes={{paper: classes.drawerPaper,}}>
        <div className={classes.drawerHeader}>
	{closer}
        </div>
        <Divider />

	<Catalogue closing={handleDrawerClose}/>

	</Drawer>

	<main  className={clsx(classes.content, {[classes.contentShift]: open})} >
	<div className={classes.drawerHeader} />
        </main>

      </div>
  );
}
