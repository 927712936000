import { Component } from 'react';

import * as QBUtils from '../qb/QBUtils'
import * as WikiUtils from './WikiUtils'

import WikiEntry from './WikiEntry'
import Busy from '../qb/Busy'


export default class Wiki extends Component {

    constructor(props) {
	super(props)
	this.debug = QBUtils.monitor(props.user)	
	this.state = { data: null, busy: true }
    }

    async componentDidMount() {
	if (this.debug) { console.log(">Wiki.componentDidMount") }
	
	if(this.props.entity) {
	    console.log(`Entity: ${this.props.entity} Role: ${this.props.role}`)
	    let data = await WikiUtils.fetchData(this.props.entity, this.props.role)
	    if (this.debug) console.log("data", data)
	    this.setState( { data, busy: false } )
	}
	if (this.debug) { console.log("<Wiki.componentDidMount") }
     }


    async componentDidUpdate(prevProps) {
	if (this.debug) { console.log(">Wiki.componentDidUpdate") }
	if (prevProps.entity !== this.props.entity  || prevProps.role !== this.props.role) {
	     if (this.debug) console.log(`Entity: ${this.props.entity} Role: ${this.props.role}`)
	    if (this.props.entity && this.props.role) {
		let data = await WikiUtils.fetchData(this.props.entity, this.props.role)
		 if (this.debug) console.log("data", data)
		this.setState( { data, busy: false } )
	    } else {
		 if (this.debug) console.log("Entity or Role missing")
	    }
	}
	if (this.debug) { console.log("<Wiki.componentDidUpdate") }
    }


    render() {
	if (this.debug) { console.log('Wiki.render', this.state) }
	if (this.state.busy) {
	    return ( <Busy/> )
	    
	} else {
	    return (
		<WikiEntry user={this.props.user} entity={this.props.entity} role={this.props.role} data={this.state.data} />
	    )
	}
    }
}
