// a data structure that may be useful for rendering a "List of Entity Annotaions" and an "Index of Entities"

export class ENode {

    // data is a "section"
    // we are just keeping track of how many entities are in this section
    // this was when entites were rare, so we wanted to indicate which
    // sections had entities and how many (ListOfEntities)
    constructor(data) {
        this.uid = data.uid
        this.id = data.id
        this.count = 1
    }

    increment() {
        return this.count++
    }
}

const LOG_ENTITIES = false

export class LOE {

    // data is the  raw json of the document
    // emap is the entityMap mapping sentences to the list of entityData for that sentence
    constructor(data, emap) {

        // entity index: maps the entity (qua string) to the list of pairs of [guids, role] consisting of the
        // of sentences annotated with it in that role.
        this.index = {}

        // list of enodes
        this.list = []

        // section id to ENode  1/21/2022 iam: is this even used?
        this.enodeMap = new Map()

        // entity map for the document
        this.entityMap = emap
        this.initialize(data, data)
        if (LOG_ENTITIES) {
            console.log(this.index)
        }
    }

    hasEntities() {
        return this.list.length > 0
    }

    indexAnnotations(x, annotations) {
        const guid = x.guid
        const content = x.content
        if (LOG_ENTITIES) {
            console.log(`<sentence: uid=${x.uid}>`)
            console.log(content)
        }
        annotations.forEach((annotation) => this.indexAnnotation(guid, content, annotation))
        if (LOG_ENTITIES) {
            console.log("</sentence>")
        }
    }

    indexAnnotation(guid, content, annotation) {
        const role =  annotation[0]
        const start = annotation[1]
        const end = annotation[2]
        const entity = content.substring(start, end)
        const index = this.index[entity]
        if (index) {
            index.push([guid, role])
        } else {
            this.index[entity] = [[guid, role]] // why just the role? why not the whole annotation?
        }
        if (LOG_ENTITIES) {
            console.log(`${entity} : [${start}, ${end}] role=${annotation[0]}`)
        }
    }

    initialize(x, s) {
        if (!x) {
            return;
        }
        const nt = x['node_type'];
        switch(nt) {
            case 'document': x['document_parts'].forEach((d) => this.initialize(d, d)); return;
            case 'document_part': {
                x['subsections'].forEach((d) => this.initialize(d, d));
                x['direct_children'].forEach((d) => this.initialize(d, x));
                return null;
            }
            case 'section': {
                x['subsections'].forEach((d) => this.initialize(d, d));
                x['direct_children'].forEach((d) => this.initialize(d, x));
                return null;
            }
            case 'figure': {
                x['caption'].forEach((d) => this.initialize(d, s));
                return;
            }
            case 'list': {
                x['list_items'].forEach((d) => this.initialize(d, s));
                return;
                
            }
            case 'paragraph':
            case 'list_item':
            case 'abbreviation_entry':
	    case 'defined_term':
	    case 'indented':
            case 'glossary_entry':
            case 'note': {
                x['direct_children'].forEach((d) => this.initialize(d, s));
                return;
            }
            case 'sentence': {
                const eInfo = this.entityMap.get(x.uid)
                if (eInfo) {
                    const annotations = eInfo.annotations()
                    if (annotations) {
                        this.indexAnnotations(x, annotations)
                        const loenode = this.enodeMap.get(s.id)
                        if (loenode) {
                            loenode.increment()
                        } else {
                            const enode = new ENode(s)
                            this.enodeMap.set(s.id, enode)
                            this.list.push(enode)
                        }
                    }
                }
                return;
            }
	    case 'definition_placeholder':
            case 'reference':
                return
            case 'table':
            case 'code':
                return
            default:
                console.error(`LOE: Don't forget about poor wee ${nt}`)
                return;
        }
    }

}
