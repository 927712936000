import ReactHtmlParser from 'react-html-parser';

export function rdfId2jsonId(rdf) {
    const sharp = rdf.indexOf('#')
    return rdf.substring(sharp + 1)
}


// make the JSON easier to grok
export function makePresentable(json) {
    // make a deep copy so we can destructively modify it
    // maybe later we could skip this step.
    const copy = JSON.parse(JSON.stringify(json));
    stripUids(copy)
    return copy
}

export function stripUids(x) {
    if (Array.isArray(x)) {
	x.forEach((y) => stripUids(y))
    } else if (typeof x === 'object') {
	delete x['uid']
	delete x['span_idxes']
	for (const key of Object.keys(x)) {
	    x[key] = stripUids(x[key])
	}
    }
    return x
}


// used to map "ex#'21905-h00'" to "21905"
// now just maps 21905-h00 to 21905
export function nodeBase(topNode){
    if (!topNode) {
	return topNode;
    }
    let dash = topNode.lastIndexOf("-")
    return dash === -1 ? topNode : topNode.substring(0, dash)
}


// maps "ex#'21905-h00'" to "h00"
export function nodeVersion(topNode){
    if (!topNode) {
	return topNode;
    }
    const start = topNode.indexOf("-") + 1;
    const finish = topNode.lastIndexOf("'");
    if (finish < 0){
	return topNode.substring(start);
    } else {
	return topNode.substring(start, finish);
    }
}

// helper routine (used in Document, and TableOfContents)
export function unchanged(data, other) {
    return !other ? true : other.unchanged(data);
}

export function containsHTML(str) {
    return  htmlTag.test(str) || (str && str.indexOf('&') >= 0)

}

const htmlTag = new RegExp(/<[/]?\w+>/)

export function isTag(str) {
    return htmlTag.test(str)
}

// renders html tags within the string.
export function innerHtml(str) {
    if (containsHTML(str)) {
	return ReactHtmlParser(str)
    } else {
	return str
    }
}




// used for scrolling, and as the identifier of DOM nodes corresponding to JSON nodes.
export function nodeIdentifier(data) {
    return data.id ? data.id : data.guid
}

export function openInNewTab(url) {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}


// quick and dirty. sorry.
export function cleanBlob(str) {
    let retval = str
    if (str && (str.indexOf('<') >= 0  || str.indexOf('>'))) {
	retval = str.replaceAll('<', '&lt')
	retval = str.replaceAll('>', '&gt')
    }
    return retval
}
