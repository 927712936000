import React  from 'react';
import { connect } from "react-redux";

import BugReportIcon from '@mui/icons-material/BugReport';
import IconButton from '@mui/material/IconButton';

import { ECOLOR } from '../../javascript/colors'

import { EToolip } from '../EToolip'

const mapStateToProps = state => {
    return {
        settings:  state.user.settings
    }
}


function ConnectedBugButton(props) {
    if (props.settings && !props.settings.value('debug')) {
	return null
    } else {
	const msg = props.msg
	const handleClick = () => { 
	    navigator.clipboard.writeText(msg)
	    console.log(msg) 
	}
	return (
	    <EToolip title="Print debug info to browser console (web developer tools)">
	    <IconButton style={{ color: ECOLOR }} aria-label="print debug info to the developer console window" component="span" onClick={handleClick} size="small">
	    <BugReportIcon />
	    </IconButton>
	    </EToolip>
	)
    }
}

const BugButton = connect(mapStateToProps)(ConnectedBugButton);

export default BugButton;
