import React, { useState } from 'react';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom'

import withStyles from '@mui/styles/withStyles';

import TreeView from '@mui/lab/TreeView';
import { v4 as getUUID } from 'uuid';

import { setLOIState } from "../../actions/index";

import CustomTreeItem from './CustomTreeItems'
import { Close, Open } from '../Icons'

import { si_header } from '../Issue'

import { TILabel } from './TreeItems'

import { ECOLOR } from '../../javascript/colors'

const useStyles = withStyles({
    root: {
        height: 240,
        flexGrow: 1,
        maxWidth: 400,
    },
    label: {
	    backgroundColor: "green",
	    color: "red"
    },
});

const mapStateToProps = state => {
    return {
	    document: state.portfolio.document,
	    other: state.portfolio.other,
	    loi_state: state.portfolio.loi_state,
	    settings: state.user.settings,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setLOIState: nodeList => dispatch(setLOIState(nodeList)),
    };
}

function treeifyIssues(document, loi, s, handler, other){
    const inode_list = loi.issues[s]
    if (!inode_list) {
	    return null
    }
    const inode = inode_list[0]
    const parentage = document.parentage()
    const pnode = parentage.pnode(inode.owner.guid)
    if (!pnode) {
	    console.error(inode.owner)
    }
    const fragment = pnode.section
    const label = `Section ${s} with ${inode_list.length} issues`
    const uuid = s + '_issues'
    const children = inode_list.length > 0 ? inode_list.map((inode) => treeifyINode(document, inode, handler, other)) : null
    return (
	    <CustomTreeItem key={uuid} nodeId={uuid} label={TILabel(label, handler(fragment))}>
	    {children}
	    </CustomTreeItem>
    );
}


function treeifyINode(document, inode, handler, other) {
    const guid = inode.owner.guid
    const parentage = document.parentage()
    const pnode = parentage.pnode(guid)
    const parent = pnode.parent
    const label = si_header(inode)
    //const uuid = getUUID();
    const uuid = inode.uid
    return (
	    <CustomTreeItem key={uuid} nodeId={uuid} label={TILabel(label, handler(parent))}>
	    </CustomTreeItem>
    );
}

function ConnectedListOfIssues(props) {
    // We have made some attempt to neutralize the selection weirdness.
    // Not really sure if we have succeeded, but ...
    const [selected, setSelected]  = useState(null)

    // no longer zoomOrScrolling
    let navigate = useNavigate();

    const other = props.other;

    const makeClickHandler = (fragment) => {
	    return () => {
	        const url = props.document.node2LocalURL(fragment, other)
	        console.log(`Navigating to ${url}; other=${other ? other.nodeId : null}`)
	        return navigate(url)
	    }
    }

    const handleSelect = (event, nodeIds) => {
	    setSelected(null)
    }

    const handleToggle = (event, nodeIds) => {
	    props.setLOIState([...nodeIds])
    }

    const document = props.document;
    if (!document) {
        return null;
    }
    const classes = useStyles;
    const loi = document.listOfIssues();
    const sections = Object.keys(loi.issues)
    return (
	    <TreeView key={getUUID()}
	    className={classes.root}
	    defaultCollapseIcon={<Close sx={{color: ECOLOR}} />}
	    defaultExpandIcon={<Open sx={{color: ECOLOR}} />}
	    expanded={props.loi_state}
	    selected={selected}
	    onNodeToggle={handleToggle}
	    onNodeSelect={handleSelect}
	    >
	    {sections.map((s) => treeifyIssues(document, loi, s, makeClickHandler, other))}
	    </TreeView>
    );
}


const ListOfIssues = connect(mapStateToProps, mapDispatchToProps)(ConnectedListOfIssues)

export default ListOfIssues
