import * as SPARQL from './sparql.js'


// cached queries via sparql


const cache = new Map()


export async function  query(queryString) {
    const start = performance.now()
    let retval = null
    let cached = false
    if (cache.has(queryString)) {
	cached = true
	retval = cache.get(queryString)
    } else {
	retval = await SPARQL.query(queryString)
	cache.set(queryString, retval)
    }
    const end = performance.now()
    const duration = end - start
    const whence = cached ? 'Cached' : 'Network'
    console.log(`${whence} query took ${duration}  ms`)
    return retval
}
