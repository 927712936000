import React, { Fragment } from 'react';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom'

import { v4 as getUUID } from 'uuid';

import NavDropdown from 'react-bootstrap/NavDropdown'

import { setBusy, setOther } from "../actions/index";

//import { unquote, nodeName, nodeBase } from '../javascript/utils'

import { nodeBase } from '../javascript/utils'

function mapDispatchToProps(dispatch) {
    return {
	    setBusy: bool => dispatch(setBusy(bool)),
	    setOther: other => dispatch(setOther(other)),
    };
}

const mapStateToProps = state => {
    return {
	    cache: state.network.cache,
	    builder: state.network.builder,
	    document: state.portfolio.document,
	    other: state.portfolio.other,
	    settings: state.user.settings,
    };
}


function UnconnectedDocumentComparator(props) {

    let navigate = useNavigate();

    let handleChange = (action) => {
	    if (action === 'clear') {
	        if (props.other) {
		        props.setOther(null);
		        const thisId = props.document.nodeId //unquote(props.document.nodeId)
		        let url = `/document/${thisId}`
		        console.log(`Navigating to ${url}`)
		        return navigate(url)
	        } else {
		        let url = "/"
		        console.log(`Navigating to ${url}`)
		        return navigate(url)
	        }
	    } else {
	        const thisId = props.document.nodeId //unquote(props.document.nodeId)
	        const thatId = action //unquote(action)
	        let url = `/compare/${thisId}/${thatId}`
	        console.log(`Navigating to ${url}`)
	        return navigate(url)
	    }
    }


    if (props.builder !== null && props.document !== null) {
	    // we only display ourself if there are several versions of the current document
	    const docNames = props.builder.getDocumentNames()
        //console.log('docNames', docNames)
	    const currentId = props.document.root();
	    const currentBase = nodeBase(currentId);
	    const versions = docNames.filter(node => nodeBase(node) === currentBase);
	    //console.log('currentId:', currentId, 'currentBase:', currentBase, 'versions:', versions);
	    if (versions && versions.length > 1) {
	        //const items = versions.sort().map(n => <NavDropdown.Item key={getUUID()} disabled={n === currentId} eventKey={n}>{nodeName(n)}</NavDropdown.Item>);
            const items = versions.sort().map(n => <NavDropdown.Item key={getUUID()} disabled={n === currentId} eventKey={n}>{n}</NavDropdown.Item>);
	        return (
		        <Fragment>
		        <NavDropdown title="Compare" id="basic-nav-dropdown" onSelect={handleChange}>
		        {items}
		        <NavDropdown.Divider />
		        <NavDropdown.Item eventKey="clear">Reset</NavDropdown.Item>
		        </NavDropdown>
		        </Fragment>
	        );
	    }
    }
    return null;
}


const DocumentComparator = connect(mapStateToProps, mapDispatchToProps)(UnconnectedDocumentComparator);


export default DocumentComparator;
