import { combineReducers } from 'redux'

import network from './network'
import portfolio from './portfolio'
import user from './user'


const rootReducer = combineReducers({ network,  portfolio,  user})

export default rootReducer
