
export const Roles = {
    ACTOR:       'Actor',
    DATA:        'Data',
    LOCATION:    'Loc',
    MESSAGE:     'Message',
    PROCEDURE:   'Procedure',
    NUMBER:      'Number',
}

// In order of 'importance' ...
export function roleList() {
    return [ Roles.ACTOR, Roles.DATA, Roles.MESSAGE, Roles.PROCEDURE, Roles.LOCATION, Roles.NUMBER ]
}
