import React  from 'react';

import { useNavigate } from 'react-router-dom'


import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import { ECOLOR } from '../../javascript/colors'

import { EToolip } from '../EToolip'


export default function UserGuide(props) {

    let navigate = useNavigate();

    const handleChange = (event) => {
	const url = '/'
	console.log(`Navigating to ${url}`)
	return navigate(url)
    }

	return (
        <EToolip title="Return Home">
	    <IconButton
            style={{ color: ECOLOR }}
            aria-label="Go Home"
            component="span"
            onClick={handleChange}
            size="large">
	    <HomeIcon/>
	    </IconButton>
	    </EToolip>
    );

}
