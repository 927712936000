import React  from 'react';
import { connect } from "react-redux";

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import IconButton from '@mui/material/IconButton';

import { logout, setDocument } from '../../actions/index'

import { EToolip } from '../EToolip'
import { ECOLOR } from '../../javascript/colors'

function mapDispatchToProps(dispatch) {
    return {
	setDocument: document => dispatch(setDocument(document)),
	logout: () => dispatch(logout())
    };
}

const mapStateToProps = state => {
    return {
	cache: state.network.cache,
	username: state.user.username,
    };
}

class ConnectedLogout extends React.Component {

    constructor (props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (event) => {
	this.props.setDocument(null)
	this.props.cache.clear()
	this.props.logout()
	console.log(`Logged out: ${this.props.username}`)
    }


    render() {
	return (
        <EToolip title="Logout">
	    <IconButton
            style={{ color: ECOLOR }}
            aria-label={`Logout ${this.props.username}`}
            component="span"
            onClick={this.handleChange}
            size="large">
	    <ExitToAppIcon/>
	    </IconButton>
	    </EToolip>
    );
    }
}

const Logout = connect(mapStateToProps, mapDispatchToProps)(ConnectedLogout);

export default Logout;

