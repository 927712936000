import React, { Component } from 'react';
import { connect } from "react-redux";


import TOCButton from './buttons/TOCButton'
import DocumentNameplate from './buttons/DocumentNameplate'
import LOFButton from './buttons/LOFButton'
import LOTButton from './buttons/LOTButton'
import EntityIndexButton from './buttons/EntityIndexButton'
import CSTButton from './buttons/CSTButton'

import { setBusy, setDimensions, setDocument, setBuilder, setSettings } from "../actions/index";

import Builder from '../javascript/Builder'

import Settings from '../javascript/Settings'

import { google_analytics_pageview } from '../javascript/ga'

import Sidebar from './Sidebar'

function mapDispatchToProps(dispatch) {
    return {
	setBusy: bool => dispatch(setBusy(bool)),
	setBuilder: tbuilder => dispatch(setBuilder(tbuilder)),
	setDimensions: dimensions => dispatch(setDimensions(dimensions)),
	setDocument: document => dispatch(setDocument(document)),
	setSettings: settings => dispatch(setSettings(settings))
    };
}

const mapStateToProps = state => {
    return { 
	builder: state.network.builder,
	document: state.portfolio.document, 
	busy: state.network.busy, 
	username: state.user.username,
	settings: state.user.settings
    }
}


class ConnectedHome extends Component {

    componentDidMount() {

	this.getDimensions()
	//add dimensions listener for window resizing
	window.addEventListener('resize', this.getDimensions)
	// load in the user's settings
	const settings = new Settings(null, this.props.username)
	this.props.setSettings(settings)
	console.log(`User's settings:\n${settings.toString()}`)
	if (this.props.redirect) {
	    // we are being redirected home; so we:
	    this.props.setDocument(null)
	    google_analytics_pageview()
	}
	if (!this.props.builder) {
	    const start = performance.now()
	    const builder = new Builder()
	    console.log('Loading document catalog')
	    builder.load().then(x => {
		this.props.setBuilder(builder);
		const end = performance.now()
		console.log(`Loaded document catalog in ${end-start}ms`);
		this.props.setBusy(false);
	    }).catch(error => console.error(error))
	}
    }

    componentWillUnmount() {
	window.removeEventListener('resize', this.getDimensions)
    }

    getDimensions = () => {
	this.props.setDimensions({ width: window.innerWidth, height: window.innerHeight })
	//console.log(this.state);
    }

    render() {
	const document = this.props.document
	const debug = this.props.settings ? this.props.settings.value('debug') : false
	const footer = document && !debug ? true : false
	const controls = document ?  <>	<DocumentNameplate /><TOCButton/><LOFButton/><LOTButton/><EntityIndexButton/><CSTButton/></> : null
	const busy = this.props.busy
	return <Sidebar busy={busy} footer={footer} controls={controls}/>
    }


}

const Home = connect(mapStateToProps, mapDispatchToProps)(ConnectedHome);

export default Home;
