import React, { Fragment }  from 'react';

import { EToolip } from '../EToolip'

import { v4 as getUUID } from 'uuid';

import { innerHtml } from '../../javascript/utils'

import { entityColor } from '../../javascript/colors'

import { parseTags, domify } from '../../javascript/entity'

//import { isReference, makeReference  } from './Reference'

import { Roles } from '../../javascript/roles'

import BugButton from './BugButton'

import LocationLink from './LocationLink'

import WikiButton from '../wiki/WikiButton'

export function processEntities(document, data, eInfo, annotations, debug = false) {
    const content = data.content
    const accumulator = []
    let offset = 0;
    for (let i = 0; i < annotations.length; i++){
	    offset = processAnnotation(document, data, eInfo, content, offset, annotations[i], accumulator, debug)
    }
    // maybe there is stuff after the last entity
    if (offset < content.length) {
	    const suffix = parseTags(content.substring(offset))
	    accumulator.push(...suffix)
    }
    const domified = domify(accumulator)
    if (false && debug) {
	    console.log('parseTags: ', accumulator)
	    console.log('domified: ', domified)
    }
    return domified
}

// returns the resulting offset
function processAnnotation(document, data, eInfo, content, offset, annotation, accumulator, debug) {
    const start = annotation[1]
    const end = annotation[2]
    const role = annotation[0]
    //as of 02/23/2022 we tack on the actual raw entity at position [3] or the Location object if it is a Loc.
    const all =  annotation[3]
    if (offset > start) {
	    console.error(`Entities.js offset error: offset=${offset} > start=${start} in\n${data.content}`)
    }
    const prefix = parseTags(content.substring(offset, start))
    accumulator.push(...prefix)
    const entity = content.substring(start, end)
    const entity_subtring = `content[${start}, ${end}): "${entity}"`
    if (false && debug) {
	    console.log(entity_subtring)
    }
    let text = entity
    let isRef = false
    let raw = null
    let location = null
    if (role === Roles.LOCATION) {
	    raw = eInfo.raw

	    //console.log('Location: ', entity, raw)
	    /* Now that we have location links these things are overkill and confusing (leave them just in the references).
	       if (isReference(document, entity)) {
	       //console.log(`${entity} IS a reference`)
	       text = makeReference(document, entity, data.guid)
	       isRef = true
	       }
	     */

	    location = <LocationLink key={getUUID()} location={all} />

    } else {
	    text = innerHtml(entity)
    }

    //const extra = (role === Roles.LOCATION) ? all.toString() : JSON.stringify(all, null, 1)
    const extra = (role === Roles.LOCATION) ? JSON.stringify(all.raw, null, 1) : JSON.stringify(all, null, 1)

    //const debugInfo = debug ? `uid: ${data.uid}\nguid: ${data.guid}\n${entity_subtring}\nraw entity info:\n${JSON.stringify(eInfo.raw, null, 1)}` : null
    const debugInfo = debug ? `uid: ${data.uid}\nguid: ${data.guid}\n${entity_subtring}\nraw entity info:\n${extra}` : null

    if (location) {
	    accumulator.push(
	        <Fragment key={getUUID()}>
	        <Entity key={getUUID()} content={content} debugInfo={debugInfo} raw={raw} text={text} role={role} tooltip={!isRef}></Entity>
	        {location}
	        </Fragment>
	    )
    } else {
	    accumulator.push(<Entity key={getUUID()} content={content} debugInfo={debugInfo} raw={raw} text={text} role={role} tooltip={!isRef}></Entity>)
    }
    return end
}

export default function Entity (props) {
    const entity = props.text
    const role = props.role
    const color = entityColor(role)
    let tooltip = null

    if (!['Loc', 'Number'].includes(role)) {
	const wiki = <WikiButton entity={entity} role={role}/>
	tooltip = <span key={getUUID()} > {role} {wiki}</span>
    } else {
	tooltip = role
    }

    if (props.debugInfo) {
	const content = props.content
	const msg = `${props.debugInfo}\ncontent: "${content}"\nentity: ${entity}`
	const bugButton = <BugButton key={getUUID()} msg={msg} />

	tooltip = <span key={getUUID()} > {tooltip} {bugButton}</span>
    }
    
    // no role tooltip for references, since they have their own
    if (!props.debugInfo && !props.tooltip) {
	return (
	    <span key={getUUID()} style={{ color: color }}>{entity}</span>
	);
    } else {
	return (
	    <EToolip title={tooltip}>
	    <span key={getUUID()} style={{ color: color }}>{entity}</span>
	    </EToolip>
	);
    }
}
