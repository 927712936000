import React, { useState } from "react";
import { connect } from 'react-redux';

import { Navigate } from 'react-router-dom'

import { Alert, Box, Button, Card, CardContent, Typography } from '@mui/material';

import { Form, Row, Col } from 'react-bootstrap';

import { APP_NAME } from '../javascript/constants'
import { ECOLOR } from '../javascript/colors'


import { login } from  '../javascript/network/auth'
import { google_analytics_pageview } from  '../javascript/ga'

import { loginSuccess, loginError, refreshToken } from "../actions/index";

const mapStateToProps = state => {
    return { user: state.user }
}

function mapDispatchToProps(dispatch) {
    return {
	loginSuccess: (username, token) => dispatch(loginSuccess(username, token)),
	loginError: (username, error) => dispatch(loginError(username, error)),
	refreshToken: token => dispatch(refreshToken(token))
    }
}


function ConnectedLogin(props) {
    //const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    function validateForm() {
	return username.length > 0 && password.length > 0
    }

    function reset() {
	setUsername('')
	setPassword('')
    }

    async function handleSubmit(event) {
	event.preventDefault();
	let token = null
	//console.log('submitting', username, password)
	try {
	    token = await login(username, password, props.refreshToken)
	} catch (e) {
	    console.error('login error', e)
	}
	//console.log('token', token)
	if (token) {
	    props.loginSuccess(username, token)
	} else {
	    props.loginError(username, 'Something went awry, sorry. Try again?')
	}
    }


    if ( props.user.username !== null && props.user.token !== null ) {
	//console.log('Navigating home')
	google_analytics_pageview(`/login/${props.user.username}`)
	return <Navigate to="/" replace />;
    } else {
	return (
	    <Box>
	    <Row>
	    <Col/>
	    <Col className="p-5" sm={6}>
	    <Card>
	    <CardContent>
	    <Typography variant="h5" color={ECOLOR}> {APP_NAME}  Login</Typography>
	    { props.user.error !== null ? <Alert severity="error"> <p>{props.user.error}</p></Alert> : '' }
	    <Form onSubmit={handleSubmit}>
	    <Form.Group className="mb-3" controlId="username">
	    <Form.Label>Username</Form.Label>
	    <Form.Control autoFocus type="username" placeholder="Enter your username" value={username} onChange={(e) => setUsername(e.target.value)}/>
	    </Form.Group>

	    <Form.Group className="mb-3" controlId="formBasicPassword">
	    <Form.Label>Password</Form.Label>
	    <Form.Control type="password" placeholder="Password"  value={password} onChange={(e) => setPassword(e.target.value)} />
	    </Form.Group>
	    <Button variant={validateForm() ? "contained" : "outlined"} type="submit" disabled={!validateForm()}>Go</Button>
											      &nbsp;&nbsp;
	    <Button variant="contained" onClick={reset}>Reset</Button>
	    </Form>
            </CardContent>
            </Card>
            </Col>
	    <Col/>
            </Row>
	    </Box>
	)
    }
}

export const Login = connect(mapStateToProps, mapDispatchToProps)(ConnectedLogin);

export default Login;
