import * as NS from '../../javascript/namespaces'

import { query } from '../../javascript/network/cached_sparql.js'


function len(p) { return p ? p.length : 0 }


export function encodeEntity(entity) {
    return encodeURIComponent(entity)
}


export function decodeEntity(code) {
    return decodeURIComponent(code)
}

export async function fetchData(entity, role) {
    const start = performance.now()
    
    const qsOne = queryOne(entity, role)
    const qsTwo = queryTwo(entity, role)
    const qsThree = queryThree(entity, role)
    const qsFour = queryFour(entity, role)
    
    const promises = [ query(qsOne), query(qsTwo), query(qsThree), query(qsFour)]

    let results = await Promise.all(promises)

    const end = performance.now()
    const duration = end - start
    
    let answers = { queryOne: results[0], queryTwo:  results[1], queryThree:  results[2], queryFour:  results[3] }
    
    console.log(`Got ${results.map((p) => len(p))} results after ${duration}  ms`, results)
    
    if (false) {
	results.map((a) => console.log(JSON.stringify(a)))
	console.log("results[0][0]", results[0][0])
    }

    return answers
}

//Query to get the sentences that mention the entity
function queryOne(entity, role) {
    const qOne = `
PREFIX rdf: <${NS.RDF}>
PREFIX src: <${NS.SRC}>
PREFIX ent: <${NS.ENTITY}>
PREFIX ef: <${NS.EFFIGY}> 
SELECT DISTINCT ?doc_title ?doc_version ?sent ?doc_id ?sent_id
WHERE {
  [] a ef:ImportedDocument;
    ef:sourceGraph ?sg;
    ef:entityGraph ?eg .
  GRAPH ?eg {
    ?er a ent:EntityProcessorSentenceResult ;
      ent:extracts/rdf:rest*/rdf:first ?ext ;
      ent:sentence_id ?sent_id .
    ?ext ent:text "${entity}" ;
      ent:entity_type "${role}" .
  } 
  GRAPH ?sg {
    ?doc_id a src:Document ;
      src:version ?doc_version ;
      src:id ?doc_title .
    ?sent_id src:content ?sent .
  } 
}
    `
    return qOne
}



//Query to get a single glossary entry:
function queryTwo(entity, role) {
    const qTwo = `
PREFIX rdf: <${NS.RDF}>
PREFIX src: <${NS.SRC}>
PREFIX ef: <${NS.EFFIGY}>
SELECT DISTINCT ?def ?doc_id ?doc_version
WHERE {
  ?graph a ef:SourceGraph .
  GRAPH ?graph {
    ?doc a src:Document ;
      src:id ?doc_id ;
      src:version ?doc_version .
    ?entry_inst a src:Glossary_entry ;
      src:term "${entity}" ;
      (ef:children/rdf:first){2}/src:content ?def .
  }
}
    `
    return qTwo
}

//Query to get the expansion of an abbreviation or defined_term
function queryThree(entity, role) {
    const qThree = `
PREFIX rdf: <${NS.RDF}>
PREFIX src: <${NS.SRC}>
PREFIX ef: <${NS.EFFIGY}>
SELECT DISTINCT ?def ?def_id ?doc_title ?doc_id ?doc_version
WHERE {
  ?graph a ef:SourceGraph .
  GRAPH ?graph {
    ?doc_id a src:Document ;
      src:version ?doc_version ;
      src:id ?doc_title .
    { ?entry_inst a src:Abbreviation_entry ;
      src:term "${entity}" ;
      src:uid ?def_id ;
      ef:children/rdf:first/src:content ?def .
    }
    UNION
    { ?entry_inst a src:Defined_term ; 
      src:term "${entity}" ;
      src:uid ?def_id ;
      ef:children/rdf:first/src:content ?def .
    }
  }
}
ORDER BY DESC(?doc_version)
`
    return qThree
}

//You could also run this query backwards, in case the entity is the expanded version:
function queryFour(entity, role) {
    const qFour = `
PREFIX rdf: <${NS.RDF}>
PREFIX src: <${NS.SRC}>
PREFIX ef: <${NS.EFFIGY}>
SELECT DISTINCT ?abbr ?doc_id ?doc_version
WHERE {
  ?graph a ef:SourceGraph .
  GRAPH ?graph {
    ?doc a src:Document ;
      src:id ?doc_id ;
      src:version ?doc_version .
    ?entry_inst a src:Abbreviation_entry ;
      src:term ?abbr ;
      ef:children/rdf:first/src:content "${entity}" .
  }
}
ORDER BY DESC(?doc_version)
`
    return qFour
}
