// TENURED

// metadata
export const MD_CONTROL = "md";
// table of contents
export const TOC_CONTROL = "toc";
// list of figures
export const LOF_CONTROL = "lof";
// list of tables
export const LOT_CONTROL = "lot";

// TENURE TRACK

// issue summary
export const IS_CONTROL = "is";
// list of issues
export const LOI_CONTROL = "loi";
// concrete synatx trees
export const CST_CONTROL = "cst";
// list of entities
export const LOE_CONTROL = "loe";
// index of entites
export const EI_CONTROL = "ei";


export function controlToHeader(control) {
    switch (control) {
	case MD_CONTROL: return "Meta Data"
	case TOC_CONTROL: return "Table of Contents"
	case LOF_CONTROL: return "List Of Figures"
	case LOT_CONTROL: return "List Of Tables"
	case IS_CONTROL: return "Issue Summary"
	case LOI_CONTROL: return "List of Issues"
	case CST_CONTROL: return "Concrete Syntax Trees"
	case LOE_CONTROL: return "List of Entities"
	case EI_CONTROL: return "Index of Entities"
	default:
	    console.error(`controlToHeader: Unexpected control: ${control}`)
	    return null
    }
}

export function settingsToControls(settings) {
	const retval = []
 	if (settings.value('entities')) {
	    retval.push(...[LOE_CONTROL, EI_CONTROL])
	}
	if (settings.value('cst')) {
	    retval.push(CST_CONTROL)
	}
	if (settings.value('issues')) {
	    retval.push(...[IS_CONTROL, LOI_CONTROL])
	}
    return retval
}


export function allControls(settings) {
    const retval = settingsToControls(settings)
    retval.push(...[MD_CONTROL, TOC_CONTROL, LOF_CONTROL, LOT_CONTROL])
    return retval
}
