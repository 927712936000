import { EFFIGY_NS } from './constants.js'
import { query } from './cached_sparql.js'
import OneShotTreeBuilder from './tree_builder.js'
import { mapValues } from './utils.js'

export const SRC_NS = 'http://www.sri.com/effigy/source#'

export default class SourceTreeBuilder extends OneShotTreeBuilder {

    topClass = "http://www.sri.com/effigy/source#Document"

    async getDocumentMetadata(){
        let queryString = `
            PREFIX ef: <${EFFIGY_NS}>
            PREFIX src: <${SRC_NS}>
            SELECT ?doc ?kind ?srcGraph ?entGraph ?cstGraph ?id ?srcFile ?title ?date ?version
            WHERE {
                ?imp a ef:ImportedDocument ;
                    ef:sourceGraph ?srcGraph ;
                    ef:entityGraph ?entGraph .
                OPTIONAL { ?imp ef:cstGraph ?cstGraph }
                GRAPH ?srcGraph {
                    ?doc a src:Document ;
                        src:id ?id ;
                        src:kind ?kind ;
                        src:source_file ?srcFile ;
                        src:title ?title ;
                        src:date ?date ;
                        src:version ?version .    
                }
            }
        `
        let results = await query(queryString)
        return results.map(r => mapValues(r, v=>v.value))
    }

    // Not used currently
    async getDocumentsForUidIri(uidIri){
        let queryString = `
            PREFIX ef: <${EFFIGY_NS}>
            PREFIX src: <${SRC_NS}>
            SELECT ?doc ?graph
            WHERE {
                ?graph a ef:SourceGraph .
                GRAPH ?graph {
                    ?doc a src:Document .
                    ?doc ((src:content | src:subsections | src:document_parts) / rdf:rest*/rdf:first)+ ${uidIri}
                }
            }
        `
        return await query(queryString)
    }

}
