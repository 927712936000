import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom'

import ViewportList from "react-viewport-list";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import IconButton from '@mui/material/IconButton';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';

import Typography from '@mui/material/Typography';

import { ECOLOR } from '../../javascript/colors'
import { innerHtml, rdfId2jsonId } from '../../javascript/utils'


import SearchField from './SearchField'


export default function Occurrences (props) {
    let navigate = useNavigate();
    
    const [query, setQuery] = useState('')

    const handleChange = (e) => { setQuery(e.target.value) }
    const entity = props.entity
    const height = props.height
    let sentences = props.data.queryOne
    // a hack to get an index
    sentences.forEach((s, i) => s.count = i)
    sentences = sentences.filter(s => s.sent.value.indexOf(query) >= 0)
    
    let qtext = query.length > 0 ? `that contain the string "${query}"` : ''

    return (
	<Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="occurrences-content"
        id="occurrences-header"
        >
        <Typography style={{ color: ECOLOR }}>{innerHtml(`Occurrences (${sentences.length}) of the entity ${entity} in the corpus ${qtext}`)}</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <SearchField query={query} handler={handleChange} />
	<List key={'ol'} dense={true}>
        <OccurrenceList sentences={sentences} navigate={navigate} height={height}/>
        </List>
	</AccordionDetails>
	</Accordion>
    )
}

// new fangled way.
function OccurrenceList (props) {
    const ref = useRef(null)
    const navigate = useNavigate();
    const sentences = props.sentences
    const height = props.height
    return  (
	    <div className="scroll-container" style={{maxHeight: height, overflow: 'auto'}} ref={ref}>
	    <ViewportList viewportRef={ref} items={sentences} itemMinSize={40} margin={8}>
            {(item) =>  wikiSentenceItem(item, item.count, navigate) }
	    </ViewportList>
	    </div>
    )
}


/* old slow way 
function OccurrenceList (props) {
    const navigate = props.navigate
    const sentences = props.sentences //.slice(0, 100)
    return sentences.map((s, i) =>  wikiSentenceItem(s, i, navigate))
}
*/

function wikiSentenceItem(sentence, index, navigate) {
    
    const text = innerHtml(`<b>${index}:</b> <em>${sentence.sent.value} </em>`)
    const doc_title = sentence.doc_title.value   
    const doc_version = sentence.doc_version.value
    const doc = `${doc_title}  version ${doc_version}`
    //const json = JSON.stringify(sentence)
    const doc_id = rdfId2jsonId(sentence.doc_id.value)
    const sent_id = rdfId2jsonId(sentence.sent_id.value)

    const handleClick = () => {
	let url = `/document/${doc_id}/sentence/${sent_id}`
	console.log(`Navigating to ${url}`)
	return navigate(url)
    }


    return (
    	<ListItem key={'oli' + index}
        secondaryAction={
            <IconButton style={{ color: ECOLOR }} edge="end" aria-label="navigate to the document" onClick={handleClick}>
            <AssistantDirectionIcon />
            </IconButton>
	}
	>
	<ListItemText key={'olit' + index} primary={text} secondary={doc}/>
	</ListItem>
	)
}



