
import React  from 'react';
import { connect } from "react-redux";

import ParkIcon from '@mui/icons-material/Park';
import IconButton from '@mui/material/IconButton';


import { ECOLOR } from '../../javascript/colors'
import { EToolip } from '../EToolip'
import { setControl } from "../../actions/index";
import { CST_CONTROL } from '../../constants/controls'

function mapDispatchToProps(dispatch) {
    return {
	setControl: control => dispatch(setControl(control)),
    };
}

const mapStateToProps = state => {
    return {
	control: state.portfolio.control,
	document: state.portfolio.document,
        settings:  state.user.settings
    }
}


class ConnectedCSTButton extends React.Component {

    constructor (props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick = (event) => {
	    const  off = this.props.control === CST_CONTROL
	    this.props.setControl(off ? null :  CST_CONTROL );
    }

    render() {
        const csts = this.props.settings.value('cst')
        if (!csts) {
            return null
        }
        const document = this.props.document;
        if (!document || !document.hasConcreteSyntaxTrees()) {
            return null;
        }
	const  off = this.props.control === CST_CONTROL
	return (
            <EToolip title="Index of CSTs">
	    <IconButton
            style={{ color: ECOLOR }}
            aria-label={`${off ? "close" : "open"} the document's index of concrete syntax trees`}
            component="span"
            onClick={this.handleClick}
            size="large">
	    <ParkIcon />
	    </IconButton>
	    </EToolip>
        );
    }
}

const CSTButton = connect(mapStateToProps, mapDispatchToProps)(ConnectedCSTButton);

export default CSTButton;
