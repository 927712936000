import { Component, Fragment } from 'react';
import { useNavigate } from 'react-router-dom'

import Card from 'react-bootstrap/Card'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import WikiLink from '../wiki/WikiLink'

import Typography from '@mui/material/Typography';

/*
   import List from '@mui/material/List';
   import ListItem from '@mui/material/ListItem';
   import ListItemText from '@mui/material/ListItemText';
 */



import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';

import TextField from '@mui/material/TextField';

import { ECOLOR, entityColor } from '../../javascript/colors'

import { innerHtml } from '../../javascript/utils'

import { v4 as getUUID } from 'uuid';

import { EToolip } from '../EToolip'

import * as QBUtils from './QBUtils'

import Busy from './Busy'

import NavigationBar from './NavigationBar'

function createRow(entity, role, key) {
    return { entity, role, key }
}

function row2TableData (row, index, uid) {
    return createRow(row.text.value, row.type.value, uid + index);
}

export default class QueryBrowser extends Component {

    constructor(props) {
	super(props)
	this.debug = QBUtils.monitor(props.user)
	if (this.debug) { console.log('QueryBrowser', props) }
	this.state = { data: null, busy: false, ...QBUtils.string2QBState(props.qbs) }
	this.textFieldHandler = this.textFieldHandler.bind(this)
	this.setBusy = this.setBusy.bind(this)
    }

    async componentDidMount() {
	if (this.debug) { console.log(">QueryBrowser.componentDidMount") }
	if (this.props.qbs) {
	    let state = await QBUtils.computeState(this.props.qbs)
	    this.setState( state )
	}
	if (this.debug) { console.log("<QueryBrowser.componentDidMount") }
    }


    async componentDidUpdate(prevProps) {
	if (this.debug) { console.log(">QueryBrowser.componentDidUpdate") }
	if (prevProps.qbs !== this.props.qbs) {
	    if (this.props.qbs) {
		let state = await QBUtils.computeState(this.props.qbs)
		this.setState( { busy: false, ...state} )
	    } else {
		this.setState( { data: null, busy: false,  ...QBUtils.string2QBState(null)} )
	    }
	}
	if (this.debug) { console.log("<QueryBrowser.componentDidUpdate") }
    }

    makeButtonClickHandler (index) {
	return () => { this.buttonToggleSelected(index) }
    }

    buttonToggleSelected(index) {
	const new_fstate = [...this.state.fstate]
	new_fstate[index] = !new_fstate[index]
	this.setState({ fstate: new_fstate, disabled: false })
    }

    buttonIsSelected(index) {
	return this.state.fstate[index]
    }

    goIsDisabled() {
	return this.state.disabled || this.state.fstate.every((val) => !val)
    }

    setBusy() {
	this.setState( { busy: true } )
    }

    textFieldHandler(event) {
	this.setState({ prefix: event.target.value, disabled: false });
    }

    render() {
	if (this.debug) { console.log('QueryBrowser.render', this.state) }
	const buttons = QBUtils.roles.map((r, i) => {
	    const selected = this.buttonIsSelected(i)
	    const tooltip = `Press to ${selected ? 'remove' : 'include'}  entities with role ${r} in the index below`
	    return (
		<EToolip key={`filteredButton${r}_tooltip`}title={tooltip}>
	        <Button
	        key={`filteredButton${r}`}
	        size="small"
	        variant={selected ? "contained" : "outlined"}
	        color={r.toLowerCase()} // provided by the theme
	        onClick={this.makeButtonClickHandler(i)}>
	        {r}
	        </Button>
		</EToolip>
	    )
	})


	const style = QBUtils.makeStyle(this.props.user)

	if (this.state.busy) {
	    return ( <Busy/> );
	} else {
	    const text = this.state.prefix ? this.state.prefix : ""
	    const qbs = QBUtils.qbState2String(this.state)
	    return (
		<Fragment>
		<NavigationBar header={'Entity Query Browser'} style={style} />
		<Card border="info" style={{ ...style}}>
		<Card.Body>
		<Grid container spacing={6}>
		<Grid item>
		<TextField required id="outlined-required" label="Entity Prefix" value={text} onChange={this.textFieldHandler}/>
		</Grid>
		<Grid item alignItems="stretch" style={{ display: "flex" }}>
		{buttons}
		</Grid>
		<Grid item alignItems="stretch" style={{ display: "flex" }}>
		<GoButton disabled={this.goIsDisabled()} setBusy={this.setBusy} qbs={qbs}/>
		</Grid>
		</Grid>
		</Card.Body>
		</Card>
		{displayResultsTable(this.props.user, this.state)}
		</Fragment>
	    );
	}
    }
}

/*
function displayResultsList(user, state) {
    let data = state.data
    if (!data) {
	return null;
    }
    const uid = getUUID()
    const rows = data.map((row, index) => row2TableData(row, index, uid))
    const debug = QBUtils.monitor(user)

    const style = QBUtils.makeStyle(user)

    const header = QBUtils.state2Heading(rows.length, state)

    const table = rows.length> 0 ?
        <List dense={true} key={uid}>			       
        {rows.map((row) => (
	    <ListItem key={uid + 'li' + row.key}>
	    <ListItemText primary=<WikiLink entity={row.entity} role={row.role}/>
	                  key={uid + 'lit' + row.key}
                          secondary={row.role} />
	    </ListItem>
        ))}
	</List>
    : null
    return (
        <Card border="info" style={{ maxHeight: style.dimensions.height, ...style}}>
        <Card.Header> <span style={{ color: ECOLOR }}>{header}</span> </Card.Header>
        <Card.Body>
	{table}
        </Card.Body>
        </Card>
    );
}
*/

function displayResultsTable(user, state) {
    let data = state.data
    if (!data) {
	return null;
    }
    const uid = getUUID()
    const rows = data.map((row, index) => row2TableData(row, index, uid))

    const style = QBUtils.makeStyle(user)

    const header = QBUtils.state2Heading(rows.length, state)

    const table = rows.length> 0 ?
			       <TableContainer key={uid + 'container'} component={Paper}>
    <Table key={uid + 'table'} sx={{ minWidth: 650 }} size="small"  aria-label="simple table">
    <TableHead key={uid + 'head'}>
    <TableRow key={uid + 'header-row'}>
    <TableCell><Typography variant="h5">Entity</Typography></TableCell>
    <TableCell align="right"><Typography variant="h5">Role</Typography></TableCell>
    </TableRow>
    </TableHead>
    <TableBody>
    {rows.map((row) => (
	<TableRow
	key={uid + row.key}
	sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
	    >
	    <TableCell key={row.name + 'entity'} component="th" scope="row">
            <WikiLink entity={row.entity} role={row.role} text={innerHtml(row.entity)}/>
	    </TableCell>
	    <TableCell key={row.name + 'role'} align="right"><Typography style={{ color: entityColor(row.role)}}>{row.role}</Typography></TableCell>
	    </TableRow>
        ))}
        </TableBody>
	</Table>
	</TableContainer>
    : null
    return (
        <Card border="info" style={{ maxHeight: style.dimensions.height, ...style}}>
        <Card.Header> <span style={{ color: ECOLOR }}>{header}</span> </Card.Header>
        <Card.Body>
	{table}
        </Card.Body>
        </Card>
    );
}

/*
function NavLink(props) {
    let navigate = useNavigate();
    let entity = props.entity
    let role = props.role

    const handleClick = () => {
	let url = `/wiki/${entity}/${role}`
	console.log(`Navigating to ${url}`)
	return navigate(url)
    }
    return (
	<Link component="button" color={entityColor(role)} variant="body2"  onClick={handleClick}> {entity} </Link>
    )
}
*/


function GoButton(props) {
    let navigate = useNavigate();
    const handleClick = () => {
	props.setBusy()
	let url = `/qb/${props.qbs}`
	console.log(`Navigating to ${url}`)
	return navigate(url)
    }
    return (
	<Button color="secondary" variant="contained"  disabled={props.disabled} onClick={handleClick}>
	Go
	</Button>
    )
}
