import { cyan, red, green, grey, purple, yellow, indigo, orange } from '@mui/material/colors';



import { Roles } from './roles'

export const ECOLOR = purple.A700

export const ERROR_COLOR = red.A400

export const LINK_COLOR = green[700]

export const DISABLED = grey[600]

export const CHANGED_COLOR = red[50]
export const NEW_COLOR = green[400]



export function entityColor(role) {
    const retval = purple[500]
    switch (role) {
	case Roles.ACTOR:     return purple.A700
	case Roles.DATA:      return cyan[700]
	case Roles.MESSAGE:   return yellow[700]
	case Roles.PROCEDURE: return indigo[500]
	case Roles.NUMBER:    return orange[900]
	case Roles.LOCATION:  return green[700]
	default:
	    console.error(`Unhandled role ${role}`, role)
	    return retval
    }
}


export const ACTOR_COLOR = purple.A700
export const DATA_COLOR = cyan[700]
export const MESSAGE_COLOR = yellow[700]
export const PROCEDURE_COLOR = indigo[500]
export const NUMBER_COLOR = orange[900]
export const LOCATION_COLOR = green[700]

