import Typography from '@mui/material/Typography';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav'

import { ECOLOR } from '../javascript/colors'
import { APP_NAME } from '../javascript/constants'
import { SRISpinner, SunflowerSpinner} from './Spinners'

import DocumentActions from './DocumentActions'
import DocumentComparator from './DocumentComparator'

import SettingsButton from './buttons/SettingsButton'
import SearchButton from './buttons/SearchButton'
import UserGuide from './buttons/UserGuide'
import Logout from './buttons/Logout'
import IansButton from './buttons/IansButton'
import QueryBrowserButton from './buttons/QueryBrowserButton'
import RawQueryBrowserButton from './buttons/RawQueryBrowserButton'

export default function Navigation(props) {
    const busy = props.busy
    const catalogue = props.catalogue
    const controls = props.controls

    return (
	<Navbar bg="light" expand="lg">
	<SunflowerSpinner spin={busy}/>
	<Typography style={{ color: ECOLOR }} variant="h6"> {APP_NAME}</Typography>
	<Nav className="mr-auto">
	<DocumentComparator />
	<DocumentActions />
	</Nav>
	{catalogue}
	{controls}
	<SearchButton />
	<SettingsButton />
	<UserGuide />
	<QueryBrowserButton />
	<RawQueryBrowserButton />
	<IansButton />
	<Logout />
	<SRISpinner spin={busy}/>
	</Navbar>	    
    );

}
