// for making sense of location entities so we can turn them into active links.

// https://github.com/SRI-CSL/Effigy/blob/6e8ae10af2c969205209c286a565f73050bb2e18/source-documents/schemas/entities-schema.json

import { getRFC_URL, get3GPP_URL } from './Refs'

const LOCATION_DEBUG = false

export default class Location {


    constructor(doc, sentence_id, data, debug) {
	this.doc = doc
	this.sentence_id = sentence_id
	this.raw = data
	this.ok = data.hasOwnProperty('fields') ? true : false
	// the EntityDetails object
	this.fields = this.ok ? this.raw.fields : null

	this.debug = debug &&  LOCATION_DEBUG

	// if it's local we'll need to RESOLVE these
	// this will need to be a case analysis: clause, annex, figure, table, section
	this.entity_id = null   // not quite the same as the JSON id (e.g. lacks the Table Figure Annex prefix)
	this.fragment = null
	this.kind = null  // one of 'annex', 'figure', 'section', or 'table'

	// if it's in the corpus we'll need to RESOLVE this
	// this too might have to be a case analysis: tr, ts, citation
	this.documentId = null


	// if it's a citation we should RESOLVE this
	this.reference = null

	// Here is where all the resolution takes place.
	if (this.isLocal()) {
	    this.resolveLocal()
	} else if (this.isExternal()) {
	    if (!this.getCitation()) {
		if (this.debug) {
		    console.log('External but NOT a citation', this.sentence_id)
		}
		// work to do here; but NOT YET done
		return
	    }
	    this.resolveCitation()
	}
    }

    isCitation() {
	return this.reference != null
    }

    isInCorpus() {
	return this.isSelfReference() || (this.isCitation() && this.reference.isInCorpus())
    }

    //N.B. this language predates the language in the user guide.
    // here local means in the current document
    // while extrernal means somewhere esTle.
    isLocal() {
	return this.ok && (this.isSelfReference() || !this.isExternal())
    }

    isExternal() {
	// iam: I presume there are no self references in the references.
	return this.ok && (this.fields.ts || this.fields.tr || this.fields.citation || this.fields.rfc);
    }

    isSpecialized() {
	return this.ok && (this.fields.clause || this.fields.annex || this.fields.figure || this.fields.table || this.fields.section);
    }

    isGlobal() {
	return this.ok && !this.isSpecialized()
    }

    isSelfReference() {
	return this.ok && this.fields.self_reference
    }

    span2String(span) {
	if (span.text && span.ref) {
            /*
	       if (span.text !== span.ref) {
	       console.log(`Confusion:  { ref: "${span.ref}" text: "${span.text}" }`)
	       }
             */
	    return span.ref
	} else if (span.text) {
	    return span.text
	} else if (span.ref) {
	    return span.ref
	} else {
	    return '?'
	}
    }


    addFacts(msg, debug) {
	if (!debug) { return msg }
	return `
${msg}
isCitation:      ${this.isCitation()}
isInCorpus:      ${this.isInCorpus()}
isLocal:         ${this.isLocal()}
isExternal:      ${!!this.isExternal()}
isSpecialized:   ${!!this.isSpecialized()}
isGlobal:        ${this.isGlobal()}
getLocalURL:     ${this.getLocalURL()}
getExternalURL:  ${this.getExternalURL()}
	    `
    }

    toString(debug) {
	let retval = 'Empty'
        if (this.ok) {
	    retval = `${this.isLocal() ? 'Internal' : (this.isInCorpus() ? 'External Corpus' : 'External Non-Corpus')} (${this.isGlobal() ? 'Global' : 'Local'})`
            if (debug) {
                retval += ': '
	        let fields = Object.getOwnPropertyNames(this.fields)
	        // the fields of the EntityDetails object
	        // each field should be an array but ...
	        fields.sort()
	        fields.forEach( (f) => {
		    let ed = this.fields[f]
		    if (Array.isArray(ed)) {
		        retval += ' '
		        retval += f + ' = '
		        retval += ed.map((s) => this.span2String(s))
		    } else {
                        if (f === 'self_reference') {
                            retval = 'Self Reference '
                        } else {
		            console.log(`Unhandled entity for field ${f}: `,  this.raw)
                        }
		    }
	        })
	    }
        }
	
	return this.addFacts(retval, debug)
    }

    getLocalURL() {
	if (this.isLocal()) {
	    const docId = this.doc.root()
	    if (this.fragment !== null) {
		return `/document/${docId}/${this.kind}/${this.entity_id}`
	    } else {
		if (this.fields.self_reference) {
		    return `/document/${docId}/`
		} else {
		    return null
		}
	    }
	} else if (this.isInCorpus()) {
	    const reference = this.reference
	    const latest = reference.latest
	    if (this.isSpecialized()) {
		const section = this.getClause() || this.getSection()
		if (section) {
		    return `/document/${latest}/section/${section}`
		}
		const table = this.getTable()
		if (table) {
		    return `/document/${latest}/table/${table}`
		}
		const figure = this.getFigure()
		if (figure) {
		    return `/document/${latest}/figure/${figure}`
		}
		const annex = this.getAnnex()
		if (annex) {
		    return `/document/${latest}/annex/${annex}`
		}

	    } else {
		return `/document/${latest}`
	    }
	}
    }

    getExternalURL() {
	if (this.isCitation() && this.reference) {
	    return this.reference.getURL()
	} else if (this.isExternal()) {
	    // this.fields.ts || this.fields.tr || this.fields.rfc
	    let slot = this.getRfc()
	    if (slot) {
		return getRFC_URL(slot)
	    }
	    // these are hail maries
	    slot = this.getTs()
	    if (slot) {
		return get3GPP_URL(slot)
	    }
	    slot = this.getTr()
	    if (slot) {
		return get3GPP_URL(slot)
	    }
	    return null;
	}
    }



    getSlot(sname) {
	if (this.ok) {
	    let slot = this.fields[sname]
	    if (slot) {
		const vals = slot.map((s) => this.span2String(s))
		return vals ? vals[0] : null
	    }
	}
	return null
    }

    getClause() { return this.getSlot("clause") }
    getCitation() { return this.getSlot("citation") }
    getTr() { return this.getSlot("tr") }
    getTs() { return this.getSlot("ts") }
    getAnnex() { return this.getSlot("annex") }
    getFigure() { return this.getSlot("figure") }
    getTable() { return this.getSlot("table") }
    getRfc() { return this.getSlot("rfc") }
    getSection() { return this.getSlot("section") }


    resolveSection() {
	let section = this.getClause() || this.getSection()
	if (section) {
	    const fragment = this.doc.resolveFragmentFromId(section, 'section')
	    if (fragment) {
		this.fragment = fragment
		this.entity_id = section
		this.kind = 'section'
		// if (this.debug) { console.log("section found: ",  section) }
	    } else {
		if (this.debug) { console.log("section NOT found: ",  section, this.getClause(), this.getSection()) }
	    }
	    return true
	}
	return false
    }

    resolveAnnex() {
	let annex = this.getAnnex()
	if (annex) {
	    const fragment = this.doc.resolveFragmentFromId(annex, 'annex')
	    if (fragment) {
		this.fragment = fragment
		this.entity_id = annex
		this.kind = 'annex'
		// if (this.debug) { console.log("annex found: ",  annex) }
	    } else {
		if (this.debug) { console.log("annex NOT found: ",  annex) }
	    }
	    return true
	}
	return false
    }

    resolveFigure(){
	let figure = this.getFigure()
	if (figure) {
	    const fragment = this.doc.resolveFragmentFromId(figure, 'figure')
	    if (fragment) {
		this.fragment = fragment
		this.entity_id = figure
		this.kind = 'figure'
		// if (this.debug) { console.log("figure found: ",  figure) }
	    } else {
		if (this.debug) { console.log("figure NOT found: ",  figure) }
	    }
	    return true
	}
	return false
    }

    resolveTable() {
	let table = this.getTable()
	if (table) {
	    const fragment = this.doc.resolveFragmentFromId(table, 'table')
	    if (fragment) {
		this.fragment = fragment
		this.entity_id = table
		this.kind = 'table'
		// if (this.debug) { console.log("table found: ",  table) }
	    } else {
		if (this.debug) { console.log("table NOT found: ",  table) }
	    }
	    return true
	}
	return false
    }

    resolveLocal() {
	if (this.resolveSection()) { return }
	if (this.resolveAnnex()) { return }
	if (this.resolveFigure()) { return }
	if (this.resolveTable()) { return }
    }


    resolveCitation() {
	let citation = this.getCitation()
	if (citation) {
	    const references = this.doc.references()
	    const refNode = references.lookup(citation)
	    if (refNode) {
		this.reference = refNode
		this.documentId = refNode.latest
		//console.log(`Reference ${citation} found`)
	    } else {
		if (this.debug) { console.log(`Reference ${citation} NOT found`) }
	    }
	}
    }
}
