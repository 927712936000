export function rdfStringToJsonString(str){
    const parts = str.split('#')
    if (parts.length ===  2){
        return parts[1]
    }
    else {
        return str
    }
}

export function mapEntries(obj, keyFunc, valFunc) {
    return Object.fromEntries(Object.entries(obj).map(([k,v]) => [keyFunc(k), valFunc(v)]))
}

export function mapValues(obj, valFunc){
    return mapEntries(obj, key=>key, valFunc)
}
