import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

import { ECOLOR } from '../../javascript/colors'

const ETextField = styled(TextField)({
  '& label.Mui-focused': {
    color: ECOLOR,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: ECOLOR,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: ECOLOR,
    },
    '&:hover fieldset': {
      borderColor: ECOLOR,
    },
    '&.Mui-focused fieldset': {
      borderColor: ECOLOR,
    },
  },
});


export default function SearchField (props) {
    return ( <ETextField id="standard-search"  label="Search field"  type="search" value={props.query} onChange={props.handler} color="secondary"
		     InputProps={{ startAdornment: (  <InputAdornment position="start">  <SearchIcon sx={{color: ECOLOR}} />  </InputAdornment> ),  }}
		     variant="standard" size="small" /> )
}

