import React from 'react';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom'

import { v4 as getUUID } from 'uuid';

import NavDropdown from 'react-bootstrap/NavDropdown'

import { controlToHeader, settingsToControls } from '../constants/controls.js'

import { setControl } from "../actions/index";

function mapDispatchToProps(dispatch) {
    return {
	setControl: control => dispatch(setControl(control)),
    };
}

const mapStateToProps = state => {
    return { 
	portfolio: state.portfolio,
	user: state.user,
    };
}


function UnconnectedDocumentActions(props) {

    let navigate = useNavigate()

    const handleChange = (action) => {
	if (action === 'clear') {
	    if (props.portfolio.control) {
		props.setControl(null);
	    } else {
		let url = "/"
		console.log(`Navigating to ${url}`)
		return navigate(url)
	    }
	} else if (props.portfolio.control === action) {
	    props.setControl(null)
	} else {
	    props.setControl(action)
	}
    }

    if (!props.portfolio.document) {
	return null
    }

    const options = settingsToControls(props.user.settings)
    if (!options.length){
	return null
    }
	
    if (options) {
	const items = options.map(opt => <NavDropdown.Item key={getUUID()} eventKey={opt}>{controlToHeader(opt)}</NavDropdown.Item>);
	return (
	    <NavDropdown title="Actions" id="basic-nav-dropdown" onSelect={handleChange}>
	    {items}
	    <NavDropdown.Divider />
	    <NavDropdown.Item eventKey="clear">Reset</NavDropdown.Item>
	    </NavDropdown>
	)
    }
    return null
}


const DocumentActions = connect(mapStateToProps, mapDispatchToProps)(UnconnectedDocumentActions);


export default DocumentActions;
