import React, { Fragment, useState } from 'react';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser';

import withStyles from '@mui/styles/withStyles';

import TreeView from '@mui/lab/TreeView';
import CustomTreeItem from './CustomTreeItems'
import { Close, Open } from '../Icons'


import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';

import { v4 as getUUID } from 'uuid';

import { setEIState } from "../../actions/index";

import { TILabel } from './TreeItems'

import { roleList } from '../../javascript/roles'

import { EToolip } from '../EToolip'

import { ECOLOR } from '../../javascript/colors'

const timings = false

const useStyles = withStyles({
    root: {
        height: 240,
        flexGrow: 1,
        maxWidth: 400,
    },
    label: {
	    backgroundColor: "green",
	    color: "red"
    },
});

const mapStateToProps = state => {
    return {
	    document: state.portfolio.document,
	    other: state.portfolio.other,
	    ei_state: state.portfolio.ei_state,
	    settings: state.user.settings,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setEIState: nodeList => dispatch(setEIState(nodeList)),
    };
}

function treeifyMention(uuid, pair, occurrence, document, parentage, handler, other) {
    const guid = pair[0]
    const role = pair[1]
    const pnode = parentage.pnode(guid)
    const section = pnode.section
    const label = `${occurrence}: In a ${pnode.clan} as ${role} in ${section.id} (&nbsp; In section: <em>${section.name}</em>&nbsp; )`
    const fragment = pnode.parent
    const occid = `${uuid}_occurrence_${occurrence}`
    return (
	    <CustomTreeItem key={occid} nodeId={occid} label={TILabel(label, handler(fragment))}>
	    </CustomTreeItem>
    );
}

function treeifyEntity(entity, document, entityIndex, handler, other){
    const mentions = entityIndex[entity]

    const label = <span> {ReactHtmlParser(entity)}  with {mentions.length} occurrence{mentions.length > 1 ? 's' : ''} </span>
    const parentage = document.parentage()
    const uuid = `entity_${entity}_treeitem`

    const children = mentions.map((pair, occurrence) => treeifyMention(uuid, pair, occurrence, document, parentage, handler, other))

    return (
	    <CustomTreeItem key={uuid} nodeId={uuid} label={label}>
	    {children}
	    </CustomTreeItem>
    );
}


const jibberish = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
        m: 1,
    },
}

function ConnectedEntityIndex(props) {

    // We have made some attempt to neutralize the selection weirdness.
    // Not really sure if we have succeeded, but ...
    const [selected, setSelected]  = useState(null)

    // no longer zoomOrScrolling
    let navigate = useNavigate();

    const start = performance.now()
    const roles = roleList();

    const document = props.document;
    const other = props.other;
    const entityIndex = document ? document.indexOfEntities() : null
    const entities = entityIndex ? Object.keys(entityIndex) : null

    if (entities) {
	    entities.sort()
    }

    const makeButtonClickHandler = (index) => {
	    return () => { buttonToggleSelected(index) }
    }

    const makeClickHandler = (fragment) => {
	    return () => {
	        const url = props.document.node2LocalURL(fragment, other)
	        console.log(`Navigating to ${url}; other=${other ? other.nodeId : null}`)
	        return navigate(url)
	    }
    }

    const handleSelect = (event, nodeIds) => {
	    setSelected(null)
    }

    const handleToggle = (event, nodeIds) => {
	    props.setEIState({ expanded: [...nodeIds] })
    }

    const buttonIsSelected = (index) => {
	    return props.ei_state.fstate[index]
    }

    const buttonToggleSelected = (index) => {
	    const new_fstate = [...props.ei_state.fstate]
	    new_fstate[index] = !new_fstate[index]
	    props.setEIState({ fstate: new_fstate })
    }

    const filterEntities = (roles) => {
	    if (!entities || !roles || !roles.length) {
	        return null
	    }
	    const filteredEntityMap = {}
	    entities.forEach((entity) => {
	        const pairs = entityIndex[entity]
	        if (pairs) {
		        const filtered = pairs.filter((p) => roles.includes(p[1]))
		        if (filtered.length) {
		            filteredEntityMap[entity] = filtered
		        }
	        }
	    })
	    return filteredEntityMap
    }

    if (!document) {
        return null;
    }

    if (!entities || !entities.length) {
	    return (
	        <em>Sorry no entities here yet. </em>
	    );
    }

    const buttons = roles.map((r, i) => {
	    const selected = buttonIsSelected(i)
	    const tooltip = `Press to ${selected ? 'remove' : 'include'}  entities with role ${r} in the index below`
	    return (
            <EToolip key={`filteredButton${r}_tooltip`}title={tooltip}>
	        <Button
	        key={`filteredButton${r}`}
	        size="small"
	        variant={selected ? "contained" : "outlined"}
	        color={r.toLowerCase()} // provided by the theme
	        onClick={makeButtonClickHandler(i)}>
	        {r}
	        </Button>
            </EToolip>
	    )
    })

    const selectedRoles = []
    props.ei_state.fstate.forEach((v, i) => { if (v) { selectedRoles.push(roles[i]) } })

    const filteredMap = filterEntities(selectedRoles)

    const fentities =  filteredMap ? Object.keys(filteredMap) : null
    if (fentities) {
	    fentities.sort()
    }
    const children = fentities ? fentities.map((entity) => treeifyEntity(entity, document, filteredMap, makeClickHandler, props.other)) : null

    const tree =
	    <TreeView key={getUUID()}
    className={useStyles.root}
    defaultCollapseIcon={<Close sx={{color: ECOLOR}} />}
    defaultExpandIcon={<Open sx={{color: ECOLOR}} />}
    expanded={props.ei_state.expanded}
    selected={selected}
    onNodeToggle={handleToggle}
    onNodeSelect={handleSelect}
    >
    {children}
    </TreeView>
    const retval  = (
	    <Fragment>
	    <Box sx={jibberish}>
	    <ButtonGroup color="secondary" aria-label="medium secondary button group">
        {buttons}
	    </ButtonGroup>
	    </Box>
	    {tree}
	    </Fragment>
    );

    const end = performance.now()
    const loadTime = end - start
    if (timings) { console.log(`EntityIndex.render() after ${loadTime}ms`) }
    return retval;
}


const EntityIndex = connect(mapStateToProps, mapDispatchToProps)(ConnectedEntityIndex);

export default EntityIndex;
