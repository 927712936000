import { Fragment } from 'react';

import { nodeIdentifier, unchanged, innerHtml } from '../../javascript/utils'

import { MINIO_URL } from '../../javascript/network/constants'

import { CHANGED_COLOR } from '../../javascript/colors'

import Box from '@mui/material/Box';

import Issue from '../Issue'
import { v4 as getUUID } from 'uuid';

import ReactHtmlParser from 'react-html-parser';

import Sentence from './Sentence'

import BugButton from './BugButton'

import ReferenceLink from './Reference'

import MentionsButton from '../mentions/MentionsButton'


// find the node that corresponds to the data object
// (which belongs to another version of the document) in this document
export function resolveFragment(data, document) {
    let retval = null
    if (!data) {
	return retval;
    }
    if (data.node_type === 'figure') {
	const lof = document.listOfFigures()
	const node = lof.index[data.id]
	// node is a LOFNode
	if (node) {
	    retval = node.data
	}
    }  else  if (data.node_type === 'table') {
	const blob = document.getBlobById(data.id)
	if (blob) {
	    retval = blob.data
	}
    } else {
	const toc = document.tableOfContents()
	const node = toc.mapping[data.id]
	// node is a TOCNode
	if (node) {
	    retval = node.data
	}
    }
    return retval
}



export function Root(props) {
    //const start = performance.now()
    const document = props.document
    const other = props.other
    const debug = props.debug
    const data = document.jsonData()
    const partsList = data['document_parts']
    const parts = partsList.map((s) => <DocumentPart key={getUUID()} data={s} document={document} other={other} debug={debug}/>)
    //const end = performance.now()
    //const loadTime = end - start
    //console.log(`Root.render() took ${loadTime}ms`)
    return (
	parts
    );
}


// represents a json node of node_type "document_part"
// props.data will be the json for that node
function DocumentPart(props) {
    const document = props.document;
    const data = props.data;
    const other = props.other;
    const debug = props.debug
    const subsections = data['subsections'];
    const sections = subsections.map((s) => <Section key={s.uid} data={s} document={document} other={other}  debug={debug} />);
    const content = data['direct_children'];
    //if (content.length > 0 && subsections.length > 0) { console.log(`Full DocumentPart: ${data.id}`) }
    const children = content.map((dc) => makeDataContentChild(dc, 'document_part', document, other, debug));
    const identifier = nodeIdentifier(data)
    return (
        <div id={identifier}>
        {children}
        {sections}
        </div>
    );
}


// represents a json node of node_type "paragraph"
// props.data will be the json for that node
function Paragraph(props) {
    const document = props.document;
    const data = props.data;
    const other = props.other;
    const debug = props.debug
    const content = data['direct_children'];
    if (!content  || !Array.isArray(content)) {
	console.error("Paragraph with BAD content: ", data)
	return null
    }
    const sentences = content.map((s) => <Sentence key={getUUID()} data={s} document={document} other={other}  debug={debug}/>);
    const issues = issuesOfNode(document, data)
    const identifier = nodeIdentifier(data)
    return ( <p key={data.uid} id={identifier}> {issues} {sentences} </p> );
}

// like a paragraph but no newline at the front.
function Span(props) {
    const document = props.document;
    const data = props.data;
    const other = props.other;
    const debug = props.debug
    const content = data['direct_children'];
    const sentences = content.map((s) => <Sentence key={getUUID()} data={s} document={document} other={other}  debug={debug}/>);
    const identifier = nodeIdentifier(data)
    return (
	<span key={data.uid} id={identifier}> {sentences} </span>
    );
}


// represents a json node of node_type "figure"
// props.data will be the json for that node
function Figure(props) {
    const document = props.document;
    const data = props.data;
    const other = props.other;
    const debug = props.debug
    const name = data['filename']
    const filename = MINIO_URL + name;
    const id = data['id'];
    const caption = data['caption'];
    const mentions = <MentionsButton key={getUUID()} doc={document.nodeId} id={id} node={'figure'}/>

    const sentences = caption.map((s) => <Sentence key={getUUID()} data={s} document={document} other={other}  debug={debug}/>);
    const unchanged_image_hash = other === null ? true : other.sameContentHash(data)
    const verbose = false
    const issues = issuesOfNode(document, data)
    if (verbose && (other !== null)) {
	if (!unchanged_image_hash) {
	    console.log('New image hash:', id)
	}
    }

    const msg = `uid: ${data.uid}\nguid: ${data.guid}\nsource: ${name}`

    const bugButton = <BugButton msg={msg} />

    const yadayada = id ? <b> {id}: {issues} &nbsp; {sentences} </b> :  <b> {issues} &nbsp; {sentences} </b>
    if (unchanged(data, other) || unchanged_image_hash) {
        return (
	    <figure className="figure" id={id}>
	    <img src={filename} alt=""/>
	    <figcaption className="figure-caption">
	    {mentions} {bugButton} {yadayada}
	    </figcaption>
	    </figure>
        );
    } else {
	return (
	    <figure className="figure" id={id}>
	    <img src={filename} alt="" style={{border: '1px solid red'}}/>
	    <figcaption className="figure-caption">
	    {mentions} {bugButton} {yadayada}
	    </figcaption>
	    </figure>
	);
    }
}

// represents a json node of node_type "note"
// props.data will be the json for that node
function Note(props) {
    const document = props.document
    const data = props.data
    const other = props.other
    const debug = props.debug
    const id = data['id']
    const content = data['direct_children']
    // the old way, where a note is followed by an array of sentences
    const issues = issuesOfNode(document, data)
    const identifier = nodeIdentifier(data)
    if (content.length === 1) {
	const paragraph = content[0]
	const sentences = paragraph['direct_children'].map((s) => <Sentence key={getUUID()} data={s} document={document} other={other}  debug={debug}/>);
	return (
	    <p id={identifier}>{id}: {issues} {sentences} </p>
	)
    } else {
	const paragraphs = content.map((s) => <Paragraph key={getUUID()} data={s} document={document} other={other}  debug={debug}/>);
	// FIXME: this is not identical formatting. We need to do something better here. Maybe append the <b>{id}</b> onto the front of the first paragraph?
	return (
	    <span id={identifier}>{id}: {issues} {paragraphs} </span>
	);
    }
}

// represents a json node of node_type "abbreviation_entry"
// props.data will be the json for that node
function AbbreviationEntry(props) {
    return renderDefinition(props.document, props.other, props.data, props.debug)
}

function renderDefinition(document, other, data, debug) {
    //TODO: can the term be changed, but all the sentences the same?
    // we can't detect that subtlety.
    const term = innerHtml(data['term']);
    const content = data['direct_children'];
    const children = content.map((s) => <Sentence key={getUUID()} data={s} document={document} other={other}  debug={debug}/>);
    const issues = issuesOfNode(document, data)
    const identifier = nodeIdentifier(data)
    if (unchanged(data, other)) {
        return (
	    <p id={identifier}>{term}: {issues} &nbsp;&nbsp; {children}</p>
        );
    } else {
        return (
	    <p style={{ backgroundColor: CHANGED_COLOR }} id={identifier}>{term}:{issues} &nbsp;&nbsp; {children}</p>
        );
    }
}

// represents a json node of node_type "defined_term"
// props.data will be the json for that node
function DefinedTerm(props) {
    //console.log(`DefinedTerm ${props.data['term']}`, props.data)
    return renderDefinition(props.document, props.other, props.data, props.debug)
}

// represents a json node of node_type "definition_placeholder"
// props.data will be the json for that node
function DefinitionPlaceholder(props) {
    const document = props.document;
    const data = props.data;
    const other = props.other;
    const term = innerHtml(data['term']);
    const issues = issuesOfNode(document, data)
    const identifier = nodeIdentifier(data)
    if (unchanged(data, other)) {
        return (
	    <p id={identifier}>{term}: {issues}</p>
        );
    } else {
        return (
	    <p style={{ backgroundColor: CHANGED_COLOR }} id={identifier}>{term}:{issues}</p>
        );
    }
}

// represents a json node of node_type "reference"
// props.data will be the json for that node
function Reference(props) {
    const document = props.document;
    const data = props.data
    const other = props.other
    const ref_number = data['reference_number']
    const ref_id = data['referenced_doc_id']
    const title = data['referenced_doc_title']
    const ref_title = title ? innerHtml(data['referenced_doc_title']) : null
    const identifier = nodeIdentifier(data)
    const references = document.references()
    const refNode = references.lookup(ref_number)
    let link = refNode ? <ReferenceLink key={getUUID()} node={refNode}></ReferenceLink> : `[${ref_number}]`
    if (unchanged(data, other)) {
        return (
	    <div id={identifier}>{link}: {ref_title} {ref_id}</div>
        );
    } else {
        return (
	    <div style={{ backgroundColor: CHANGED_COLOR }} id={identifier}>{link}: {ref_title} {ref_id}</div>
        );
    }
}


// represents a json node of node_type "glossary_entry"
// props.data will be the json for that node
function GlossaryEntry(props) {
    const document = props.document;
    const data = props.data;
    const other = props.other;
    const debug = props.debug
    const term = data['term'];
    const content = data['direct_children'];
    const children = content.map((dc) => makeDataContentChild(dc, 'glossary_entry', document, other, debug));
    const identifier = nodeIdentifier(data)
    if (unchanged(data, other)) {
        return (
	    <div id={identifier}><b>{term}: </b> {children}</div>
        );
    } else {
	return (
	    <div style={{ backgroundColor: CHANGED_COLOR }} id={identifier}><b>{term}: </b> {children}</div>
	);
    }
}

// represents a json node of node_type "list"
// props.data will be the json for that node
function List(props) {
    const document = props.document;
    const data = props.data;
    const other = props.other;
    const debug = props.debug
    const content = data['list_items'];
    const issues = issuesOfNode(document, data)
    const items = content.map((s) => <ListItem key={getUUID()} data={s} document={document} other={other}  debug={debug} />);
    const identifier = nodeIdentifier(data)
    return (
	<Fragment>
	{issues}
	<ul style={{ listStyleType: "none" }} key={getUUID()} id={identifier}> {items} </ul>
	</Fragment>
    );
}


// represents a json node of node_type "list_item"
// props.data will be the json for that node
function ListItem(props) {
    const document = props.document;
    const data = props.data;
    const other = props.other;
    const symbol = !data['list_symbol'] ? '' : innerHtml(data['list_symbol']);
    const content = data['direct_children'];
    const clist = content.map((s) => makeListItemChild(s, document, other));
    const issues = issuesOfNode(document, data)
    const identifier = nodeIdentifier(data)
    if (unchanged(data, other)) {
        return (
	    <li key={getUUID()} id={identifier}> {symbol} {issues} &nbsp;&nbsp; {clist} </li>
        );
    } else {
        return (
	    <li key={getUUID()} style={{ backgroundColor: CHANGED_COLOR }} id={identifier}> {symbol} &nbsp;&nbsp; {clist}  </li>
        );
    }
}



function makeDataContentChild(data_content, parent, document, other, debug) {
    switch(data_content.node_type) {
        case 'indented':
        case 'paragraph':
	    if (['document_part', 'section'].includes(parent)) {
		return (
		    <Paragraph key={getUUID()} data={data_content} document={document} other={other}  debug={debug} />
		);
	    } else {
		return (
		    <Span key={getUUID()} data={data_content} document={document} other={other}  debug={debug} />
		);
	    }
/*	
        case '_paragraph': return (
            <Paragraph key={getUUID()} data={data_content} document={document} other={other}  debug={debug} />
            );
*/	    
        case 'list': return (
            <List key={getUUID()} data={data_content} document={document} other={other}  debug={debug} />
        );
        case 'note': return (
            <Note key={getUUID()} data={data_content} document={document} other={other}  debug={debug} />
        );
        case 'glossary_entry': return  (
            <GlossaryEntry key={getUUID()} data={data_content} document={document} other={other}  debug={debug} />
        );
        case 'abbreviation_entry': return  (
            <AbbreviationEntry key={getUUID()} data={data_content} document={document} other={other}  debug={debug} />
        );
        case 'defined_term': return  (
            <DefinedTerm key={getUUID()} data={data_content} document={document} other={other}  debug={debug} />
        );
        case 'definition_placeholder': return  (
            <DefinitionPlaceholder key={getUUID()} data={data_content} document={document} other={other}  debug={debug} />
        );
        case 'figure': return  (
            <Figure key={getUUID()} data={data_content} document={document} other={other}  debug={debug} />
        );
        case 'reference': return  (
            <Reference key={getUUID()} data={data_content} document={document} other={other}  debug={debug} />
        );
	case 'table':
	case 'code': return (
	    <HtmlBlob key={getUUID()} data={data_content} document={document} other={other} />
	);
        default:  {
	    console.log("BUG: Unknown data content child - ", data_content);
	    return null;
	}
    }
}

function HtmlBlob(props) {
    const document = props.document;
    const data = props.data
    const other = props.other;
    const name = data.filename
    // for seeing what causes the invalid html warning from react.
    //console.log(name)
    const content = document.getBlobContent(name)
    if (!content) {
	return null
    }
    const issues = issuesOfNode(document, data)
    let jsx = null
    try {
	jsx = ReactHtmlParser(content)
    } catch (error) {
	console.log(error)
	return null
    }
    const nt = data.node_type

    const msg = `uid: ${data.uid}\nguid: ${data.guid}\nsource: ${name}`

    const bugButton = <BugButton msg={msg} />

    const unchanged = other === null ? true : other.sameContentHash(data)
    if (!unchanged) {
	const commonStyles = {
	    bgcolor: 'background.paper',
	    m: 1,
	    border: 5,
	}
	//console.log(`${nt} blob has been updated: ${name}`)
	jsx =  <Box sx={{ ...commonStyles, borderColor: CHANGED_COLOR }}> {jsx} </Box>
    }


    switch (nt) {
	case 'table': {
	    const id = data.id
	    const caption = data.caption
	    const sentences = caption.map((s) => <Sentence key={getUUID()} data={s} document={document} other={props.other}/>);
	    const yadayada = <Fragment> {id}: {bugButton} {issues} &nbsp; {sentences} </Fragment>
	    const mentions = data['anonymous'] ? null : <MentionsButton key={getUUID()} doc={document.nodeId} id={id} node={'table'}/>
	    if (sentences.length > 0 ) {
		return (
		    <figure className="table" id={id}>
		    {jsx}
		    <figcaption className="table-caption">
		    {mentions} {yadayada}
		    </figcaption>
		    </figure>
		)
	    } else {
		return (<Fragment>{mentions} {bugButton} {issues} {jsx}</Fragment>)
	    }
	}
	case 'code': {
	    //const blob = document.getBlobByGuid(data.guid)
	    //console.log(`code: ${blob.parent.id} ${blob.data.id}`)
	    return (<Fragment>{bugButton}{issues}{jsx}</Fragment>)
	}
	default: {
	    console.error(`Unrecognized HtmlBlob: ${nt}`)
	    return null
	}
    }

}



const ANNEX_ID = new RegExp(/[A-Z]\./)

function isAnnex(id) {
    return ANNEX_ID.test(id) || id.toLowerCase().startsWith('annex')
}

// represents a json node of node_type "section"
// props.data will be the json for that node
function Section(props) {
    const document = props.document;
    const data = props.data;
    const other = props.other;
    const debug = props.debug
    const subsections = data['subsections'];
    const name = innerHtml(data.name)
    const issues = issuesOfNode(document, data)
    // may as well be consistent
    const identifier = nodeIdentifier(data)
    // entity references treat annexes differently than sections
    const node = isAnnex(identifier) ? 'annex' : 'section'
    const mentions = <MentionsButton key={getUUID()} doc={document.nodeId} id={identifier} node={node}/>
    const header = sectionHeader(identifier, name, issues, mentions)
    const sections = subsections.map((s) => <Section key={getUUID()} data={s} document={document} other={other}  debug={debug} />);
    const content = data['direct_children'];
    const children = content.map((dc) => makeDataContentChild(dc, 'section', document, other, debug));
    return (
        <Fragment>
        {header}
        {children}
        {sections}
        </Fragment>
    );
}


function sectionHeader(id, name, issues, mentions) {
    let rank = 1;
    if (id) {
        rank = id.split('.').length;
    }
    switch(rank){
        case 1: {
	    if (id === name) {
		return (<h1 id={id}>{mentions} {name} {issues}</h1>);
	    } else {
		return (<h1 id={id}>{mentions} {id}: {name} {issues}</h1>);
	    }
	}
        case 2: return (<h2 id={id}>{mentions} {id}: {name} {issues} </h2>);
        case 3: return (<h3 id={id}>{mentions} {id}: {name} {issues} </h3>);
        case 4: return (<h4 id={id}>{mentions} {id}: {name} {issues} </h4>);
        case 5: return (<h5 id={id}>{mentions} {id}: {name} {issues} </h5>);
        case 6:
        default: return (<h6 id={id}>{mentions} ({id}): {name} {issues} </h6>);
    }
}


export function renderFragment(fragment, document, other, debug) {
    if (!fragment) {
	return ( <span key={getUUID()} className="text-info"><em>The selected entity does not exist in this version.</em></span>  );
    }
    switch(fragment.node_type) {
	case 'document_part': return ( <DocumentPart key={getUUID()} data={fragment} document={document} other={other}  debug={debug}/> );
	case 'section': return ( <Section key={getUUID()} data={fragment} document={document} other={other}  debug={debug}/> );
	case 'indented':
	case 'paragraph': return (<Paragraph key={getUUID()} data={fragment} document={document} other={other}  debug={debug}/> );
	case 'sentence': return (<Sentence key={getUUID()} data={fragment} document={document} other={other}  debug={debug}/> );
	case 'figure': return (<Figure key={getUUID()} data={fragment} document={document} other={other}  debug={debug}/> );
	case 'table': return (<HtmlBlob key={getUUID()} data={fragment} document={document} other={other}  debug={debug}/> );
	case 'note': return (<Note key={getUUID()} data={fragment} document={document} other={other}  debug={debug}/> );
	case 'defined_term':  return (<DefinedTerm key={getUUID()} data={fragment} document={document} other={other}  debug={debug}/> );
	case 'abbreviation_entry': return (<AbbreviationEntry key={getUUID()} data={fragment} document={document} other={other}  debug={debug}/> );
	case 'definition_placeholder': return (<DefinitionPlaceholder key={getUUID()} data={fragment} document={document} other={other}  debug={debug}/> );
	case 'reference': return (<Reference key={getUUID()} data={fragment} document={document} other={other}  debug={debug}/> );
	case 'glossary_entry': return (<GlossaryEntry key={getUUID()} data={fragment} document={document} other={other}  debug={debug}/> );
	case 'list': return (<List key={getUUID()} data={fragment} document={document} other={other}  debug={debug}/> );
	default: {
	    console.warn(`renderFragment didn't handle: ${fragment.node_type}`)
	    return null;
	}
    }
}

function makeListItemChild(elem, document, other, debug){
    switch(elem.node_type) {
    case 'indented':
    case 'paragraph': return (
        <Span key={getUUID()} data={elem} document={document} other={other}  debug={debug} />
    );
    case 'list':
        return (
            <List key={getUUID()} data={elem} document={document} other={other}  debug={debug} />
        );
    case 'note':
        return (
            <Note key={getUUID()} data={elem} document={document} other={other}  debug={debug} />
        );
    default:  {
	console.log(`BUG: Unknown list item child - ${elem.node_type} with id ${elem.uid}`);
	return null;
    }
    }
}


export function issuesOfNode(document, data, debug) {
    const issue_list = document.issuesOfNode(data)
    if (debug && issue_list) {
	issue_list.map((inode) => console.log(inode))
    }
    const issues = issue_list ? issue_list.map((inode) => <Issue key={getUUID()} issue={inode} />) : null;
    return issues
}
