import { query } from '../../javascript/network/cached_sparql.js'

const FIGURE_PREFIX = new RegExp(/[Ff]igure\s+([\w.-]*)/)
const TABLE_PREFIX = new RegExp(/[Tt]able\s+([\w.-]*)/)
const ANNEX_PREFIX = new RegExp(/[Aa]nnex\s+([\w.-]*)/)
export function distillId(id, node) {
    switch (node) {
	case 'figure': {
	    if (FIGURE_PREFIX.test(id)) {
		const fmatch = FIGURE_PREFIX.exec(id)
		return fmatch[1]
	    } else {
		return id
	    }
	}
	case 'table': {
	    if (TABLE_PREFIX.test(id)) {
		const tmatch = TABLE_PREFIX.exec(id)
		return tmatch[1]
	    } else {
		return id
	    }
	}
	case 'annex': {
	    if (ANNEX_PREFIX.test(id)) {
		const amatch = ANNEX_PREFIX.exec(id)
		return amatch[1]
	    } else {
		return id
	    }
	}
	default: return id
    }
}

// turns a document name into a string to filter on
function fstr(name) {
    let prefix = name.substring(0, name.indexOf('-'))
    let arr = prefix.split('')
    arr.splice(2, 0, '.')
    return arr.join('')
}


function qall(document, node, identifier) {
    const filterString = fstr(document)
    const id = distillId(identifier, node)
    //console.log(`filterString = ${filterString}`)
    //console.log(`distillId(${identifier}, ${node}) = ${id}`)
    return `
PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
PREFIX src: <http://www.sri.com/effigy/source#>
PREFIX ent: <http://www.sri.com/effigy/entities#>
PREFIX ef: <http://www.sri.com/effigy#> 
SELECT DISTINCT ?src_doc ?src_title ?src_version ?sent_id ?sent_content
WHERE {
  [] a ef:ImportedDocument;
    ef:sourceGraph ?sg;
    ef:entityGraph ?eg .
  GRAPH ?eg {
    ?er a ent:EntityProcessorSentenceResult ;
      ent:sentence_id ?sent_id ;
      ent:extracts/rdf:rest*/rdf:first ?ext .
    ?ext ent:entity_type "Loc" ;
      ent:fields ?fields .
    ?fields ent:${node}/rdf:rest*/rdf:first/ent:ref "${id}" ;
      ent:ts/rdf:rest*/rdf:first/ent:ref ?ts .
    FILTER (strEnds( ?ts, "${filterString}" ))
  } 
  GRAPH ?sg {
    ?src_doc a src:Document;
      src:version ?src_version;
      src:id ?src_title .
      ?sent_id src:content ?sent_content .
  } .
}
    `
}

export function props2query(props) {
    return qall(props.doc, props.node, props.id)
}


export async function fetchState(q) {
    const start = performance.now()
    let data = []
    let error = null
    try {
	//console.log(q)
	data = await query(q)
    } catch (err) {
	error = err
	console.log(`Caught an error: ${err.toString()}`)
    }
    const end = performance.now()
    const duration = end - start
    console.log(`Got ${data.length} results after ${duration}  ms`, data)
    return { data, query: q, error}
}
