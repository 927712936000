import { useParams, useSearchParams } from "react-router-dom";
import { useSelector } from 'react-redux'

import Loader from './Loader'

import { google_analytics_pageview } from '../javascript/ga'

/*

   A hook to pass the Router stuff to our Loader which will load the state appropriately then render "Home"

 */


export default function LoaderHook(props) {
    const settings = useSelector(state => state.user.settings)
    const debug = settings ? settings.value('debug') : false


    google_analytics_pageview()

    let params = useParams()

    let [searchParams, setSearchParams] = useSearchParams()

    //[ 'reload', 'sectionId' ].forEach((sp) => { console.log(`${sp} : ${searchParams.get(sp)}`) })

    let documentId = params.documentId ? params.documentId : null

    let fragmentId = params.fragmentId ? params.fragmentId : null

    let fragmentKind = props.fragmentKind ? props.fragmentKind : null

    if (debug) console.log(`LoaderHook: documentId=${documentId} fragmentKind=${fragmentKind} fragmentId=${fragmentId}`)

    if (documentId) {
	    // normal single document mode
	    return (
            <Loader documentId={documentId} otherId={null} fragmentKind={fragmentKind} fragmentId={fragmentId} searchParams={searchParams} setSearchParams={setSearchParams} /> );
    } else {
	    // compare mode
	    let thisId = params.thisId
	    let thatId = params.thatId
	    return (
            <Loader documentId={thisId} otherId={thatId} fragmentKind={fragmentKind} fragmentId={fragmentId} searchParams={searchParams} setSearchParams={setSearchParams} /> );
    }

}
