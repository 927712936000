let site = null;

const WE_ARE_THE_FAKAWE = window.location.href

if (WE_ARE_THE_FAKAWE.indexOf('localhost') > 0) {
    site = 'local'
} else if (WE_ARE_THE_FAKAWE.indexOf('effigy.cta.sri.com') > 0) {
    site = 'dev'
} else if (WE_ARE_THE_FAKAWE.indexOf('effigy.cse.sri.com') > 0) {
    site = 'prod'
} else {
    site = 'unknown'
}

export const server_environment = site
