import * as Types from '../constants/action-types';

import { roleList } from '../javascript/roles'


const roles = roleList();
const fstate = roles.map((r) => { return false; })

const initialState = { control: null,
		               document: null,
		               fragment: null,
		               other: null,
		               toc_state: [],
		               is_state: [],
		               loi_state: [],
		               cst_state: [],
		               ei_state: { expanded: [], fstate: fstate },
                       }

    function portfolio(state = initialState, action) {
    //console.log(action)
    switch(action.type) {
	case Types.SET_CONTROL: return {...state, control: action.payload }
	case Types.SET_OTHER:
	    return action.payload ?
		   {...state, other: action.payload } :
		   {...state, other: null, fragment: null }
	case Types.SET_DOCUMENT: return {...state,
					 document: action.payload,
					 fragment: null,
					 other: null,
					 control: null,
					 toc_state: []}
	case Types.SET_FRAGMENT: return {...state, fragment: action.payload }
	case Types.SET_TOC_STATE: return {...state, toc_state: action.payload }
	case Types.SET_IS_STATE: return {...state, is_state: action.payload }
	case Types.SET_LOI_STATE: return {...state, loi_state: action.payload }
	case Types.SET_CST_STATE: return {...state, cst_state: action.payload }
	case Types.SET_EI_STATE: {
        let ei_state = {...state.ei_state, ...action.payload }
        let retval = {...state, ei_state: ei_state}
        return retval
    }
	default: return state
    }
}

export default portfolio
