import { useSelector } from 'react-redux'

import { useParams } from "react-router-dom";

import { google_analytics_pageview } from '../../javascript/ga'

import  Wiki from './Wiki'

import * as QBUtils from '../qb/QBUtils'

import { decodeEntity } from './WikiUtils'

export default function WikiHook(props) {

    google_analytics_pageview()

    let params = useParams()

    let entity = params.entity || null
    
    let role = params.role || null

    const user = useSelector(state => state.user)
    
    const debug = QBUtils.monitor(user)

    if (debug) { console.log("WikiHook", params, entity) }

    return (
	<Wiki entity={decodeEntity(entity)} role={role} user={user} />
    )

}
