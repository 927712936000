import React from 'react';
import { connect } from "react-redux";

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

import Document from './document/Document'

const mapStateToProps = state => {
    return {
	    document: state.portfolio.document,
	    other: state.portfolio.other,
    }
}

class ConnectedDocumentTabs  extends React.Component {
    render() {
	    const document = this.props.document
	    if (!document) {
	        return null;
	    }
        const other = this.props.other;
	    if (!other) {
	        return (<Document role={0} />);
	    }
	    const currentName = document.root()
	    const otherName = other.root()
	    return (
	        <Tabs defaultActiveKey="current" transition={false} id="document-tabs">
	        <Tab eventKey="current" title={currentName}>
	        <Document role={0}/>
	        </Tab>
	        <Tab eventKey="other" title={otherName}>
	        <Document role={1}/>
	        </Tab>
	        </Tabs>
	    );
    }
}

const DocumentTabs = connect(mapStateToProps)(ConnectedDocumentTabs);

export default  DocumentTabs;
