import React from 'react';
import { render } from 'react-dom'
import { Provider } from "react-redux";
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

import './index.css';

import store from "./store/index";

import Login from './components/Login'
import LoaderHook from './components/LoaderHook'

import { ECOLOR } from './javascript/colors'

import { google_analytics_initialize } from './javascript/ga'


import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";


import Home from './components/Home'

import Guide from './guide/guide'

import QBLoaderHook from './components/qb/QBLoaderHook'

import RQBLoaderHook from './components/rqb/RQBLoaderHook'

import WikiHook from './components/wiki/WikiHook'

import TableOfContents from './components/toc/TableOfContents'

import { MentionsLoaderHook } from './components/mentions/Mentions'

import 'bootstrap/dist/css/bootstrap.min.css'

import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles'

import { entityColor } from './javascript/colors'

import { Roles } from './javascript/roles'

const { palette } = createTheme();

const { augmentColor } = palette;

const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

// extend the colors with the entity colors
const theme = createTheme({
  palette: {
      ecolor: createColor(ECOLOR),
      actor: createColor(entityColor(Roles.ACTOR)),
      data: createColor(entityColor(Roles.DATA)),
      message: createColor(entityColor(Roles.MESSAGE)),
      procedure: createColor(entityColor(Roles.PROCEDURE)),
      number: createColor(entityColor(Roles.NUMBER)),
      loc: createColor(entityColor(Roles.LOCATION)),
  },
});

google_analytics_initialize()


// probably could improve this with some matching...
render(
    <Provider store={store}>
    <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
    <BrowserRouter>
    <Routes>
    <Route path="/" element={<RequireAuth><Home redirect={true}/></RequireAuth>} />
    <Route path="/login" element={<Login />} />
    <Route path="document/:documentId" element={<RequireAuth><LoaderHook/></RequireAuth>} />
    <Route path="document/:documentId/section/:fragmentId" element={<RequireAuth><LoaderHook fragmentKind="section"/></RequireAuth>} />
    <Route path="document/:documentId/annex/:fragmentId" element={<RequireAuth><LoaderHook fragmentKind="annex"/></RequireAuth>} />
    <Route path="document/:documentId/table/:fragmentId" element={<RequireAuth><LoaderHook fragmentKind="table"/></RequireAuth>} />
    <Route path="document/:documentId/figure/:fragmentId" element={<RequireAuth><LoaderHook fragmentKind="figure"/></RequireAuth>} />
    <Route path="document/:documentId/fragment/:fragmentId" element={<RequireAuth><LoaderHook fragmentKind="fragment"/></RequireAuth>} />
    <Route path="document/:documentId/sentence/:fragmentId" element={<RequireAuth><LoaderHook fragmentKind="sentence"/></RequireAuth>} />
    <Route path="document/:documentId/hash/:fragmentId" element={<RequireAuth><LoaderHook fragmentKind="hash"/></RequireAuth>} />
    <Route path="compare/:thisId/:thatId" element={<RequireAuth><LoaderHook/></RequireAuth>} />
    <Route path="compare/:thisId/:thatId/section/:fragmentId" element={<RequireAuth><LoaderHook fragmentKind="section"/></RequireAuth>} />
    <Route path="compare/:thisId/:thatId/annex/:fragmentId" element={<RequireAuth><LoaderHook fragmentKind="annex"/></RequireAuth>} />
    <Route path="compare/:thisId/:thatId/table/:fragmentId" element={<RequireAuth><LoaderHook fragmentKind="table"/></RequireAuth>} />
    <Route path="compare/:thisId/:thatId/figure/:fragmentId" element={<RequireAuth><LoaderHook fragmentKind="figure"/></RequireAuth>} />
    <Route path="compare/:thisId/:thatId/fragment/:fragmentId" element={<RequireAuth><LoaderHook fragmentKind="fragment"/></RequireAuth>} />
    <Route path="compare/:thisId/:thatId/hash/:fragmentId" element={<RequireAuth><LoaderHook fragmentKind="hash"/></RequireAuth>} />
    <Route path="/guide" element={<Guide />} />
    <Route path="/qb" element={<RequireAuth><QBLoaderHook/></RequireAuth>} />
    <Route path="/qb/:qbStateString" element={<RequireAuth><QBLoaderHook/></RequireAuth>} />
    <Route path="/wiki/:entity/:role" element={<RequireAuth><WikiHook/></RequireAuth>} />
    <Route path="/rqb" element={<RequireAuth><RQBLoaderHook/></RequireAuth>} />
    <Route path="/rqb/:rQueryString" element={<RequireAuth><RQBLoaderHook/></RequireAuth>} />
    <Route path="/mentions/:Document/:Node/:Identifier" element={<RequireAuth><MentionsLoaderHook/></RequireAuth>} />
    <Route path="/area51" element={<RequireAuth><TableOfContents/></RequireAuth>} />
    
    </Routes>
    </BrowserRouter>
    </ThemeProvider>
    </StyledEngineProvider>
    </Provider>,
    document.getElementById('root')
);

//thatDoc={'29503-g60'}

//<QueryBrowser/>

function RequireAuth({children}) {
    const user = useSelector(state => state.user)

    if (user.username !== null && user.token !== null ) {
	return children;
    } else {
	return <Navigate to="/login" replace />
    }
}
