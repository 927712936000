import SparqlClient from 'sparql-http-client'
import ParsingClient from 'sparql-http-client/ParsingClient.js'

let serverURL = ''
if (process.env.NODE_ENV === 'production') {
    // In production mode, we use nginx's reverse proxy.
    // The real server URL is configured in sfproxy.conf
    serverURL = window.location.origin
}
else {
    // In development mode, we use an environment var to
    // configure the server URL (in the .env file).
    // This can be overridden with a .env.local file.
    serverURL = process.env.REACT_APP_JENA_URL
}

console.log('server URL',serverURL)

const endpointUrl = serverURL + '/effigy/sparql'
const client = new ParsingClient({endpointUrl})
const sclient = new SparqlClient({endpointUrl})

export async function query(queryStr) {
    return await client.query.select(queryStr)
}

export async function query_stream(queryStr) {
    return await sclient.query.select(queryStr)
}

