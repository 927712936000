import * as React from 'react';
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';

import { ECOLOR } from '../../javascript/colors'

import * as Utils from './TOCUtils'

import * as QBUtils from '../qb/QBUtils'

import { UnchangedTreeItem, NewTreeItem, ChangedTreeItem } from '../trees/CustomTreeItems'

import { TILabel } from '../trees/TreeItems'

import { Close, Open } from '../Icons'

export default function CompareTOC(props) {

    const [theseDocumentParts, setTheseDocumentParts] = React.useState([])
    const [thoseDocumentParts, setThoseDocumentParts] = React.useState([])

    const thisDoc = props.thisDoc
    const thatDoc = props.thatDoc

    //console.log(`TOC thisDoc = ${thisDoc} thatDoc = ${thatDoc}`)

    React.useEffect( () => {

	async function getDocumentParts(doc, setter) {
	    const parts = await Utils.fetchDocumentParts(doc)
	    setter(parts)
	}

	// THIS DOCUMENT
	if (thisDoc && theseDocumentParts.length === 0) {
	    getDocumentParts(thisDoc, setTheseDocumentParts)
	}

	// THAT DOCUMENT
	if (thatDoc && thoseDocumentParts.length === 0) {
	    getDocumentParts(thatDoc, setThoseDocumentParts)
	}

    }, [thisDoc, theseDocumentParts, thatDoc, thoseDocumentParts])

    if (!thisDoc || !thatDoc) {
	return null
    }
    
    if (!theseDocumentParts || theseDocumentParts.length === 0) {
	return null
    }

    if (thatDoc && thoseDocumentParts.length === 0) {
	return null
    }

    const thisMap = Utils.makeInitialMap(theseDocumentParts)
    const thatMap = Utils.makeInitialMap(thoseDocumentParts)

    return <CompareTOCTree thisDoc={thisDoc} thatDoc={thatDoc} theseDocumentParts={theseDocumentParts} thisMap={thisMap} thatMap={thatMap}/>

}


function CompareTOCTree(props) {

    // need this for the layout (i.e. dimensions)
    const user = useSelector(state => state.user)
    const [state, setState] = React.useState({ proxima: [], ahora: [], thisMap: props.thisMap, thatMap: props.thatMap })
    const navigate = useNavigate();
    const thisDoc = props.thisDoc
    const thatDoc = props.thatDoc
    // the state:
    //
    // ahora: the current rendered expanded
    // proxima: the next requested version of the expanded nodes ahora (requires a db fetch)
    // thisMap: maps section ids in thisDoc to { name: ..., children: ... }
    //      children being null indicates that the query has yet to happen.
    //      otherwise children is a list of ids.
    // thatMap: maps section ids in thatDoc to { name: ..., children: ... }
    //      children being null indicates that the query has yet to happen.
    //      otherwise children is a list of ids.
    //
    // we have proxima/ahora because the useEffect happens AFTER the render, so the
    // expansion actually takes two renders. ¯\_(ツ)_/¯

    const style = QBUtils.makeStyle(user)

    React.useEffect( () => {

	async function updateMap(docId, nodes) {
	    const nThisMap = await Utils.expandMap(thisDoc, nodes, state.thisMap)
	    const nThatMap = await Utils.expandMap(thatDoc, nodes, state.thatMap)
	    setState( { proxima: state.proxima, ahora: state.proxima, thisMap: nThisMap, thatMap: nThatMap } )
	}

	if (state.proxima.length > state.ahora.length) {
	    // a node is being expanded so we have to see if we need to get the children...
	    const nodes = Utils.nodesToFetchChildren(state.proxima, state.ahora, state.thisMap)
	    updateMap(thisDoc, nodes)
	} else if (state.proxima.length !== state.ahora.length) {
	    setState( { proxima: state.proxima,  ahora: state.proxima, thisMap: state.thisMap, thatMap: state.thatMap } )
	} 

    }, [ state, thisDoc, thatDoc ])

    
    const dummyTreeItem = (nid) => {
	const did = 'dummy' + nid
	return (<TreeItem key={did} nodeId={did} label={''}> </TreeItem>)
    }

    const capitalize =  (str) => {
	return str ? str.charAt(0).toUpperCase() + str.slice(1) : str
    }

    const makeLabel = (val) => {
	if (val.id === val.name) {
	    return capitalize(val.name)
	} else {
	    return `${val.id}: ${val.name}`
	}
    }

    const renderTree = (nid, handler, top) => {
	const thisVal = state.thisMap.get(nid)
	if ( !thisVal ) {
	    console.error(`${nid} has no value in the state map`, state.thisMap)
	}
	const thatVal = state.thatMap ? state.thatMap.get(nid) : null
	const label = top ? makeLabel(thisVal) : TILabel(makeLabel(thisVal), handler(nid))
	const children = Array.isArray(thisVal.children)
	      ? thisVal.children.map((node) => renderTree(node, handler, false))
	      : dummyTreeItem(nid)
	if (!state.thatMap) {
	    return (<UnchangedTreeItem key={nid} nodeId={nid} label={label}>{children}</UnchangedTreeItem>)
	} else if (!thatVal) {
	    return (<NewTreeItem key={nid} nodeId={nid} label={label}>{children}</NewTreeItem>)
	} else if (!top && thisVal.uid !== thatVal.uid) {
	    return (<ChangedTreeItem key={nid} nodeId={nid} label={label}>{children}</ChangedTreeItem>)
	} else {
	    return (<UnchangedTreeItem key={nid} nodeId={nid} label={label}>{children}</UnchangedTreeItem>)
	}
    }

    const handleToggle = (event, nodeIds) => {
	setState( { proxima: nodeIds, ahora: state.ahora, thisMap: state.thisMap, thatMap: state.thatMap  } )
    }

    const makeClickHandler = (id) => {
	    return () => {
		const url = `/compare/${thisDoc}/${thatDoc}/section/${id}`
	        console.log(`Navigating to ${url}`)
	        return navigate(url)
	    }
    }

    if (!props.theseDocumentParts || props.theseDocumentParts.length === 0) {
	return null
    }

    return (
	<Box sx={style}> 
	<TreeView
	    aria-label="controlled"
	    defaultCollapseIcon={<Close sx={{color: ECOLOR}} />}
	    defaultExpandIcon={<Open sx={{color: ECOLOR}} />}
            expanded={state.ahora}
            onNodeToggle={handleToggle}
	>
	    {renderTree('main', makeClickHandler, true)}
	    {renderTree('annexes', makeClickHandler, true)}
	</TreeView>
	</Box>
    )
}

