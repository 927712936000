import List from '@mui/material/List';
import { useSelector } from 'react-redux'

import CatalogueCollection from './CatalogueCollection'
import CatalogueEntry from './CatalogueEntry'



export default function Catalogue(props) {
    const builder = useSelector(state => state.network.builder)
    if (builder == null) {
	return null
    }
    const theLibrary = builder.getLibrary()

    const shelver = (ce) =>  {
	const releases = ce.releases
	if (releases.length > 1) {
	    return (<CatalogueCollection key={ce.uid + 'CC'} data={ce} closing={props.closing}/>)
	} else {
	    return (<CatalogueEntry key={ce.uid + 'CE'} data={ce} release={releases[0]} closing={props.closing}/>)
	}
    }

    if (theLibrary == null) {
	return null
    } else {
	return (
            <List dense={true} >
            { theLibrary.catalog().map(shelver) }
	    </List>
	)
    }
}
