import React  from 'react';

import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom'

import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import IconButton from '@mui/material/IconButton';

import { ECOLOR } from '../../javascript/colors'
import { EToolip } from '../EToolip'

import { server_environment } from '../../javascript/servers'


export default function RawQueryBrowserButton (props) {

    let navigate = useNavigate();

    const settings = useSelector(state => state.user.settings)
    const debug = settings ? settings.value('debug') : false

    const handleClick = (event) => {
	let url = `/rqb`
	console.log(`Navigating to ${url}`)
	return navigate(url)
    }

    if ( server_environment === 'prod' || !debug) {
	return null
    }

    return (
        <EToolip title="The Raw Jena Query Browser">
	<IconButton
        style={{ color: ECOLOR }}
        aria-label={'The raw Jena query browser for cross corpus queries'}
        component="span"
        onClick={handleClick}
        size="large">
	<QueryBuilderIcon />
	</IconButton>
	</EToolip>
    );



}
