import * as Types from '../constants/action-types';

import { stopPeriodicRefresh } from  '../javascript/network/auth'

const initialState = { catalogue: false, settings: null, dimensions: null, username: null, token: null, error: null }

function user(state = initialState, action) {
    //console.log('user', action)
    switch(action.type) {
	case Types.SET_CATALOGUE: return {...state, catalogue: action.payload}
	case Types.SET_DIMENSIONS: return {...state, dimensions: action.payload}
	case Types.SET_SETTINGS: return {...state, settings: action.payload }
	case Types.LOGIN_SUCCESS: return {...state, ...action.payload, error: null}
	case Types.LOGIN_ERROR: return {...state, ...action.payload }
	case Types.LOGOUT: {
	    stopPeriodicRefresh()
	    return {...state, username: null, token: null, error: null }
	}
	case Types.REFRESH_TOKEN: return {...state, ...action.payload }
	default: return state
    }
}

export default user
