import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import { ECOLOR } from '../javascript/colors'

export const toolTipDelay = 2000

export const EToolip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: ECOLOR,
      boxShadow: theme.shadows[1],
      fontSize: 12,
      maxWidth: 'none',
  },
}));
