import { useSelector } from 'react-redux'


import CompareTOC from './CompareTOC'
import TOC from  './TOC'

export default function TableOfContents() {

    const document = useSelector(state => state.portfolio.document)

    const other = useSelector(state => state.portfolio.other)

    if (document && other) {
	return (<CompareTOC thisDoc={document.nodeId} thatDoc={other.nodeId}/>)
    } else if (document) {
	return (<TOC thisDoc={document.nodeId}/>)
    } else {
	// this should be deleted once the dust settles.
	return (<CompareTOC thisDoc={'29503-h50'} thatDoc={'29503-g60'}/>)
    }
}
