import * as React from 'react';
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';

import { ECOLOR } from '../../javascript/colors'

import * as Utils from './TOCUtils'

import * as QBUtils from '../qb/QBUtils'

import { UnchangedTreeItem } from '../trees/CustomTreeItems'
import { TILabel } from '../trees/TreeItems'

import { Close, Open } from '../Icons'

export default function TOC(props) {

    const [documentParts, setDocumentParts] = React.useState([])

    const thisDoc = props.thisDoc

    React.useEffect( () => {

	async function getDocumentParts(doc) {
	    const parts = await Utils.fetchDocumentParts(doc)
	    setDocumentParts(parts)
	}
	
	if (thisDoc && documentParts.length === 0) {
	    getDocumentParts(thisDoc)
	    //console.log(documentParts)
	}


    }, [documentParts, thisDoc])

    if (!documentParts || documentParts.length === 0) {
	return null
    }

    return <TOCTree thisDoc={thisDoc} documentParts={documentParts} thisMap={Utils.makeInitialMap(documentParts)}/>

}


function TOCTree(props) {

    // need this for the layout (i.e. dimensions)
    const user = useSelector(state => state.user)

    const [state, setState] = React.useState({ proxima: [], ahora: [], map: props.thisMap })

    const navigate = useNavigate();

    const thisDoc = props.thisDoc
    
    // the state:
    //
    // ahora: the current rendered expanded
    // proxima: the next requested version of the expanded nodes ahora (requires a db fetch)
    // map: maps section ids to { name: ..., children: ... }
    //      children being null indicates that the query has yet to happen.
    //      otherwise children is a list of ids.
    //
    // we have proxima/ahora because the useEffect happens AFTER the render, so the
    // expansion actually takes two renders. ¯\_(ツ)_/¯

    const style = QBUtils.makeStyle(user)

    React.useEffect( () => {

	async function updateMap(docId, nodes) {
	    const nMap = await Utils.expandMap(docId, nodes, state.map)
	    setState( { proxima: state.proxima, ahora: state.proxima, map: nMap } )
	}

	if (state.proxima.length > state.ahora.length) {
	    // a node is being expanded so we have to see if we need to get the children...
	    const nodes = Utils.nodesToFetchChildren(state.proxima, state.ahora, state.map)
	    updateMap(thisDoc, nodes)
	} else if (state.proxima.length !== state.ahora.length) {
	    setState( { proxima: state.proxima,  ahora: state.proxima, map: state.map } ) 
	} 

    }, [ state, thisDoc ])

    
    const dummyTreeItem = (nid) => {
	const did = 'dummy' + nid
	return (<TreeItem key={did} nodeId={did} label={''}> </TreeItem>)
    }

    const capitalize =  (str) => {
	return str ? str.charAt(0).toUpperCase() + str.slice(1) : str
    }

    const makeLabel = (val) => {
	if (val.id === val.name) {
	    return capitalize(val.name)
	} else {
	    return `${val.id}: ${val.name}`
	}
    }
    
    const renderTree = (nid, handler, top) => {
	const val = state.map.get(nid)
	if ( !val ) {
	    console.error(`${nid} has no value in the state map`, state.map)
	}
	const label = top ? makeLabel(val) : TILabel(makeLabel(val), handler(nid))
	return (
	    <UnchangedTreeItem key={nid} nodeId={nid} label={label}>
	    {Array.isArray(val.children)
	     ? val.children.map((node) => renderTree(node, handler, false))
	   : dummyTreeItem(nid) }
	    </UnchangedTreeItem>
	)
    }

    const handleToggle = (event, nodeIds) => {
	setState( { proxima: nodeIds, ahora: state.ahora, map: state.map  } )
    }

    const makeClickHandler = (id) => {
	    return () => {
		const url = `/document/${thisDoc}/section/${id}`
	        console.log(`Navigating to ${url}`)
	        return navigate(url)
	    }
    }

    if (!props.documentParts || props.documentParts.length === 0) {
	return null
    }

    return (
	<Box sx={style}> 
	<TreeView
	    aria-label="controlled"
	    defaultCollapseIcon={<Close sx={{color: ECOLOR}} />}
	    defaultExpandIcon={<Open sx={{color: ECOLOR}} />}
            expanded={state.ahora}
            onNodeToggle={handleToggle}
	>
	    {renderTree('main', makeClickHandler, true)}
	    {renderTree('annexes', makeClickHandler, true)}
	</TreeView>
	</Box>
    )
}

