import OneShotTreeBuilder from './tree_builder.js'
import * as NS from '../../javascript/namespaces'

export const ENTITY_NS = NS.ENTITY

export default class EntityTreeBuilder extends OneShotTreeBuilder {

    topClass = `${ENTITY_NS}EntityProcessorSentenceResult`

}
