import React  from 'react';

import { useNavigate } from 'react-router-dom'


import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';

import { ECOLOR } from '../../javascript/colors'

import { EToolip } from '../EToolip'


export default function UserGuide(props) {

    let navigate = useNavigate();

    const handleChange = (event) => {
	const url = '/guide'
	console.log(`Navigating to ${url}`)
	if (props.closing) { props.closing() }
	return navigate(url)
    }

	return (
        <EToolip title="User Guide">
	    <IconButton
            style={{ color: ECOLOR }}
            aria-label="Launch the user guide in a new tab"
            component="span"
            onClick={handleChange}
            size="large">
	    <InfoIcon/>
	    </IconButton>
	    </EToolip>
    );

}
