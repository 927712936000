import { Component } from 'react';
import { connect } from "react-redux";

import { setBusy, setDimensions, setDocument, setBuilder, setOther, setSettings, setFragment  } from "../actions/index";

import { Doc }  from '../javascript/DOC'

import Home from './Home'

function mapDispatchToProps(dispatch) {
    return {
	setBusy: bool => dispatch(setBusy(bool)),
	setBuilder: tbuilder => dispatch(setBuilder(tbuilder)),
	setDimensions: dimensions => dispatch(setDimensions(dimensions)),
	setDocument: document => dispatch(setDocument(document)),
	setOther: other => dispatch(setOther(other)),
	setFragment: fragment => dispatch(setFragment(fragment)),
	setSettings: settings => dispatch(setSettings(settings))
    };
}

const mapStateToProps = state => {
    return {
	cache: state.network.cache,
	builder: state.network.builder,
	document: state.portfolio.document,
	other: state.portfolio.other,
	busy: state.network.busy,
	username: state.user.username,
	settings: state.user.settings
    }
}

class ConnectedLoader extends Component {

    constructor(props) {
	super(props)
	this.currentDocumentId = null
	this.otherDocumentId = null
	this.fragmentId = null
	this.fragmentKind = null
        this.debug = this.props.settings && this.props.settings.value('debug')

    }


    componentDidMount() {
	if (this.debug) console.log("Loader.componentDidMount ", this.props.documentId);
    }

    componentDidUpdate() {
	if (this.debug) console.log(">Loader.componentDidUpdate ", this.props.documentId);
	const searchParams=this.props.searchParams
	const setSearchParams=this.props.setSearchParams
	const reload = searchParams.get("reload") === 'true'
	// has something changed?
	if ((this.currentDocumentId !== this.props.documentId) ||
	    (this.otherDocumentId !== this.props.otherId)      ||
	    (this.fragmentId !== this.props.fragmentId)        ||
	    reload) {
	    if (this.debug) {
		console.log(`LOADING documentId=${this.props.documentId} fragmentId=${this.props.fragmentId} otherId=${this.props.otherId} (reload=${reload})`);
	    }

	    if (reload) {
		// Might be better to do this a param rather than a search Param, but for now ¯\_(ツ)_/¯
		// we turn off the reload once we see it, otherwise we would repeatedly try to reload.
		// we also replace the ?reload=true by ?reload=false in the history so the back button
		// does not cause a reload.
		setSearchParams({reload: 'false'}, {replace: true})
	    }
	    if (this.otherDocumentId !== this.props.otherId) {
		// need to reset
		this.props.setOther(null)
	    }
	    // record the current state; so we don't come down this branch again
	    this.currentDocumentId = this.props.documentId
	    this.otherDocumentId = this.props.otherId
	    this.fragmentId = 	this.props.fragmentId
	    this.fragmentKind = this.props.fragmentKind
	    // package up the fragment information
	    let fragmentObj = (this.fragmentKind && this.fragmentId) ? { id: this.fragmentId, kind: this.fragmentKind } : null

	    this.loadDocument(this.props.documentId, reload === true, this.props.otherId, fragmentObj)
	}
	if (this.debug) console.log("<Loader.componentDidUpdate ", this.props.documentId);
    }

    loadDocument(documentId, reload, otherId, fragmentObj){
	let dname = documentId
	let oname = otherId
	//console.log(`loadDocument(${dname}, ${reload}, ${oname}, ${fragmentObj})`)
	if(reload){
	    // reloads never have additional requirements
	    this._reload(dname)
	} else {
	    let document = this.props.document
	    // are we are moving around the current document
	    if (document && dname === document.root()) {
		if (this.debug) {
		    console.log('SKIPPING SETTING DOC')
		}
		this._loadRest(document, oname, fragmentObj)
		return
	    }
	    // we are loading another document; so we reset
	    if (document) {
		this.props.setDocument(null);
	    }

	    document = this.props.cache.fetch(dname)

	    if (document) {
		if (this.debug) {
		    console.log('SETTING DOC FROM CACHE')
		}
                this.props.setDocument(document);
		// get and set the fragment corresponding to the section id
		this._loadRest(document, oname, fragmentObj)
	    } else {
		this._load(dname, oname, fragmentObj)
	    }
	}
    }


    _loadRest(document, oname, fragmentObj) {
	//console.log(`_loadRest(..., ${oname}, ${fragmentObj})`)
	if(oname) {
	    let other = this.props.cache.fetch(oname)
	    if (other) {
		this.props.setOther(other);
 		// get and set the fragment corresponding to the fragmentObj
		this.setFragmentObject(document, fragmentObj)
	    } else {
		this.props.setBusy(true)
		other = new Doc(this.props.builder, oname, this.props.settings)
		other.load().then(x =>  {
		    this.props.setOther(other)
		    this.props.cache.update(other)
 		    // get and set the fragment corresponding to the fragmentObj
		    this.setFragmentObject(document, fragmentObj)
		    this.props.setBusy(false)
		})
	    }
	}
	// get and set the fragment corresponding to the fragmentObj
	this.setFragmentObject(document, fragmentObj)
    }

    _load(dname, oname, fragmentObj){
        this.props.setBusy(true)
        const document = new Doc(this.props.builder, dname, this.props.settings)
        document.load().then(x =>  {
	    if (this.debug) {
		console.log('SETTING DOC FROM NETWORK')
	    }
	    this.props.setDocument(document)
	    this.props.cache.update(document)
            this.props.setBusy(false)
	    // do the rest of the work
	    this._loadRest(document, oname, fragmentObj)
        })
    }

    _reload(dname){
	this.props.setDocument(null)
	this._load(dname)
    }

    setFragmentObject(document, fragmentObj) {
	if (fragmentObj) {
	    const id = fragmentObj.id
	    const kind = fragmentObj.kind
	    const fragment = document.resolveFragmentFromId(id, kind)
	    if (fragment) {
		this.props.setFragment(fragment)
	    } else {
		console.log(`setFragmentObject ${kind} with ${id} NOT FOUND in document ${document.nodeId}`)
	    }
	} else {
	    // hopefully returning to the top level
	    if (this.debug) {
		console.log('At top level')
	    }
	    this.props.setFragment(null)
	}
    }


    render() {
	return (<Home />);
    }

}

const Loader = connect(mapStateToProps, mapDispatchToProps)(ConnectedLoader);

export default Loader;
