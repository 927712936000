import SourceTreeBuilder from './network/source_tree_builder'

import  { Library } from './Library'

import { rdfId2jsonId } from './utils'


// encapsulates our interactions with sparql/sunflower for now.
export default class Builder {

    constructor() {

        //<source tree stuff>
        this.sourceTreeBuilder = new SourceTreeBuilder()
        this.documentMetaData = null
        this.documentNames = null
        this.name2CatalogueEntry = {}
        this.library = null
        //<source tree stuff>

    }

    async load() {
        this.documentMetaData = await this.sourceTreeBuilder.getDocumentMetadata()
        this.documentNames = this.documentMetaData.map((ce) => { let name = rdfId2jsonId(ce.doc); this.name2CatalogueEntry[name] = ce; return name})
	// to see the metadata coming down the pipe...
        //this.documentMetaData.forEach((ce) => { console.log(rdfId2jsonId(ce.doc), ce) })
        this.documentNames.sort()
        this.library = this.getLibrary()
        console.log(`The number of available documents: ${this.documentNames.length}`)
    }

    sourceGraph(name) {
        let graph = this.name2CatalogueEntry[name].srcGraph
        return  graph ? graph : null
    }

    entityGraph(name) {
        let graph = this.name2CatalogueEntry[name].entGraph
        return graph ? graph : null
    }

    cstGraph(name) {
        let graph = this.name2CatalogueEntry[name].cstGraph
        return graph ? graph : null
    }


    async constructJSON(nodeName, debug=true){
        const dId = this.sourceGraph(nodeName)
        if (debug) {
            console.log(`Calling sourceTreeBuilder.load(${dId})`)
        }
        await this.sourceTreeBuilder.load(dId)
        if (debug) {
            console.log(`Returned from sourceTreeBuilder.load(${dId}), constructing json`)
        }
        let entry = this.getCatalogueEntry(nodeName)
        let json = this.sourceTreeBuilder.constructTree(entry.doc)
        return json
    }

    getCatalogueMap() {
        return this.name2CatalogueEntry
    }

    getCatalogueEntry(name) {
	return this.name2CatalogueEntry[name]
    }

    getDocumentNames() {
        return this.documentNames
    }

    getLibrary() {
        if (this.library) {
            return this.library
        }
        // make it and cache it
        this.library = new Library(this)
        return this.library;
    }

    shortName(name) {
        return name.split('-')[0]
    }


}
