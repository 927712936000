import { v4 as getUUID } from 'uuid';

// a data structure that may be useful for rendering a "Table Of Contents"

export class TOCNode {

    constructor(data) {
        this.id = getUUID();
        this.rank = data.id;
        this.children = [];
        this.title = data.name;
        this.data = data;
    }

    label() {
	if (this.rank === this.title) {
	    return this.title;
	} else {
	    return this.rank + ": " + this.title;
	}
    }

    empty() {
	return this.children.length === 0
    }
}

export class TOC {

    constructor(data) {
	// an ordered list of the top-level TOC nodes
        this.index = []
	// maps section id to the toc node of that section
        this.mapping = {}

        this.initialize(data, true)
    }


    hasSection(id) {
	return !!this.mapping[id]
    }

    node4Section(id) {
	return this.mapping[id]
    }

    initialize(x, toplevel) {
        if (!x) {
            return;
        }
        const nt = x['node_type'];
        switch(nt) {
            case 'document': {
                x['document_parts'].map((d) => this.initialize(d, toplevel)); return null;
            }
            case 'document_part':  {
		x['subsections'].map((d) => this.initialize(d, toplevel)); 
		return null;
	    }
            case 'section':
                const node = new TOCNode(x)
                if (toplevel) {
                    this.index.push(node);
                }
                this.mapping[x.id] = node;
                node.children.push(...x['subsections'].map((d) => this.initialize(d, false)));
                return node;
                // anything smaller than a section can be ignored for now.
            default: return null;
        }
    }

}


