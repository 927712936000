import React  from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom'

import DangerousIcon from '@mui/icons-material/Dangerous';
import IconButton from '@mui/material/IconButton';

import { ECOLOR } from '../../javascript/colors'
import { EToolip } from '../EToolip'



export default function IansButton (props) {

    let navigate = useNavigate();

    const user = useSelector(state => state.user)

    const debug = user.settings ? user.settings.value('debug') : false


    const handleClick = (event) => {
	let url = `/area51`
	console.log(`Navigating to ${url}`)
	return navigate(url)
    }

    if (user.username !== 'iam' || !debug) {
	return null
    }

    return (
        <EToolip title="Ian's Button">
	<IconButton
        style={{ color: ECOLOR }}
        aria-label={'A button for enabling experimental features'}
        component="span"
        onClick={handleClick}
        size="large">
	<DangerousIcon />
	</IconButton>
	</EToolip>
    );



}
