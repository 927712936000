import React from 'react';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom'

import { v4 as getUUID } from 'uuid';

import ReactHtmlParser from 'react-html-parser';


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ImageIcon from '@mui/icons-material/Image';
import { red } from '@mui/material/colors';

import { ECOLOR } from '../../javascript/colors'
import { unchanged } from '../../javascript/utils'

const mapStateToProps = state => {
    return {
	    document: state.portfolio.document,
	    other: state.portfolio.other,
	    settings: state.user.settings,
    };
}

function lofNode2ListItem(node, parentage, handler, other){
    const uid = getUUID();
    const redc = red[600]
    const fixed = unchanged(node.data, other)
    const textColor = { color: fixed ? "black" : redc }
    const label = <> <b>{ReactHtmlParser(node.id)}:</b> &nbsp; {node.caption()} </>
    const icolor = fixed ? ECOLOR : redc
    return (
	    <ListItem button key={uid} onClick={handler(node.data)}>
	    <ListItemIcon style={{ color: icolor }}> <ImageIcon />  </ListItemIcon>
	    <ListItemText primaryTypographyProps={{ style: textColor }}  primary={label}/>
	    </ListItem>
    );
}


function ConnectedListOfFigures(props) {

    // no longer zoomOrScrolling
    let navigate = useNavigate();

    const other = props.other;

    const makeClickHandler = (fragment) => {
	    return () => {
	        const url = props.document.node2LocalURL(fragment, other)
	        console.log(`Navigating to ${url}; other=${other ? other.nodeId : null}`)
	        return navigate(url)
	    }
    }

    const document = props.document;
    if (!document || !document.hasFigures()) {
        return null;
    }
    const parentage = document.parentage()
    const lof = document.listOfFigures();
    return (
        <List dense={true} key={getUUID()}>
        { lof.list.map((n) => lofNode2ListItem(n, parentage, makeClickHandler, other)) }
        </List>
    );
}


const ListOfFigures = connect(mapStateToProps, null)(ConnectedListOfFigures);

export default ListOfFigures;
