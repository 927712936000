import React,  { Component } from 'react';
import { connect } from "react-redux";
import IconButton from '@mui/material/IconButton';
import ReportIcon from '@mui/icons-material/Report';
import { EToolip } from './EToolip'

const mapStateToProps = state => {
    return { 
	settings: state.user.settings,
    };
}

export function si_header(inode) {
    return si_nt_name(inode.data.node_type)
}

export function si_nt_name(nt) {
    switch (nt) {
	case 'unknown_list_symbol': return 'Unknown List Symbol';
	case 'unexpected_list_numbering': return 'Unexpected List Numbering';
	case 'list_level_skip': return 'List Level Skip';
	case 'semantic_list_level_mismatch': return 'Semantic List Level Mismatch';
	case 'manual_fix': return 'Manual Fix';
	case 'inconsistent_section_level': return 'Inconsistent Section Level';
	case 'section_level_skip': return 'Section Level Skip';
	case 'note_label': return 'Note Label';
	case 'missing_caption': return 'Missing caption';
	case 'reference_format': return 'Reference Format';
	case 'no_abbreviation_term': return 'No Abbreviation Term';
	case 'potential_section_header': return 'Potential Section Header';
	case 'resume_on_fixed_level': return 'Resume on Fixed Level';
	default: {
	    const msg = `Unexpected structure issue node type (name): ${nt}`
	    console.error(msg)
	    return msg
	}
    }
}

export function nt_name(nt) {
    switch(nt){
	case 'document': return 'Document'
	case 'document_part': return 'Document Part'
	case 'section': return 'Section'
	case 'paragraph': return 'Paragraph'
	case 'sentence': return 'Sentence'
	case 'figure': return 'Figure'
	case 'table': return 'Table'
	case 'code': return 'Code'
	case 'note': return 'Note'
	case 'glossary_entry': return 'Glossary Entry'
	case 'abbreviation_entry': return 'Abbreviation Entry'
	case 'reference': return 'Reference'
	case 'list': return 'List'
	case 'list_item': return 'List Item'
	default: {
	    const msg = `Unexpected source schema node type: ${nt}`
	    console.error(msg)
	    return msg
	}
    }
}

//console.log(inode); 

function si_description(nt) {
    switch (nt) {
	case 'unknown_list_symbol': 
	    return "Unknown list symbol: %symbol";
	case 'unexpected_list_numbering': 
	    return "Current list symbol is %symbol, expected predecessor %predecessor, but last symbols per level are %last_symbols (list item: '{s}')"
	case 'list_level_skip': 
	    return "Structurally inconsistent list formatting, skipped from previous level %previous_level to current level %this_level. Fixed current level to %fixed_level."
	case 'semantic_list_level_mismatch': 
	    return "Semantically inconsistent list formatting level, current item is on level %formatted_level and has symbol %symbol, but predecessor %predecessor appeared on level %semantic_level. Fixed current level to %semantic_level."
	case 'manual_fix': 
	    return "Identified and manually fixed problem: %details"
	case 'inconsistent_section_level': 
	    return "'Semantic' section level %semantic_level (based on naming pattern) doesn't match formatted section level %formatted_level section level, using semantic level.";
	case 'section_level_skip': 
	    return "Skipped from previous section level %previous_level to current level %this_level. Fixed current level to %fixed_level.";
	case 'missing_caption': 
	    return "Table or figure is missing a caption";
	case 'note_label': 
	    return "Note doesn't have expected label ('Note number:')";
	case 'reference_format': 
	    return "Unexpected reference format"
	case 'no_abbreviation_term': {
	    return "No abbreviation term given for abbreviation entry. Assuming that this is another definition for previous abbreviation %abbreviation.";
	}
	case 'potential_section_header': 
	    return "This appears to be a section header even though it is not formatted as one. Assuming that this is a section header."
	case 'resume_on_fixed_level':
	    return "List item is formatted on level %this_level, but a fix from a previously processed list item mapped this to level to %fixed_level."
	default: {
	    const msg = `Unexpected structure issue node type (description): ${nt}`
	    console.error(msg)
	    return msg
	}
    }
}

function si_body(inode) {
    const si = inode.data
    const nt = si.node_type
    const description = si_description(nt)
    switch (nt) {
	case 'unknown_list_symbol': 
	    return description.replace('%symbol', si.symbol);
	case 'unexpected_list_numbering': 
	    //console.log('si.last_symbols', si.last_symbols, typeof(si.last_symbols))
	    return description.replace('%symbol', si.symbol).replace('%predecessor', si.predecessor).replace('%last_symbols', si.last_symbols);
	case 'list_level_skip': 
	    return description.replace('%previous_level', si.previous_level).replace('%this_level', si.this_level).replace('%fixed_level', si.fixed_level);
	case 'semantic_list_level_mismatch': 
	    return description.replace('%formatted_level',si.formatted_level).replace('%symbol',si.symbol).replace('%predecessor',si.predecessor).replaceAll('%semantic_level',si.semantic_level)
	case 'manual_fix': 
	    return description.replace('%details', si.details);
	case 'inconsistent_section_level': 
	    return description.replace('%semantic_level',si.semantic_level).replace('%formatted_level',si.formatted_level);
	case 'section_level_skip': 
	    return description.replace('%previous_level',si.previous_level).replace('%this_level',si.this_level).replace('%fixed_level',si.fixed_level);
	case 'missing_caption': 
	    return description;
	case 'note_label': 
	    return description;
	case 'reference_format': 
	    return description;
	case 'no_abbreviation_term': {
	    //console.log('inode parent', inode.parent);
	    return description.replace('%abbreviation', si.abbreviation);
	}
	case 'potential_section_header': 
	    return description;
	case 'resume_on_fixed_level':
	    return description.replace('%this_level', si.this_level).replace('%fixed_level', si.fixed_level);
	default: {
	    const msg = `Internal error: Unexpected structure issue node type ${si.node_type}`
	    console.error(msg)
	    return msg
	}
    }
}


class ConnectedIssue extends Component {
    render() {
	const issues = this.props.settings.value('issues')
	const inode = this.props.issue
	if (!issues || !inode ) {
	    return null;
	}

	let i_header = si_header(inode)
	let i_body = si_body(inode)
	let tt_content = 
		<React.Fragment>
			<em>{i_header}</em>
			<p>{i_body}</p>
		</React.Fragment>

	return (
            <EToolip disableFocusListener disableTouchListener title={tt_content}>
	    <IconButton color="primary" aria-label="display the issue encountered here" component="span" size="small">
            <ReportIcon fontSize="small" color="secondary"/>
            </IconButton>
  	    </EToolip>
	);
    }
}

const Issue = connect(mapStateToProps)(ConnectedIssue);

export default Issue;

