import React  from 'react';
import { connect } from "react-redux";

import Card from 'react-bootstrap/Card'

import * as Ctrl from '../constants/controls';

import DocumentMetaData from './DocumentMetaData'
//import TableOfContents from './TableOfContents'
import TableOfContents from './toc/TableOfContents'
import ListOfFigures from './lists/ListOfFigures'
import ListOfTables from './lists/ListOfTables'
import ListOfIssues from './trees/ListOfIssues'
import IssueSummary from './trees/IssueSummary'
import ConcreteSyntaxTrees from './trees/ConcreteSyntaxTrees'
import ListOfEntityAnnotations from './lists/ListOfEntityAnnotations'
import EntityIndex from './trees/EntityIndex'

import { controlsDimensions } from '../javascript/Realty'


const mapStateToProps = state => {
    return {
	settings: state.user.settings,
	dimensions: state.user.dimensions,
	control: state.portfolio.control,
    };
}

export class ConnectedControls extends React.Component {


    control() {
	const settings = this.props.settings
	const issues = settings ? settings.value('issues') : false
	const entities = settings ? settings.value('entities') : false
	const cst = settings ? settings.value('cst') : false

	switch(this.props.control) {
	    case Ctrl.MD_CONTROL: return <DocumentMetaData />
	    case Ctrl.TOC_CONTROL: return <TableOfContents />
	    case Ctrl.LOF_CONTROL: return <ListOfFigures />
	    case Ctrl.LOT_CONTROL: return <ListOfTables />
	    case Ctrl.IS_CONTROL: return issues ? <IssueSummary /> : null
	    case Ctrl.LOI_CONTROL: return issues ? <ListOfIssues /> : null
	    case Ctrl.CST_CONTROL: return cst ? <ConcreteSyntaxTrees /> : null
	    case Ctrl.LOE_CONTROL: return entities ? <ListOfEntityAnnotations />  : null
	    case Ctrl.EI_CONTROL: return  entities ? <EntityIndex />  : null
	    case null: return null
	default:
		console.error(`Unexpected control: ${this.props.control}`)
		return null
	}
    }

    render() {
	const child = this.control()
	const dimensions = controlsDimensions(this.props.dimensions)
	//console.log(`Controls: height = ${dimensions.height}`)
	const style = { margin: '1rem', maxHeight: dimensions.height, overflow: 'auto' }
	if (child) {
	    return (
		<Card border="success" style={style}>
		<Card.Body>
		{child}
		</Card.Body>
		</Card>
	    )
	} else {
	    return null;
	}
    }
}

const Controls = connect(mapStateToProps)(ConnectedControls);

export default Controls;
