import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';

// these used to be simple paths to ../../public/ but with routes that doesn't work.
import sunflower_logo from '../images/sunflower.png'
import sri_logo from '../images/sri.jpg'


const useStyles = makeStyles((theme) => ({
    root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  spinner: {
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
}));


export function SunflowerSpinner(props) {
    const spin = props.spin
    const classes = useStyles();
    if (spin) {
	return (
	    <div className={classes.root}>
	    <div className={classes.wrapper}>
	    <img src={sunflower_logo} width="30" height="30" alt="Sunflower logo" />
	    {<CircularProgress size={40} className={classes.spinner} style={{'color': 'orange'}} />}
	    </div>
	    </div>
	);
    } else {
	return (
	    <img src={sunflower_logo} width="30" height="30" className="d-inline-block align-top" alt="Sunflower logo" />
	);
    }
}

export function SRISpinner(props) {
    const spin = props.spin
    const classes = useStyles();
    if (spin) {
	return (
	    <div className={classes.root}>
	    <div className={classes.wrapper}>
	    <img src={sri_logo} alt="SRI logo" width="30" height="30"/>
	    <CircularProgress size={40} className={classes.spinner} />
	    </div>
	    </div>
	);
    } else {
	return (
	    <img src={sri_logo} alt="SRI logo"  width="30" height="30"/>  
	);
    }
}
