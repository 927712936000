/* For dolling out space to the widgets */

const defaultSize = 800

const defaultDimensions = { width:  defaultSize, height: defaultSize }

const navbar = 100

const footer = 60

const controls = 220

const tabs = 80


export function documentViewDimensions(dimensions, debug) {
    const padding = debug ? 100 : 160
    return { width: dimensions ? dimensions.width : defaultSize, height: dimensions ? dimensions.height - padding  : defaultSize }
}


export function controlsDimensions(dimensions) {
    return { width: dimensions ? dimensions.width : defaultSize , height: 200 }
}


export function documentDimensions(dimensions, control, compare, debug) {
    // control: means we have a control visible
    // compare: means we are in compare mode, so we have tab headers taking up space
    // debug: means we are in debug mode so clt doesn't want the footer to take up space on her postage stamp.
    if (!dimensions) {
	return defaultDimensions
    }

    let complement = navbar
    if (!debug) {
	complement += footer
    }
    if (control) {
	complement += controls
    }
    if (compare) {
	complement += tabs
    }
    //console.log(control, compare, debug, complement, dimensions.height - complement)
    return { width: dimensions.width, height: dimensions.height - complement }
}
