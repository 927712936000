/*
 *
 * Settings are stored in a dictionary this.data
 * This is written out to the item user in local storage
 *
 */


const VERBOSE = false

const DEFAULT_SETTINGS = { }


export default class Settings {
    // these are the things whose values we store and retrieve from local storage.
    slots = ['issues', 'cst', 'entities', 'debug']   //iam: groan, not very consistent with the plurals (no biscuit)

    constructor(other, username=null) {
	if (VERBOSE) { console.log(`new Settings(${other}, ${username})`) }
        this.user = username
        if (!other) {
            this.data = {}
            this.load()
        } else {
            this.data = other.data
        }
    }
    
    toggle(slot) {
	const newValue = !this.data[slot]
        this.data[slot] = newValue
        this.save()
	return newValue
    }

    assign(slot, value) {
        this.data[slot] = value ? value : false
    }

    value(slot) {
	const value = this.data[slot]
        return value ? value : false
    }

    save() {
        const data = {}
        this.slots.forEach((slot) => data[slot] = this.value(slot))
        if (this.user) {
            localStorage.setItem(this.user, JSON.stringify(data))
	    if (VERBOSE) { console.log('settings.save()') }
        }
    }

    load() {
        let data = DEFAULT_SETTINGS
        if (this.user) {
            const stored = localStorage.getItem(this.user)
	    if (VERBOSE) { console.log(`Stored settings: ${stored}`) }
            if (stored) {
                try {
                    data = JSON.parse(stored)
                } catch(e) {
                    console.error(`Settings failed to parse: ${stored}`, e)
                }
            }
        }
        this.slots.forEach((slot) => this.assign(slot, data[slot]))
    }

    toString() {
	let retval = ''
	for (let entry = 0; entry < this.slots.length; entry++){
	    retval += `\t${this.slots[entry]}: ${this.value(this.slots[entry])}\n`
	}
	return retval
    }


}
