import { ApiClient, AuthenticationServiceApi } from '@sricsl/auth-client'
import { TOKEN_REFRESH_MIN } from './constants'


// Singleton API client
let apiClient = new ApiClient()
if (process.env.NODE_ENV === 'production') {
  // In production mode, we use nginx's reverse proxy.
  // The real server URL is configured in sfproxy.conf
  apiClient.basePath = '';
}
else {
  // In development mode, we use an environment var to
  // configure the server URL (in the .env file).
  // This can be overridden with a .env.local file.
  let authUrl = process.env.REACT_APP_AUTH_URL;
  apiClient.basePath = authUrl;
}

console.log('Set Auth service URL to ',apiClient.basePath)
apiClient.timeout = 10 * 1000 // 10 sec
apiClient.enableCookies = true
let Auth = new AuthenticationServiceApi(apiClient)

export async function login(username, password, callback=null) {
    const token = await Auth.login({username, password})
    setToken(Auth.apiClient, token)
    startPeriodicRefresh(callback)
    return token
}

export async function refresh() {
    await Auth.refreshToken()
}

// Variable for the interval timer
let timer;

// callback used to update the redux state
function startPeriodicRefresh(callback=null){
  timer = setInterval( ()=>{
    Auth.refreshToken()
      .then( (data) => {
          console.log('Refreshing token: ', data);
	  //keep the redux state upto date because reasons.
	  if (callback !== null) {
	      callback(data)
	  }
          setTokens(data)
      })
      .catch( (error) => {
        console.error('Error refreshing token: ', error)
      });
  }, TOKEN_REFRESH_MIN * 60 * 1000)
}

export function stopPeriodicRefresh(){
  clearInterval(timer)
}

function setToken(apiClient, token) {
  let auth = apiClient.authentications['bearerAuth']
  auth.accessToken = token
}

function setTokens(token) {
    setToken(Auth.apiClient, token)
}
