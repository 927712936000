import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import HomeButton from '../buttons/HomeButton'
import UserGuide from '../buttons/UserGuide'

import { ECOLOR } from '../../javascript/colors'

import { innerHtml } from '../../javascript/utils'

export default function NavigationBar(props) {

    const header = props.header
    const style = { color: ECOLOR, backgroundColor: 'white', ...props.style }

    return (
	<AppBar position="static" style={style}>
	<Toolbar >
	<HomeButton/>
	<Typography variant="h6" color="inherit" component="div" sx={{ flexGrow: 1 }}>
	{innerHtml(header)}
	</Typography>
	<UserGuide />
	</Toolbar>
	</AppBar>
    )

}
