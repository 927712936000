import { useEffect, useState } from 'react'

import { renderFragment } from './DocumentUtils';

export default function useScrollHook(data, currentCount, document, other, debug) {
    const [rendering, setRendering] = useState(false)
    const [items, setItems] = useState([])
    const [hasMore, setHasMore] = useState(false)

    // if the data we are scrolling through changes we start from scratch
    useEffect(() => {
	setItems([])
    }, [data])


    useEffect( () => {
	setRendering(true)
	if (currentCount < data.length && items.length < currentCount) {
	    // render the next element in the data stream array
	    const newDOMElement = renderFragment(data[currentCount], document, other, debug)
	    setItems( previousItems => [ ...previousItems, newDOMElement])
	}
	setHasMore(currentCount + 1 < data.length)
	setRendering(false)
    }, [data, currentCount, items, document, other, debug])


    return { rendering, items, hasMore }
}
