import React, { Fragment, useState, useRef, useCallback, useEffect} from 'react'
import useScrollHook from './useScrollHook'

//https://www.youtube.com/watch?v=NZKUirTtxcg
//https://github.com/WebDevSimplified/React-Infinite-Scrolling

export default function DocumentScroller (props) {

    const { data, document, other, debug } = props

    const [ count, setCount ] = useState(0) 

    // reset if the data we are scolling through changes
    useEffect( () => {
	setCount(0)
    }, [data, document, other, debug])

    // use the hook to get the current state of the scrolling
    const { rendering, items, hasMore } = useScrollHook(data, count, document, other, debug)

    // this will be a used to observe when our footer comes into view
    const observer = useRef()

    // get a reference to the footer element in our DOM
    const footerRef = useCallback((node) => {
	// if we are rendering just bail
	if (rendering)   return
	// get rid of the previous observer
	if (observer.current) observer.current.disconnect()
	// set the action when we our footer becomes visible
	observer.current = new IntersectionObserver(entries => {
	    if (entries[0].isIntersecting && hasMore) {
		setCount(prevCount => prevCount + 1)
	    }
	})
	// now observe our new footer element
	if (node) observer.current.observe(node)
    }, [hasMore, rendering])

    const footer = hasMore ? <div ref={footerRef} key={`LoadMore${count}`}>{'Loading...'}</div> : <span key={`NoMas${count}`}></span>

    return (
	<Fragment>
	    {items}
	    {footer}
	</Fragment>
    )

}
