import * as React from 'react';
import { connect } from "react-redux";

import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@mui/material/IconButton';
import { ECOLOR } from '../../javascript/colors'

import { dumpLoadTimes } from '../../javascript/DOC'

import Settings from '../../javascript/Settings'

import { setSettings, setControl } from "../../actions/index";

import { allControls } from "../../constants/controls"

import { EToolip } from '../EToolip'

import SettingsDialog from '../SettingsDialog'

const mapStateToProps = state => {
    return {
	    user: state.user,
	    control: state.portfolio.control,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setSettings: settings => dispatch(setSettings(settings)),
        setControl: ctrl => dispatch(setControl(ctrl)),
    };
}

class ConnectedSettingsButton extends React.Component {

    constructor (props) {
        super(props);
        this.toggleOpen = this.toggleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
    	this.state = { open: false }
    }

    toggleOpen(event) {
	    this.setState({ open: !this.state.open })
    }

    handleClose(event) {
	    this.setState({ open: false })
    }

    handleChange = (event) => {
	    const settings = new Settings(this.props.user.settings, this.props.user.username);
	    const ctrl = this.props.control
	    settings.toggle(event.target.value)
	    // we need to check if the control is going to vanish
	    if ((ctrl !== null) && !allControls(settings).includes(ctrl)) {
	        this.props.setControl(null);
	    }
	    // for my benefit only
        if (this.props.user.username === 'iam') {
	        dumpLoadTimes()
        }
	    this.props.setSettings(settings);
    }

    render() {
        const username = this.props.user.username ? this.props.user.username : null
	    const settings = this.props.user.settings ? this.props.user.settings : new Settings(null, username);
	    return (
	        <React.Fragment>
	        <EToolip title="Settings">
	        <IconButton
            style={{ color: ECOLOR }}
            aria-label="Open or close the settings popup"
            component="span"
            size="large"
	        onClick={this.toggleOpen}
	        >
	        <SettingsIcon />
	        </IconButton>
	        </EToolip>
	        { this.state.open ?
	          <SettingsDialog open={this.state.open} handleChange={this.handleChange} settings={settings} handleClose={this.handleClose}/>
            : null }
	        </React.Fragment>
	    );
    }
}


const SettingsButton = connect(mapStateToProps, mapDispatchToProps)(ConnectedSettingsButton);

export default SettingsButton
