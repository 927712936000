import React from 'react';
import { v4 as getUUID } from 'uuid';

import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import IconButton from '@mui/material/IconButton';

// unknown error
import NotListedLocationSharpIcon from '@mui/icons-material/NotListedLocationSharp';

// unknown uncurated
import NotListedLocationOutlinedIcon from '@mui/icons-material/NotListedLocationOutlined';

// local
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

// external
import LocationOnTwoToneIcon from '@mui/icons-material/LocationOnTwoTone';


import { EToolip } from '../EToolip'

import { ECOLOR, ERROR_COLOR, LINK_COLOR, DISABLED } from '../../javascript/colors'

import { openInNewTab } from '../../javascript/utils'


function locationColor(location) {
    if (location.isLocal()) {
	if (location.isSelfReference()) {
	    return ECOLOR
	}
	return location.fragment ? ECOLOR : ERROR_COLOR
    }
    if (location.isExternal()) {
	return location.isInCorpus() ? ECOLOR : ( location.getExternalURL() ? LINK_COLOR : DISABLED )
    }
    return ERROR_COLOR
}

function locationAriaLabel(location) {
    if (location.isLocal()) {
	return location.fragment ? 'Local link; Click to follow' : 'Local link; Unknown destination'
    }
}

function locationClickHandler(navigate, props, location, debug) {
    return () => {
	if (debug) {
	    console.log(location.toString(), location)
	}
	let url = location.getLocalURL()
	if (url) {
	    console.log(`Navigating to ${url}`)
	    return navigate(url)
	}
	url = location.getExternalURL()
	if (url) {
	    console.log(`Launching tab at ${url}`)
	    openInNewTab(url)
	    return
	}
	console.log("Unhandled case")
    }
}

function locationIcon(location) {
    if (location.isLocal()) {
	return <LocationOnOutlinedIcon  key={getUUID()}/>
    }
    if (location.isExternal()) {
	return  location.getExternalURL() ? <LocationOnTwoToneIcon key={getUUID()}/> : <NotListedLocationOutlinedIcon key={getUUID()}/>
    }
    return <NotListedLocationSharpIcon key={getUUID()}/>
}

export default function LocationLink(props) {

    let navigate = useNavigate();

    const settings = useSelector(state => state.user.settings)

    const debug = settings ? settings.value('debug') : false

    const location = props.location

    const color = locationColor(location)

    const aria = locationAriaLabel(location)

    const msg = location.toString(debug)

    const handleClick = locationClickHandler(navigate, props, location, debug)

    const icon = locationIcon(location)

    return (
	<EToolip key={getUUID()} title={msg}>
	<IconButton key={getUUID()}  style={{ color: color }} aria-label={aria}  component="span" onClick={handleClick} size="small">
	{icon}
	</IconButton>
	</EToolip>
    )

}
