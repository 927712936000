// a data structure that may be useful for rendering a "List of Issues" and an "Issue Summary"
import { v4 as getUUID } from 'uuid';

export class INode {

    constructor(loi, si, owner) {
	// uuid for rendering
	this.uid = getUUID()
	// entity that has this issue
	this.owner = owner
	// the structural issue
        this.data = si
	// loi is the encompasing LOI object
	loi.add2summary(this);
	loi.add_to_si_map(this);
	loi.add_to_nt_map(this);
    }

}

export class LOI {

    constructor(data) {
	// maps a section id to the list of issues in that section.
        this.issues = {}

	// maps the uid of the node with the issue to the list of issue nodes (i.e. INode)
	this.index = {}

	// maps the si node_types to the list of inodes of that si type
	this.si_map = {}

	// maps the node_types of objects to the list of inodes whose owner has that type
	this.nt_map = {}


	// summary slots; statistical info
	this.si_count = 0
	this.si_summary = {}
	this.nt_summary = {}
	
	// for rendering
	this.si_uuid = getUUID()
	this.nt_uuid = getUUID()


	// process the json
        this.initialize(data, data);
	//this.summary()
    }


    add_to_si_map(inode) {
	// add the inode to the si_map
	const si = inode.data.node_type
	if (this.si_map[si]) {
	    this.si_map[si].push(inode)
	} else {
	    this.si_map[si] = [inode]
	}
    }

    add_to_nt_map(inode) {
	// add the inode to the nt_map
	const nt = inode.owner.node_type
	if (this.nt_map[nt]) {
	    this.nt_map[nt].push(inode)
	} else {
	    this.nt_map[nt] = [inode]
	}
    }

    add2summary(inode) {
	const si_nt = inode.data.node_type
	const o_nt = inode.owner.node_type
	this.si_count += 1
	this.si_summary[si_nt] = this.si_summary[si_nt] ? this.si_summary[si_nt] + 1 : 1
	this.nt_summary[o_nt] = this.nt_summary[o_nt] ? this.nt_summary[o_nt] + 1 : 1
    }

    process_node(x, p) {
	if (!x  || !x['structure_issues']) { return }
	const issues = x['structure_issues']
	//console.log(`a node of type ${x.node_type} has ${issues.length} issues`)
	const inode_list = issues.map((i) => this.process_issue(i, x))
	this.index[x.uid] = inode_list
	if (inode_list) {
	    if (!p.id) {
		console.log(p.node_type)
	    }
	    if (!this.issues[p.id]) {
		this.issues[p.id] = []
	    }
	    this.issues[p.id].push(...inode_list)
	    //console.log(`this.issues[${p.id}] = `, this.issues[p.id])
	}
    }
    
    process_issue(i, o) {
	//console.log(`issue: ${i.node_type}`)
	return new INode(this, i, o)
    }

    initialize(x, p) {
        if (!x) {
            return;
        }
	this.process_node(x, p)
        const nt = x['node_type'];
        switch(nt) {
            case 'document': {
                x['document_parts'].forEach((d) => this.initialize(d, x)); return;
            }
            case 'document_part':  {
		x['subsections'].forEach((d) => this.initialize(d, x)); 
		x['direct_children'].forEach((d) => this.initialize(d, x));
		return;
	    }
            case 'section': {
		x['subsections'].forEach((d) => this.initialize(d, x));
		x['direct_children'].forEach((d) => this.initialize(d, x));
		return;
	    }
	    case 'table':
	    case 'figure': {
		x['caption'].forEach((d) => this.initialize(d, p));
		return;
	    }
	    case 'list': {
		x['list_items'].forEach((d) => this.initialize(d, p));
		return;
	    }
	    case 'paragraph':
	    case 'list_item':
	    case 'abbreviation_entry':
	    case 'glossary_entry':
	    case 'note': {
		x['direct_children'].forEach((d) => this.initialize(d, p));
		return;
	    }
	    // the rest are leafs.
            default: return;
        }
    }

    si_mapping() {
	return this.si_map
    }

    nt_mapping() {
	return this.nt_map
    }

    get_si_count() {
	return this.si_count
    }

    get_si_summary() {
	return this.si_summary
    }

    get_nt_summary() {
	return this.nt_summary
    }

    summary() {
	console.log('Structural Issue Summary:', this.si_summary)
	console.log('Per Node Type Issue Summary:', this.nt_summary)
    }

}


