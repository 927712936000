import { useSelector } from 'react-redux'

import { useParams } from "react-router-dom";

import { google_analytics_pageview } from '../../javascript/ga'

import  RawQueryBrowser from './RawQueryBrowser'

import * as QBUtils from '../qb/QBUtils'

export default function RQBLoaderHook(props) {

    google_analytics_pageview()

    let params = useParams()

    let rqs = params.rQueryString || null

    const user = useSelector(state => state.user)
    
    const debug = QBUtils.monitor(user)

    if (debug) { console.log("RQBLoaderHook", params, rqs) }

    return (
	<RawQueryBrowser rqs={rqs} user={user} />
    )

}
