// a small wrapper around the library, the stuff the catalog and references need

import { v4 as getUUID } from 'uuid';


/*
   The library's catalog looks like this:
   [
   ...
   {
   uid: getUUID(),
   shortname: '29503',
   title: '3rd Generation Partnership Project; Technical Specification Group Core Network and Terminals; 5G System; Unified Data Management Services; Stage 3',
   releases: ['29503-g60', '29503-g70', '29503-h00', '29503-h10', '29503-h20'],
   },
   ...
   ]
 */


export class Library {


    constructor(builder) {
	    this.shortNames2entries = {}
	    const shortNames = []
	    builder.documentNames.forEach((name) => {
	        let short = builder.shortName(name);
	        let entry = this.shortNames2entries[short]
	        if (entry) {
		        entry.releases.push(name)
	        } else {
		        shortNames.push(short)
		        let title = builder.name2CatalogueEntry[name].title
		        entry = { uid: getUUID(), shortname: short, title: title, releases: [name] }
		        this.shortNames2entries[short] = entry
	        }
	    })
	    this.catalogue = shortNames.map((short) => { return this.shortNames2entries[short]; })
    }


    catalog() {
	    return this.catalogue
    }

    entries(shortname) {
	    const val = this.shortNames2entries[shortname]
	    return val ? val : null
    }

}
