import React  from 'react';
import { connect } from "react-redux";

import TocIcon from '@mui/icons-material/Toc';
import IconButton from '@mui/material/IconButton';
import { ECOLOR } from '../../javascript/colors'


import { setControl } from "../../actions/index";

import { TOC_CONTROL } from '../../constants/controls'

import { EToolip } from '../EToolip'

function mapDispatchToProps(dispatch) {
    return {
	setControl: control => dispatch(setControl(control)),
    }
}

const mapStateToProps = state => {
    return { control: state.portfolio.control }
}


class ConnectedTOCButton extends React.Component {

    constructor (props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick = (event) => {
	const  off = this.props.control === TOC_CONTROL
	this.props.setControl(off ? null :  TOC_CONTROL );
    }

    render() {
	const  off = this.props.control === TOC_CONTROL
	return (
        <EToolip title="Table of Contents">
	    <IconButton
            style={{ color: ECOLOR }}
            aria-label={`${off ? "close" : "open"} the table of contents`}
            component="span"
            onClick={this.handleClick}
            size="large">
	    <TocIcon />
	    </IconButton>
	    </EToolip>
    );
    }
}

const TOCButton = connect(mapStateToProps, mapDispatchToProps)(ConnectedTOCButton);

export default TOCButton;

