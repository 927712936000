import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import DragHandleIcon from '@mui/icons-material/DragHandle';

import IconButton from '@mui/material/IconButton';
import ParkIcon from '@mui/icons-material/Park';

import { v4 as getUUID } from 'uuid';

import { ECOLOR, ERROR_COLOR } from '../../javascript/colors'

import CloseMe from '../CloseMe'

import { innerHtml } from '../../javascript/utils'


function PaperComponent(props) {
    return (
	<Draggable
	handle="#draggable-dialog-title"
	cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}


export default function CSTDialog(props) {
  const [open, setOpen] = React.useState(false);

    const id = open ? 'simple-popover' : undefined;
    const content = props.content
    const trees = props.trees
    const ntrees = trees.length
    const data = trees[0]
    const color =  data.error ? ERROR_COLOR : ECOLOR

    const handleClickOpen = () => {
	setOpen(true);
    }

    const handleClose = () => {
	setOpen(false);
    }

    const csts = (ntrees > 1) ? <CSTTabs trees={trees} /> : <pre>{JSON.stringify(trees[0], null, 1)}</pre>;
    const target = innerHtml(content)

    return (
	<React.Fragment>
        <IconButton
        aria-describedby={id}
        color="primary"
        aria-label="display concrete syntax tree for this sentence"
        component="span"
        onClick={handleClickOpen}
            size="small">
        <ParkIcon fontSize="small" sx={{ color: color }} />
        </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth='md'
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
	BackdropProps={{ style: { backgroundColor: "transparent" }}}
	>
        <DialogTitle style={{ cursor: 'move'}} id="draggable-dialog-title">
	
	<Typography sx={{ color: color }} align="center"><DragHandleIcon fontSize="small" sx={{ color: color }} />Concrete Syntax Tree{ntrees > 1 ? "s" : ""}<DragHandleIcon fontSize="small" sx={{ color: color }} /></Typography>
	</DialogTitle>
	<DialogContent>
        {
        <DialogContentText>
	{target}
	</DialogContentText>
	}
        {csts}
	</DialogContent>
	<DialogActions>
	<CloseMe handleClose={handleClose} />
        </DialogActions>
      </Dialog>
      </React.Fragment>
  );
}

const CSTFields = ['cst', 'cleaned-text', 'ep-text', 'reformulation', 'sentence_id', 'skipped_csts']

function CSTTabs(props) {
    const [value, setValue] = React.useState('0');

    const handleChange = (event, newValue) => {
	setValue(newValue);
    };

    const trees = props.trees

    const displayTree = (tree, i) => { 
	return (
	    <dl key={`cst_tab_${i}_dl_key`}>
	    {CSTFields.map((k, j) => 
		{ return (
                   <React.Fragment key={getUUID()}>
		     <dt key={`cst_tab_${i}_dt_key_${j}`}>{k}</dt>
		     <dd key={`cst_tab_${i}_dd_key_${j}`}> 
                         <pre key={getUUID()}>{JSON.stringify(tree[k], null, 1)}</pre>
                     </dd>
                   </React.Fragment>)
		})}
	    </dl>
	);
    }


    const ids = Array.from(Array(trees.length).keys())
    const idStrs = ids.map((n) => { return n.toString(); })
    return (
	<Box key={getUUID()} sx={{ width: '100%', typography: 'body1' }}>
	<TabContext key={getUUID()} value={value}>
        <Box key={getUUID()} sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList key={getUUID()} onChange={handleChange} aria-label="CST Tabs">
	{ids.map((i) => { return <Tab key={getUUID()} label={"CST " + i} value={idStrs[i]} /> })}
        </TabList>
        </Box>
	{ids.map((i) => { return <TabPanel key={getUUID()} value={idStrs[i]}><pre key={getUUID()}>{displayTree(trees[i])}</pre></TabPanel> })}
	</TabContext>
	</Box>
    );
}
