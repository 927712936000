import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


export default function Busy (props) {

    return (
	<Backdrop 
	sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
	open={true}>
	<CircularProgress color="inherit" />
	</Backdrop>
    )



}
